import React from 'react';
import "../../styles/style.scss"

function BasicCCMPage() {
    return (
        <>
            <div className="title-label sans-pro-semi-bold">Comment ça marche ?</div>
            <div className="liste ">
                <div className="point-label">
                    <div className="point">
                        <img src="/assets/img/check-green.png" alt="done"/>
                    </div>
                    <div className="label sans-pro-semi-bold">
                        Sélectionner un logement
                    </div>
                </div>
                <div className="trait-explication">
                    <div className="trait"> </div>
                    <div className="explication sans-pro-regular">
                        Commencez à saisir le nom du logement puis sélectionnez-le dans la liste
                    </div>
                </div>
                <div className="point-label">
                    <div className="point">
                        <img src="/assets/img/check-green.png" alt="done"/>
                    </div>
                    <div className="label sans-pro-semi-bold">
                        Indiquer la date de prestation
                    </div>
                </div>
                <div className="trait-explication">
                    <div className="trait"></div>
                    <div className="explication sans-pro-regular">
                        Cliquez sur ‘Choisir la date’ en orange puis sélectionnez un jour de prestation
                    </div>
                </div>
                <div className="point-label">
                    <div className="point">
                        <img src="/assets/img/check-green.png" alt="done"/>
                    </div>
                    <div className="label sans-pro-semi-bold">
                        Choisir le service
                    </div>
                </div>
                <div className="trait-explication">
                    <div className="trait trait-invisible"></div>
                    <div className="explication sans-pro-regular">
                        Sélectionnez le service souhaité puis lancez la commande
                    </div>
                </div>
            </div>
        </>
    );
}

export default BasicCCMPage;
