import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const productApi = createApi({
    reducerPath: "productApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Product"],
    endpoints: (builder) => ({
        productsFetch: builder.query({
            query: () => `/api/v1/produit/type/kit`,
            providesTags: ["Product"],
        }),

    }),
});

export const {
    useProductsFetchQuery
} = productApi;
