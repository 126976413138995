import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    authRatingCreate: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/comment/rating",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Dashboard"],
    }),
    // authFetchToken: builder.mutation({
    //   query: (payload) => ({
    //     url: `/api/v1/Dashboard`,
    //     method: "GET",
    //     body: payload,
    //   }),
    //   invalidatesTags: ["Dashboard"],
    // }),
  }),
});

export const {
  useAuthRatingCreateMutation
} = dashboardApi;
