import React from 'react';
import "../../styles/style.scss"

function BasicSAPPage() {
    return (
        <>
            <div className="title-label sans-pro-semi-bold">Comment ça marche ?</div>
            <div className="liste gray-zone">
                <div className="point-label">
                    <div className="point">
                        <img src="/assets/img/light-colored.png" alt="done"/>
                    </div>
                    <div className="label sans-pro-semi-bold">
                        Plus sur le service d’appoint
                    </div>
                </div>
                <div className="trait-explication">
                    <div className="trait trait-invisible"></div>
                    <div className="explication">
                        L’option ‘Autre besoin’ du service d’appoint permet d’adresser tout type de <br/>
                        demande exceptionnelle à une aide-ménagère, telle que faire une course dans le <br/>
                        logement... Les services techniques tels que les petits travaux d’électricité ou de <br/>
                        plomberie ne sont pas concernés.
                    </div>
                </div>
            </div>
        </>
    );
}

export default BasicSAPPage;
