import React from 'react';
import "../../styles/style.scss"

function BasicSAPCollectePage() {
    return (
        <>
            <div className="title-label sans-pro-semi-bold">Comment ça marche ?</div>
            <div className="liste gray-zone">
                <div className="point-label">
                    <div className="point">
                        <img src="/assets/img/light-colored.png" alt="done"/>
                    </div>
                    <div className="label sans-pro-semi-bold">
                        Plus sur le service d’appoint
                    </div>
                </div>
                <div className="trait-explication">
                    <div className="trait trait-invisible"></div>
                    <div className="explication">
                        L’option ‘Collecte de linge’ du service d’appoint est à utiliser pour demander <br/>
                        l’enlèvement des kits de linge Kliner qui sont stockés dans vos logements, <br/>
                        notamment ceux où il n’y a pas eu de nouvelle arrivée depuis plus de 15 jours.
                    </div>
                </div>
            </div>
        </>
    );
}

export default BasicSAPCollectePage;
