import React, {useEffect, useState} from 'react';
import "../styles/style.scss"
import {Avatar, Button, Col, Divider, Dropdown, Menu, message, Row} from "antd";
import DocumentClientModal from "./modal/document-client";
import {API_ROOT, convertDateStringToTimeStringDateOnly} from "../../../utils/global-var";
import {useClientStatsFetchQuery} from "../services/user-stats-api";
import LogementFilteredPage from "./modal/logement-filtered-modal";
import PlanningFilteredPage from "./modal/prestation-filtered-modal";
import {usePaiementclientFetchQuery} from "../services/paiement-client";
import {FileProtectOutlined} from "@ant-design/icons";
import {useInfoUserFetchOneQuery} from "../../equipes/services/indo-user-api";
import {useClientUpdateBillingMutation} from "../services/client-api";
import {setRefresh} from "../../logements/services/logement-slice";
import modifierPaiementDelaiModalView from "./modal/changer-delai-paiement-modal";
import ModifierPaiementDelaiModalView from "./modal/changer-delai-paiement-modal";

const ClientsDrawerPage = ({record, closeDrawer, setOpenLogement, setRecordLogement}) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalLogement, setShowModalLogement] = useState(false);
    const [showModalPrestation, setShowModalPrestation] = useState(false);
    const [showModalDelaiPaiement, setShowModalDelaiPaiement] = useState(false);
    const [delaiPaiement, setDelaiPaiement] = useState(null);
    const [statusLogement, setStatusLogement] = useState("");
    const [statusPrestation, setStatusPrestation] = useState("");
    const statistique = useClientStatsFetchQuery(record.user_id ?? record.id);
    const moyenPaiement = usePaiementclientFetchQuery({id: record.user_id ?? record.id});
    const { data: userIfon, refetch } = useInfoUserFetchOneQuery(record.user_id ?? record.id);

    useEffect(() => {

    }, [record, showModalDelaiPaiement]);
    const onChange = (key) => {
        console.log(key);
    };
    console.log(userIfon);
    //console.log(record);
    //console.log(moyenPaiement);

    const menuPhone = (
        <Menu>
            <Menu.Item key="cle">
                <strong>Numéro de téléphone</strong>
                <p>
                    {record?.user_phone ?? record?.phone}
                </p>
            </Menu.Item>
        </Menu>
    );
    const menuEmail = (
        <Menu>
            <Menu.Item key="cle">
                <strong>Adresse email</strong>
                <p>
                    {record?.user_email ?? record?.email}
                </p>
            </Menu.Item>
        </Menu>
    );
    const items = [
        {
            key: '1',
            label: <div className="sans-pro-regular">Tout</div>,
            children: "",
        },
        {
            key: '2',
            label: <div className="sans-pro-regular">Tarifs libres</div>,
            children: "",
        },
    ];
    return (
        <div className="record-client">
                <div className="head">
                    <div className="date sans-pro-semi-bold">Créé le {convertDateStringToTimeStringDateOnly(record?.user_createdAt ?? record?.createdAt)}</div>
                    <div className="type">
                        <div className="icone">
                           <img src="./assets/img/fuse.png" alt="icone"/>
                        </div>
                        <div className="text sans-pro-semi-bold">
                            Adoption
                        </div>
                    </div>
                    <div className="cguState">
                        {record?.user_isCguValidated === 1 && <img src="/assets/img/success-icon.png" alt="success"/>}
                        <FileProtectOutlined />
                    </div>
                </div>
            <div className="photo">
                {(record?.user_avatar ?? record?.avatar) === null ? <Avatar src="./assets/img/user-empty.png" alt="icone"/> : <Avatar src={`${API_ROOT}/${record?.user_avatar ?? record?.avatar}`} alt="icone"/>}
            </div>
            <div className="idClient">
                #{record?.user_id ?? record?.id}
            </div>
            <div className="content" style={{paddingTop: "15px", paddingBottom: "15px"}}>
                    <div className="info">
                        <div className="name">
                            <div className="nom sans-pro-semi-bold"> {record.user_lastname ?? record.lastname} {record.user_firstname ?? record.firstname}</div>
                            {(record?.user_profil ?? record?.profil) === "particulier" && <div className="sigle">LMNP</div>}
                            {(record?.user_profil ?? record?.profil) === "conciergerie indépendante" && <div className="sigleCorp">Conciergerie</div>}
                            <div className="menu"></div>
                            <div className="pays sans-pro-regular">France</div>
                        </div>
                        <div className="info-supp">
                            <Dropdown
                                overlay={menuEmail}
                                placement="top"
                                arrow
                            >
                            <div className="icone-enveloppe">
                                <img src="./assets/img/mail-subscription - Modifié.png" alt="icon"/>
                            </div>
                            </Dropdown>
                            <Dropdown
                                overlay={menuPhone}
                                placement="top"
                                arrow
                            >
                            <div className="icone-call">
                                <img src="./assets/img/phone icon - Modifié.png" alt="icon"/>
                            </div>
                            </Dropdown>
                            {moyenPaiement?.data === null && <div className="paiement">
                                <div className="icone-paiement">
                                    <img src="./assets/img/empty-payment-icon.png" alt="icon"/>
                                </div>
                                <div className="text">
                                    Aucun moyen
                                </div>
                                <div className="icone-close">
                                    <img src="./assets/img/no card icon.png" alt="icon"/>
                                </div>
                            </div>}
                            {moyenPaiement?.data?.type === "sepa_debit" && <div className="paiement">
                                <div className="icone-paiement">
                                    <img src="./assets/img/sepa-icon.png" alt="icon"/>
                                </div>
                                <div className="text">
                                    **** {moyenPaiement?.data?.sepa_debit.last4}
                                </div>
                                <div className="icone-close">
                                    <img src="./assets/img/green-check.png" alt="icon"/>
                                </div>
                            </div>}
                            {moyenPaiement?.data?.type === "card" && <div className="paiement">
                                <div className="icone-paiement">
                                    <img src={moyenPaiement?.data?.card?.brand === "visa" ? "./assets/img/visa icon.webp" : moyenPaiement?.data?.card?.brand === "visa" ? "./assets/img/mastercard icon.webp" : "./assets/img/amex icon.png"} alt="icon"/>
                                </div>
                                <div className="text">
                                    **** {moyenPaiement?.data?.card.last4}
                                </div>
                                <div className="icone-close">
                                    <img src="./assets/img/green-check.png" alt="icon"/>
                                </div>
                            </div>}
                            <div className="barre-content">
                                <div className="barre"> </div>
                            </div>
                            <div className="timeFacturation">
                                    <div className={"elementFacture "+(userIfon?.billingDetail?.charge_automatically && userIfon?.billingDetail?.charge_automatically_event === "prestation_end" ? " active" : "")} onClick={() => {
                                        setDelaiPaiement({
                                            charge_automatically: true,
                                            charge_automatically_event: "prestation_end",
                                            manual_debit_due_days: 0
                                        });
                                        setShowModalDelaiPaiement(true);
                                    }}>Auto - fin presta</div>
                                    <div className={"elementFacture "+(userIfon?.billingDetail?.charge_automatically && userIfon?.billingDetail?.charge_automatically_event === "threshold_attained" ? " active" : "")} onClick={() => {
                                        setDelaiPaiement({
                                            charge_automatically: true,
                                            charge_automatically_event: "threshold_attained",
                                            manual_debit_due_days: 0
                                        });
                                        setShowModalDelaiPaiement(true);
                                    }}>Auto - Échéance</div>
                                    <div className={"elementFacture "+(!userIfon?.billingDetail?.charge_automatically && userIfon?.billingDetail?.manual_debit_due_days === 7 ? " active" : "")} onClick={() => {
                                        setDelaiPaiement({
                                            charge_automatically: false,
                                            charge_automatically_event: "threshold_attained",
                                            manual_debit_due_days: 7
                                        });
                                        setShowModalDelaiPaiement(true);
                                    }}>Manuel - J+7</div>
                                    <div className={"elementFacture "+(!userIfon?.billingDetail?.charge_automatically && userIfon?.billingDetail?.manual_debit_due_days === 15 ? " active" : "")} onClick={() => {
                                        setDelaiPaiement({
                                            charge_automatically: false,
                                            charge_automatically_event: "threshold_attained",
                                            manual_debit_due_days: 15
                                        });
                                        setShowModalDelaiPaiement(true);
                                    }}>Manuel - J+15</div>
                                    <div className={"elementFacture "+(!userIfon?.billingDetail?.charge_automatically && userIfon?.billingDetail?.manual_debit_due_days === 20 ? " active" : "")} onClick={() => {
                                        setDelaiPaiement({
                                            charge_automatically: false,
                                            charge_automatically_event: "threshold_attained",
                                            manual_debit_due_days: 20
                                        });
                                        setShowModalDelaiPaiement(true);
                                    }}>Manuel - J+20</div>
                            </div>
                        </div>
                        <Divider  style={{width:"200%", marginLeft:"-50%"}}/>
                        <div className="score">
                            <div className="titre">
                               <div className="text sans-pro-semi-bold"> Score de santé</div>
                               <div className="indice">
                                   {record.sante < 5 && <div className='sante-red'>{record.sante}</div>}
                                   {record.sante >= 5 && record.sante < 7 && <div className='sante-orange'>{record.sante}</div>}
                                   {record.sante >= 7 && <div className='sante-green'>{record.sante}</div>}
                               </div>
                            </div>
                            <div className="contenu">
                                <Row>
                                    <Col lg={12}>
                                        <div className="backraillé">
                                            <div className="utilisation" style={{width:"76%"}}>
                                                <div className="text">
                                                    76%
                                                </div>
                                            </div>
                                        </div>
                                        <div className="libelle">Utilisation</div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="backraillé">
                                            <div className="satisfaction" style={{width:"48%"}}>
                                                <div className="text">
                                                    48%
                                                </div>
                                            </div>
                                        </div>
                                        <div className="libelle">Satisfaction</div>
                                    </Col>
                                </Row>
                                <br/>
                                <br/>
                                <Row>
                                    <Col lg={12}>
                                        <div className="backraillé">
                                            <div className="croissance" style={{width:"84%"}}>
                                                <div className="text">
                                                    84%
                                                </div>
                                            </div>
                                        </div>
                                        <div className="libelle">Croissance</div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="backraillé">
                                            <div className="ambassadeur" style={{width:"76%"}}>
                                                <div className="text">
                                                    76%
                                                </div>
                                            </div>
                                        </div>
                                        <div className="libelle">Ambassadeur</div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="logement-client">
                            <div className="titre">
                                <div className="text sans-pro-semi-bold"> Logements</div>
                                <div className="indice">(7)</div>
                            </div>
                            <div className="contenu">
                                <div className="element" onClick={()=>{setStatusLogement("GREEN"); setShowModalLogement(true); }}>
                                    <div className="Himage">
                                        <div className="image">
                                            <img src="./assets/img/Logements icon - Modifié - Modifié.png" alt="icone"/>
                                        </div>
                                    </div>
                                    <div className="label">Actif ({statistique?.data?.nb_logement_green ?? 0 })</div>
                                </div>
                                <div className="element" onClick={()=>{setStatusLogement("ORANGE"); setShowModalLogement(true); }}>
                                    <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/Logements icon - Modifié - Modifié (1).png" alt="icone"/>
                                    </div>
                                    </div>
                                    <div className="label">En attente ({statistique?.data?.nb_logement_orange ?? 0 })</div>
                                </div>
                                <div className="element" onClick={()=>{setStatusLogement("RED"); setShowModalLogement(true); }}>
                                    <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/Logements icon - Modifié - Modifié - Modifié.png" alt="icone"/>
                                    </div>
                                    </div>
                                    <div className="label">Brouillons ({statistique?.data?.nb_logement_red ?? 0 })</div>
                                </div>
                            </div>
                        </div>
                        <div className="logement-client">
                            <div className="titre">
                                <div className="text sans-pro-semi-bold"> Prestation</div>
                            </div>
                            <div className="contenu">
                                <div className="element" onClick={()=> {setStatusPrestation("Terminé"); setShowModalPrestation(true);}}>
                                    <div className="Himage">
                                        <div className="image">
                                            <img src="./assets/img/Play Icon - Modifié (1).png" alt="icone"/>
                                        </div>
                                    </div>
                                    <div className="label">Terminé ({statistique?.data?.nb_prestations_finies ?? 0 })</div>
                                </div>
                                <div className="element" onClick={()=> {setStatusPrestation("En cours"); setShowModalPrestation(true);}}>
                                    <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/Play Icon - Modifié.png" alt="icone"/>
                                    </div>
                                    </div>
                                    <div className="label">En cours ({statistique?.data?.nb_prestations_en_cours ?? 0 })</div>
                                </div>
                                <div className="element" onClick={()=> {setStatusPrestation("Programmé"); setShowModalPrestation(true);}}>
                                    <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/Play Icon - Modifié (2).png" alt="icone"/>
                                    </div>
                                    </div>
                                    <div className="label">Programmé ({statistique?.data?.nb_prestations_programmees ?? 0 })</div>
                                </div>
                                <div className="element" onClick={()=> {setStatusPrestation("Annulé"); setShowModalPrestation(true);}}>
                                    <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/presta del icon.png" alt="icone"/>
                                    </div>
                                    </div>
                                    <div className="label">Annulé ({statistique?.data?.nb_prestations_annules ?? 0 })</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Divider />
            <div className="content" style={{paddingTop: "15px"}}>
                <div className="info">
                <Row>
                    <Col lg={11}>
                        <div className="score">
                            <div className="titre">
                                <div className="text sans-pro-semi-bold"> Facturation</div>
                            </div>
                            <Button onClick={() => setShowModal (true)}>
                                <div className="icone-button">
                                    <img src="./assets/img/euro icon - Modifié.png" alt="icon"/>
                                </div>
                                Factures & Attentions
                            </Button>
                        </div>
                    </Col>
                    <Col lg={2} >
                        <div className="verticalDivider">

                        </div>
                    </Col>
                    <Col lg={11}>
                        <div className="score">
                            <div className="titre">
                                <div className="text sans-pro-semi-bold"> Conversations</div>
                            </div>
                            <Button>
                                <div className="icone-button">
                                    <img src="./assets/img/intercom logo - Modifié.png" alt="icon"/>
                                </div>
                                Voir dans intercom
                            </Button>
                        </div>
                    </Col>
                </Row>
                </div>
            </div>
            <DocumentClientModal
                status={showModal}
                record={record}
                handleCancel={() => setShowModal (false)}
            />
            {statusLogement && <LogementFilteredPage
                status={showModalLogement}
                userId={record.user_id ?? record.id}
                userInfo={record}
                closeDrawer={closeDrawer}
                setOpenLogement={setOpenLogement}
                setRecordLogement={setRecordLogement}
                statusLogement={statusLogement}
                handleCancel={() => setShowModalLogement(false)}
            />}
            <PlanningFilteredPage
                status={showModalPrestation}
                userId={record}
                userInfo={record}
                statusPrestation={statusPrestation}
                handleCancel={() => setShowModalPrestation(false)}
            />
            {delaiPaiement !== null && <ModifierPaiementDelaiModalView
                user={userIfon}
                refresh={refetch}
                data={delaiPaiement}
                status={showModalDelaiPaiement}
                handleCancel={() => setShowModalDelaiPaiement(false)}
            />}
        </div>
    );
}

export default ClientsDrawerPage;
