import React, {useEffect, useState} from "react";
import {Modal, Row, Col, Popover, Button, Select, Carousel, Spin} from "antd";
import {IoChevronBack, IoChevronForwardOutline} from "react-icons/io5";
import {useMediaprestationFetchOneQuery} from "../services/mediaprestation-api";
import moment from "moment/moment";
import {API_ROOT, convertDateStringToTimeStringDateOnly} from "../../../utils/global-var";
import AddPhotoPrestation from "./add_photo_prestation";
const contentStyle = {
    margin: 0,
    height: '600px',
    color: 'black',
    lineHeight: '600px',
    textAlign: 'center',
    background: 'white',
};
function RapportPhoto({ status, date, prestations, resetDate, handleCancel }) {
    const [showModal, setShowModal] = useState(false);
    const carouselRef = React.createRef();
    const [selectedLogement, setSelectedLogement] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(date);
    const [selectedLogementPhase, setSelectedLogementPhase] = useState("before");
    const { data: mediaListDetail, refetch } = useMediaprestationFetchOneQuery(currentDate.split("T")[0]);
    useEffect(() => {
        refetch();
    }, [currentDate, showModal]);
    console.log(mediaListDetail);
    const onCancel = () => {
        handleCancel();
        resetDate(null);
    };
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    const handleSelectChange = (value) => {
        const selectedIndex = mediaListDetail.findIndex(item => item.logement_id === value);
        setLoading(true);
        setSelectedLogement(selectedIndex);
        setLoading(false);
    };
    const handleSelectChangeDate = (value) => {
        setLoading(true);
        setCurrentDate(value);
        setLoading(false);
    };

    return (
        <>
            <Modal
                destroyOnClose
                open={status} footer={null} closable={true} width={1500} onCancel={onCancel} className="RapportPhoto" >
                <div className="rapport">
                    <div className="head sans-pro-semi-bold">
                        Reportage photos
                        <div className="dateSelector">
                            {/*<div className="image">
                                <img src="./assets/img/logon.png" alt="image"/>
                            </div>
                                <Select
                                defaultValue="Mardi 11 décembre 2024"
                                onChange={null}
                            suffixIcon={<div className='icon-logement-select'><img
                                src="../assets/img/arrow-down-gray.png" alt="add"/></div>}
                            options={[
                                {value: 'Mardi 11 décembre 2024', label: 'Mardi 11 décembre 2024'},

                            ]}
                        />*/}
                            <Button type={"primary"} onClick={() => setShowModal(true)}>
                                Gérer les photos d'{selectedLogementPhase === "before" ? "avant" : "après" } la prestation
                            </Button>
                        </div>
                    </div>
                    <br/>
                    <div className="select">
                        <div className="libelle">
                            Prestations du
                        </div>
                        <div className="selecteur">
                            {Array.isArray(mediaListDetail) && mediaListDetail?.length !== 0 && (
                                <Select
                                    defaultValue={date}
                                    style={{width:"200px"}}
                                    onChange={handleSelectChangeDate}
                                    suffixIcon={
                                        <div className='icon-logement-select'>
                                            <img src="../assets/img/arrow-down-gray.png" alt="add"/>
                                        </div>
                                    }
                                    options={[
                                        ...prestations.map((item, index) => ({
                                            key: index,
                                            value: item["prestation_date"],
                                            label: `${convertDateStringToTimeStringDateOnly(item["prestation_date"], true)}`,
                                        })),
                                    ]}
                                />
                            )}
                        </div>
                    </div>
                    <div className="appartement">
                        <div style={{marginTop: "6px", marginBottom:"5px"}}>Logements: </div>
                        {mediaListDetail?.length !== 0 && mediaListDetail?.map((item, index) =>
                            <div className="button" key={"Logement_"+index}>
                            <Button className={selectedLogement === index ? "active" : ""} onClick={() => handleSelectChange(item["logement_id"])}>
                                {item["logement_libelle"].toUpperCase()}
                            </Button>
                        </div>)}
                    </div>
                    <br/>
                    <Spin spinning={loading}>
                   <div className="apercuPhoto">
                       <Row>
                           <Col lg={2}>
                               <div className="prev" onClick={()=> carouselRef.current.prev()}>
                                   <IoChevronBack />
                               </div>
                           </Col>
                           <Col lg={20}>
                               <Carousel afterChange={onChange} ref={carouselRef}>
                                   {mediaListDetail?.length > 0 &&
                                       mediaListDetail[selectedLogement]?.catalogs?.length > 0 &&
                                       mediaListDetail[selectedLogement]?.catalogs
                                           .filter(item2 => item2["status"] === selectedLogementPhase)
                                           .map((item2, index2) => (
                                               <div key={`Image-Logement_${selectedLogement}_${index2}`}>
                                                  <div style={{height: "600px", alignItems:"center", justifyContent:"center", display:"flex"}}>
                                                      <img src={`${API_ROOT}/${item2["image"]}`} alt="" style={{height: "100%", borderRadius:"10px"}}/>
                                                  </div>
                                               </div>
                                           ))
                                   }
                                   {mediaListDetail?.length > 0 && mediaListDetail[selectedLogement]?.catalogs?.length <= 1 && mediaListDetail[selectedLogement]?.catalogs[0].id === null &&<div><h3 style={contentStyle}>Aucune photo {selectedLogementPhase === "before" ? "avant" : "après" } la prestation {mediaListDetail[selectedLogement]?.logement_libelle.toUpperCase()}</h3></div>}
                                   {mediaListDetail?.length > 0 && mediaListDetail[selectedLogement]?.catalogs?.length >= 1 && !mediaListDetail[selectedLogement]?.catalogs?.some(item2 => item2["status"] === selectedLogementPhase) &&<div><h3 style={contentStyle}>Aucune photo {selectedLogementPhase === "before" ? "avant" : "apres" } la prestation {mediaListDetail[selectedLogement]?.logement_libelle.toUpperCase()}</h3></div>}
                               </Carousel>
                           </Col>
                           <Col lg={2}>
                               <div className="next" onClick={()=> carouselRef.current.next()}>
                                   <IoChevronForwardOutline />
                               </div>
                           </Col>
                       </Row>
                   </div>
                    </Spin>
                   <div className="avant-apres">
                       <a href="#" className={" avant sans-pro-regular "+(selectedLogementPhase === "before" ? " active": "")} onClick={() => setSelectedLogementPhase("before")}>AVANT</a> <span style={{color:"rgba(128, 128, 128, 0.4196078431)"}}>|</span> <a href="#" className={"après sans-pro-regular "+(selectedLogementPhase === "after" ? " active": "")} onClick={() => setSelectedLogementPhase("after")}>APRES</a>
                   </div>
                </div>
            </Modal>

            {mediaListDetail && <AddPhotoPrestation
                status={showModal}
                logement={mediaListDetail[selectedLogement]}
                position={selectedLogementPhase}
                handleCancel={() => setShowModal(false)}
            />}
        </>
    );
}

export default RapportPhoto;