import React from 'react';
import "../../styles/style.scss"
import {Col, Row} from "antd";

const HeureTerminerApercu = ({choix}) => {
    return (
        <div className='AideMenageApercu'>
            <Row>
                <Col lg={2}>

                </Col>
                <Col lg={22}>
                    <div className="apercu-content">
                        <Row>
                            <Col lg={2}>
                                <div className="img">
                                    <img src="./assets/img/light-colored.png" alt="icone"/>
                                </div>
                            </Col>
                            <Col lg={22}>
                                {choix === "oui" && <div className="text">
                                    Bonne nouvelle ! Le système a détecté que la prestation a été terminée dans les temps.
                                </div>}
                                {choix === "non" && <div className="text">
                                    Mauvais point ! Veillons à ce que les aides ménagères respectent les créneaux d’intervention définis.
                                </div>}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default HeureTerminerApercu;
