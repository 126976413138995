import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Badge, Button, Col, Modal, Progress, Row, Spin, Popover, Divider, Tag, Tooltip} from "antd";
import {
    BranchesOutlined,
    CalendarOutlined,
    CloseCircleFilled, EyeFilled,
    EyeOutlined,
    FileTextOutlined,
    HistoryOutlined
} from "@ant-design/icons";
import {
    IoArrowForwardCircleOutline,
    IoCaretForward,
    IoCaretForwardSharp,
    IoCartOutline,
    IoDocumentTextOutline, IoStopCircleOutline
} from "react-icons/io5";
import {
    API_ROOT,
    convertDateStringToTimeString,
    monthList,
    coutHorsTax,
    coutTTC,
    eventText, convertDateStringToTimeStringComplete, convertDateStringToTimeStringDateOnly, handleConvert,
    computeMarginalProfit,
    marginalProfitTagColor,
    profileVAT
} from "../../utils/global-var";
import dayjs from "dayjs";
import KitDeLingeModalView from "./modal/modal/apercu_planning/kits-de-linge";
import KitAccueilModalView from "./modal/modal/apercu_planning/kit-acceuil";
import ControleQualiteModalView from "./modal/modal/apercu_planning/controle-qualité";
import EvaluationModalView from "./modal/modal/apercu_planning/evaluation";
import {usePrestationFetchOneMutation} from "./services/planning-api";
import {useCartFetchAmenitiesQuery} from "../logements/services/logement-api";
import moment from "moment";
import UpdateTime from "./update/update-time";
import {setRefresh} from "./services/planning-slice";
import "moment/locale/fr";
import "./styles/style.scss"
import PrestationFordward from "./update/prestation-forward";
import PrestationDelete from "./update/prestation-cancel";
import AnnulerPrestationModalView from "./modal/Annuler-prestation";
import TerminerPrestationModalView from "./modal/terminer-pretation";
import DemarrerPrestationModalView from "./modal/demarrer-prestation";
import {useLogFetchQuery} from "./services/log-api";
import {useGetSubscriptionThresholdQuery, useMoyenPaiementQuery} from "./services/paiement-api";
import {usePaiementclientFetchQuery} from "../clients/services/paiement-client";
import ControleFinPrestationPage from "./modal/controle_fin_prestation";
import InstructionSpecialeModalView from "./modal/instruction_special";
import {useAminitieFetchQuery, useLingeFetchQuery} from "./services/aminitie-linge-api";

const PlanningDetail = ({prestationId, status, handleCancel, userId}) => {
    const [showModalCreneau, setShowModalCreneau] = useState(false);
    const [showModalKit, setShowModalKit] = useState(false);
    const [showModalAccueil, setShowModalAccueil] = useState(false);
    const [showModalControle, setShowModalControle] = useState(false);
    const [prestation, setPrestation] = useState();
    const [showModalAnnulerP, setShowModalAnnulerP] = useState(false);
    const [showModalTerminerP, setShowModalTerminerP] = useState(false);
    const [showModalDemarrerP, setShowModalDemarrerP] = useState(false);
    const [showControleSinPrestation, setShowControleSinPrestation] = useState(false);
    const [showModalEvaluation, setShowModalEvaluation] = useState(false);
    const [showModalInstructionSpecial, setShowModalInstructionSpecial] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(monthList[parseInt(new Date().getMonth() + 1)] + " " + (new Date().getFullYear()));
    const [selectedMonth, setSelectedMonth] = useState(dayjs());
    const [prestationFetchOne] = usePrestationFetchOneMutation();
    const paiementFetch = useMoyenPaiementQuery({id: userId.id ?? userId.user_id});
    const moyenPaiement = usePaiementclientFetchQuery({id: userId.id ?? userId.user_id});
    const logList = useLogFetchQuery({service: "prestation", page: 1, pagesize: 6, order: "DESC", item: prestationId});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const subscriptionThreshold = useGetSubscriptionThresholdQuery(userId.id ?? userId.user_id);
    const cart = useCartFetchAmenitiesQuery(prestation?.logement?.id)
    // const { isRefresh } = useSelector((state) => state.planning);
    const {user} = useSelector((state) => state.auth);
    /*const amenitieList = useAminitieFetchQuery({id: prestationId});
    const lingeList = useLingeFetchQuery({id: prestationId});
    console.log("amenitieList");
    console.log(amenitieList);
    console.log("lingeList");
    console.log(lingeList);*/
    //console.log(userId);


    const [clickedForward, setClickedForward] = useState(false);
    const [clickedForwardCancel, setClickedCancel] = useState(false);
    useEffect(() => {
        if (prestationId) {
            handleFetchPrestation(prestationId);
        }
        // needRefresh();
    }, [prestationId, clickedForward, clickedForwardCancel, showModalAnnulerP, showModalDemarrerP, showModalTerminerP]);

    useEffect(() => {
        if (prestationId) {
            handleFetchPrestation(prestationId);
        }
    }, []);


    /*useEffect(() => {
        isRefresh &&
            handleFetchPrestation(prestationId);
    }, [isRefresh]);*/


    const getPercent = () => {
        if (prestation?.quality_control === null) {
            return 0;
        }
        if (prestation?.quality_control?.bo_stars !== null) {
            return 100;
        }
        if (prestation?.quality_control?.prestation_finished_on_time !== null) {
            return 80;
        }
        if (prestation?.quality_control?.prestation_started_on_time !== null) {
            return 60;
        }
        if (prestation?.quality_control?.linge_amenities_deposes) {
            return 40;
        }
        if (prestation?.quality_control?.presence_confirmee) {
            return 20;
        }
        return 0;
    }

    const handleFetchPrestation = async (prestationId) => {
        setLoading(true)
        prestationFetchOne({id: prestationId})
            .unwrap()
            .then((res) => {
                setPrestation(res);
                setLoading(false);
                dispatch(setRefresh(false));
            })
            .catch(() => {
                setLoading(false);
            });
    }


    const goToNextMonth = () => {
        setSelectedMonth(selectedMonth.add(1, 'month'));
    };

    const goToPreviousMonth = () => {
        setSelectedMonth(selectedMonth.subtract(1, 'month'));
    };

    const customStatus = (value) => {
        switch (value) {
            case "En cours":
                return (<Badge color="#FDAC3D" count={"En cours"}/>);
            case "Programmé":
                return (<Badge color="#333333" count={"À faire"}/>);
            case "Annulé":
                return (<Badge color="#DF2E49" count={"Annulé"}/>);
            case "Non effectué":
                return (<Badge color="#DF2E49" count={"Non effectué"}/>);
            case "Terminé":
                return (<Badge color="#00C875" count={"Terminé"}/>);
            default:
                return (<Badge color="#333333" count={"À faire"}/>);
        }
    }


    const kitCount = (items, product) => {
        let total = 0;
        for (let item of items) {
            if (item.produit.produit === product) {
                total += item.quantite;
            }
        }
        return total;
    }


    const handleClickForward = (open) => {
        setClickedForward(open);
    };


    const handleClickCancel = (open) => {
        setClickedCancel(open);
    };

   // console.log(userId);
    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 50}}
            closable={true}
            onCancel={() => handleCancel()}
            width={1400}
            className="apercu-programmer">

            <Spin spinning={loading}>
                <div className="programmer-a">
                    <div className="title sans-pro-regular">
                        <div className="indice">#{prestation?.id}</div>
                        <div className="title-label">Prestation
                            du {convertDateStringToTimeStringDateOnly(prestation?.creneau_intervention_debut)}</div>
                        <div className="edit-icon">
                            <Popover
                                trigger="click"
                                placement="bottom"
                                content={(
                                    <div>
                                        {prestation?.status !== "Annulé" && <Popover
                                            open={clickedForward}
                                            onOpenChange={handleClickForward}
                                            trigger="click"
                                            placement="rightTop"
                                            title="Reporter la prestation"
                                            content={
                                                <PrestationFordward
                                                    handleCancel={() => setClickedForward(false)}
                                                    prestationId={prestation?.id}
                                                    creneauDebut={prestation?.creneau_intervention_debut}
                                                    creneauFin={prestation?.creneau_intervention_fin}
                                                />
                                            }>
                                            <div className="detail-presta-dropdown" style={{marginBottom: '10px'}}>
                                                <HistoryOutlined/> <span>Reporter</span>
                                            </div>
                                        </Popover>}
                                        {prestation?.status !== "Annulé" && prestation?.status !== "En cours" &&
                                            <div className="detail-presta-dropdown"
                                                 style={{cursor: "pointer", marginBottom: "10px"}}
                                                 onClick={() => setShowModalDemarrerP(true)}>
                                                <IoCaretForward style={{
                                                    color: "black",
                                                    fontSize: "22px",
                                                    verticalAlign: "top",
                                                    marginLeft: "0 15px"
                                                }}/> <span>Démarrer</span>
                                            </div>}
                                        {prestation?.status === "En cours" && <div className="detail-presta-dropdown"
                                                                                   style={{
                                                                                       cursor: "pointer",
                                                                                       marginBottom: "10px"
                                                                                   }}
                                                                                   onClick={() => setShowModalTerminerP(true)}>
                                            <IoStopCircleOutline style={{
                                                color: "black",
                                                fontSize: "20px",
                                                verticalAlign: "top",
                                                marginLeft: "0 7px"
                                            }}/> <span>Terminer</span>
                                        </div>}
                                        <div className="detail-presta-dropdown" style={{cursor: "pointer"}}
                                             onClick={() => setShowModalAnnulerP(true)}>
                                            <CloseCircleFilled style={{color: "red"}}/> <span>Annuler</span>
                                        </div>

                                    </div>
                                )}>
                                <img src="./assets/img/pen-colored.png" alt="pen"/>
                            </Popover>
                        </div>
                        <div className="status">{customStatus(prestation?.status)}</div>
                        {prestation?.status === "Non effectué" && <div
                            className="status">{prestation?.motif === null || prestation?.motif === "" ? "Aucun motif d'annulation signalé." : prestation?.motif}</div>}
                    </div>

                    <Row>
                        <Col lg={16} className="content-box-padding-left">
                            <div className="content-box content-box-top">
                                <div className="mintitle sans-pro-regular">
                                    Détails de la commande
                                </div>
                                <Row>
                                    <Col lg={12}>
                                        <div className="icon-content">
                                            <div className="icon"><IoCartOutline/></div>
                                        </div>
                                        <div className="content">
                                            <div className="head sans-pro-semi-bold">Service</div>
                                            <div className="element sans-pro-regular">
                                                <span>Type:</span> {prestation?.service} {prestation?.serviceAppointRef === "laundry_collect" &&
                                                <div style={{
                                                    display: "inline-block",
                                                    marginLeft: "3px"
                                                }}> ({prestation?.serviceDescription})</div>}
                                            </div>
                                            <div className="element sans-pro-regular">
                                                <span>Durée:</span>

                                                {prestation?.logement?.type === "apartment" && prestation?.service === "Ménage classique" && handleConvert(prestation?.logement?.cout_duree?.duree_menage_classique)}
                                                {prestation?.logement?.type === "apartment" && prestation?.service === "Ménage en profondeur" && handleConvert(prestation?.logement?.cout_duree?.duree_menage_profondeur)}
                                                {prestation?.logement?.type === "apartment" && prestation?.service === "Service d'appoint" && "1h 0"}
                                                {prestation?.logement?.type === "villa" && "illimité"}
                                            </div>
                                            <div className="element sans-pro-regular">
                                                <span>Créneau:</span> Entre {convertDateStringToTimeString(prestation?.creneau_intervention_debut)} et {convertDateStringToTimeString(prestation?.creneau_intervention_fin, "end")}
                                                <img src="./assets/img/pen-colored.png"
                                                     onClick={() => setShowModalCreneau(true)} alt="pen"/>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="icon-content">
                                            <div className="icon"><IoDocumentTextOutline/></div>
                                        </div>
                                        <div className="content">
                                            <div className="head sans-pro-semi-bold">Accès au logement
                                                <div className="iconMore">
                                                    <img src="./assets/img/open-icon-colored.png" className="more"
                                                         alt="more"/>
                                                </div>
                                            </div>
                                            <div className="element sans-pro-regular">
                                                <span>Adresse:</span> {prestation?.logement?.adresse}, {prestation?.logement?.ville}
                                            </div>
                                            <div className="element sans-pro-regular">
                                                <span>Guidage:</span> <a href="#">Voir les instruction d'accès</a>
                                            </div>
                                            <div className="element sans-pro-regular">
                                                <span>Gestion des clés:</span> {prestation?.logement?.gestion_cle}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <br/>
                                <br/>
                                <Row>
                                    <Col lg={12}>
                                        <div className="icon-content">
                                            <div className="icon"><IoDocumentTextOutline/></div>
                                        </div>
                                        <div className="content">
                                            <div className="head sans-pro-semi-bold">Linge <img
                                                src="./assets/img/pen-colored.png" className="display-none" alt="pen"
                                                onClick={() => setShowModalKit(true)}/></div>
                                            {
                                                prestation?.logement.laundryManagementType === "FULLY_MANAGED" &&
                                                <>
                                                    <div className="element sans-pro-regular">
                                                        <span>Fourni par:</span> Kliner
                                                    </div>
                                                    <div className="element sans-pro-regular">
                                                        <span>Linge simple:</span> {prestation?.details_info?.lit_simple} Kit
                                                    </div>
                                                    <div className="element sans-pro-regular">
                                                        <span>Linge double:</span> {parseInt(prestation?.details_info?.lit_double) + parseInt(prestation?.details_info?.lit_king)} Kits
                                                    </div>
                                                    <div className="element sans-pro-regular">
                                                        <span>Blanchisserie:</span> Kliner
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="icon-content">
                                            <div className="icon"><IoDocumentTextOutline/></div>
                                        </div>
                                        {!cart.isFetching &&
                                            <div className="content">
                                                <div className="head sans-pro-semi-bold">Kit d'accueil <img
                                                    src="./assets/img/pen-colored.png" className="display-none"
                                                    onClick={() => setShowModalAccueil(true)} alt="pen"/></div>
                                                <Row gutter={24}>
                                                    <Col>
                                                        <div className="element sans-pro-regular">
                                                            <span>Kit d'hygiène:</span> {kitCount(cart.data, "Kit Hygiène ")}
                                                        </div>
                                                        <div className="element sans-pro-regular">
                                                            <span>Kit d'entretien:</span>{kitCount(cart.data, "Kit Entretien")}
                                                        </div>
                                                        <div className="element sans-pro-regular">
                                                            <span>Kit petit Déjeuner:</span> {kitCount(cart.data, "Kit Petit déjeuner")}
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="element sans-pro-regular">
                                                            <span>Mini Kit Hygiène:</span> {kitCount(cart.data, "Mini Kit Hygiène")}
                                                        </div>
                                                        <div className="element sans-pro-regular">
                                                            <span>Mini Kit Entretien:</span> {kitCount(cart.data, "Mini Kit Entretien")}
                                                        </div>
                                                        <div className="element sans-pro-regular">
                                                            <span>Mini Kit Petit déjeuner:</span> {kitCount(cart.data, "Mini Kit Petit déjeuner")}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="content-box content-box-top">
                                <div className="mintitle sans-pro-regular">
                                    Equipe d'entretien
                                </div>
                                <div className="content-responsable">
                                    <div className="user-icon">
                                        <img
                                            src={prestation?.responsable?.avatar ? `${API_ROOT}/${prestation?.responsable?.avatar}` : "./assets/img/logon.png"}
                                            alt="photo"/>
                                    </div>
                                    <div className="user-info sans-pro-regular">
                                        <div
                                            className="name sans-pro-semi-bold">{prestation?.responsable?.firstname} {prestation?.responsable?.lastname}</div>
                                        <div
                                            className="status disable sans-pro-regular color-A7A7A7">{prestation?.responsable?.entreprise}</div>
                                        <div
                                            className="number disable sans-pro-regular color-A7A7A7">{prestation?.responsable?.phone}</div>
                                    </div>
                                    <div className="user-button">
                                        <Badge color={"violet"} className="sans-pro-regular"
                                               count={"Responsable"}></Badge>
                                    </div>
                                </div>
                                <div className="content-aide sans-pro-regular">
                                    <div className="user-icon">
                                        <img
                                            src={prestation?.aide_menagere?.avatar ? `${API_ROOT}/${prestation?.aide_menagere?.avatar}` : "./assets/img/logon.png"}
                                            alt="photo"/>
                                    </div>
                                    <div className="user-info">
                                        <div
                                            className="name sans-pro-regular">{prestation?.aide_menagere?.firstname} {prestation?.aide_menagere?.lastname}</div>
                                    </div>
                                    <div className="user-button">
                                        <Badge color={"lightgrey"} className="sans-pro-regular"
                                               count={"Aide-ménagère"}></Badge>
                                    </div>
                                </div>
                                <div className="instruction sans-pro-regular">
                                    <a href="#" onClick={() => setShowModalInstructionSpecial(true)}> <img src="/assets/img/instruction.png" style={{width: "20px", verticalAlign: "top", marginRight:"5px"}}  alt="airbnb" className="title-img" />
                                        Laisser des instructions de ménage pour cette prestation</a>
                                    {prestation?.instruction !== null && prestation?.instruction !== "" && <img src="/assets/img/green-check.png" style={{
                                        width: "15px",
                                        verticalAlign: "top",
                                        marginLeft: "15px",
                                        marginTop: "4px"
                                    }} alt="airbnb" className="title-img"/>}
                                </div>
                                <div className="send-message sans-pro-regular">
                                    <Button className="disable" style={{cursor: "default"}}>
                                        <div className="icon">
                                            <img src="./assets/img/sen- email-icon-grey.png" alt="photo"/>
                                        </div>
                                        Envoyer un message à l'équipe</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col lg={16} className="content-box-padding-left">
                            <Row>
                                <Col lg={12} className="content-min-box-padding-left">
                                    <Row>
                                        <Col lg={24}>
                                            <div className="content-box">
                                                <div className="mintitle custom-padding sans-pro-regular">
                                                    Contrôle qualité
                                                    <div className="linkBottom sans-pro-regular">
                                                        <a href="#"
                                                           onClick={() => setShowModalControle(true)}>Controler</a>
                                                    </div>
                                                </div>
                                                <div className="status-progress">
                                                    <div className="label"> {getPercent()}%</div>
                                                    <div className="progresscontent">
                                                        <Progress percent={getPercent()} showInfo={false}
                                                                  strokeColor="#74DA74"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={24}>
                                            <div className="content-box" style={{marginTop: "20px"}}>
                                                <div className="mintitle custom-padding sans-pro-regular">
                                                    Notes reçues
                                                    <div className="linkBottom sans-pro-regular">
                                                        <div className="note">
                                                            <div className="imageEtoile">
                                                                <img src="./assets/img/star-black.png" alt=""/>
                                                            </div>
                                                            <div className="value sans-pro-semi-bold">
                                                                {prestation?.quality_control?.final_stars ?? 0}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="status-progress noteSide">
                                                    <Row>
                                                        <Col lg={1}></Col>
                                                        <Col lg={6}>
                                                            <div className="label-note">Kliner</div>
                                                            <div className="value">
                                                                <div className="note">
                                                                    <div className="imageEtoile">
                                                                        <img src="./assets/img/star-black.png" alt=""/>
                                                                    </div>
                                                                    <div className="value sans-pro-semi-bold">
                                                                        {prestation?.quality_control?.bo_stars ?? 0}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <div className="verticalDivider"></div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="label-note">Client</div>
                                                            <div className="value">
                                                                <div className="note">
                                                                    <div className="imageEtoile">
                                                                        <img src="./assets/img/star-black.png" alt=""/>
                                                                    </div>
                                                                    <div className="value sans-pro-semi-bold">
                                                                        {prestation?.quality_control?.customer_stars ?? 0}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="link"><a href="#">Feedback</a></div>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <div className="verticalDivider"></div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="label-note">Voyageur</div>
                                                            <div className="value">
                                                                <div className="note">
                                                                    <div className="imageEtoile">
                                                                        <img src="./assets/img/star-black.png" alt=""/>
                                                                    </div>
                                                                    <div className="value sans-pro-semi-bold">
                                                                        {prestation?.quality_control?.traveler_stars ?? 0}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="link"><a href="#">Commentaires</a></div>
                                                        </Col>
                                                        <Col lg={1}></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={24} className={prestation?.status === "Terminé" ? "" : "disabled-block"}>
                                                            <div style={{textAlign: "center", marginTop:"50px", cursor:"pointer"}} onClick={() => setShowControleSinPrestation(true)}>
                                                                <EyeFilled style={{fontSize:"18px", verticalAlign:"top", marginTop:"2.5px"}}/> <a href="#" style={{fontSize:"14px"}}>Rapport de prestation</a>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12} className="content-min-box-padding-right">
                                    <div className="content-box content-box-bottom">
                                        <div className="mintitle sans-pro-regular">
                                            Activité
                                        </div>
                                        <div style={{overflowY: "scroll", height:"90%"}} className="scroll-none">
                                            {logList?.data?.messages?.map((item, index) =>
                                                (<div className="log" key={"Log_" + index}>
                                                        <div className="image">
                                                            <BranchesOutlined/>
                                                        </div>
                                                        <div className="description sans-pro-regular">
                                                            {eventText(item)}
                                                            <div
                                                                className="timer">{convertDateStringToTimeStringComplete(item?.events_log_actionDate)}</div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {/*<div className="log">
                                            <div className="image">
                                                <BranchesOutlined/>
                                            </div>
                                            <div className="description sans-pro-regular">
                                                <div className="libelle">La prestation a été créée
                                                    par <span>Brice</span>
                                                </div>
                                                <div className="timer">06.12.24 à 10:38</div>
                                            </div>
                                        </div>
                                            <div className="log">
                                            <div className="image">
                                            <CalendarOutlined />
                                            </div>
                                            <div className="description sans-pro-regular">
                                            <div className="libelle"><span>Martine</span> a démarré la prestation</div>
                                            <div className="timer">06.12.24 à 10:38</div>
                                            </div>
                                            </div>
                                            <div className="log">
                                            <div className="image">
                                            <BranchesOutlined />
                                            </div>
                                            <div className="description sans-pro-regular">
                                            <div className="libelle"><span>Martine</span> a ajouté les <a href="#">photos/vidéos
                                            Avant du logement</a></div>
                                            <div className="timer">06.12.24 à 10:38</div>
                                            </div>
                                            </div>
                                            <div className="log">
                                            <div className="image">
                                            <FileTextOutlined />
                                            </div>
                                            <div className="description sans-pro-regular">
                                            <div className="libelle"><span>Martine</span> a terminé la prestation</div>
                                            <div className="timer">06.12.24 à 10:38</div>
                                            </div>
                                            </div>
                                            <div className="log">
                                            <div className="image">
                                            <FileTextOutlined />
                                            </div>
                                            <div className="description sans-pro-regular">
                                            <div className="libelle"><span>Martine</span> a ajouté les <a href="#">photos/vidéos
                                            Avant du logement</a></div>
                                            <div className="timer">06.12.24 à 10:38</div>
                                            </div>
                                            </div>
                                            <div className="log">
                                            <div className="image">
                                            <FileTextOutlined />
                                            </div>
                                            <div className="description sans-pro-regular">
                                            <div className="libelle"><span>Kliner</span> a contrôlé la prestation et
                                            elle a obtenu une note générale de 4.2 sur 5
                                            </div>
                                            <div className="timer">06.12.24 à 10:38</div>
                                            </div>
                                            </div>*/}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={8}>
                            <div className="content-box content-box-bottom">
                                <div className="mintitle sans-pro-regular">
                                    Clôture
                                    <div className="linkBottom sans-pro-regular">
                                        <a href="#" onClick={() => setShowModalControle(true)}>Ajuster</a>
                                    </div>
                                </div>
                                <div className="title-ttc sans-pro-regular">Total TTC dû</div>
                                <Row>
                                    <Col lg={12}>
                                        <div
                                            className="price sans-pro-regular">€ {coutTTC(userId?.profil ?? userId?.user_profil, prestation)} (EURO)
                                        </div>
                                        <div
                                            className="hors-taxe sans-pro-regular color-A7A7A7">Soit {coutHorsTax(userId?.profil ?? userId?.user_profil, prestation)} Hors
                                            Taxes
                                        </div>
                                    </Col>

                                    <Col lg={7}>
                                            <div>
                                                <Tooltip color='white' title={
                                                        <>
                                                        <p><strong>Détail facturé</strong> <br/></p>
                                                        <p style={{color: 'black'}}>Service ({prestation?.service}) .....{prestation?.details_info?.cout_menage ?? prestation?.cout_menage} €</p>
                                                        <p style={{color: 'black'}}>Linge .......................... {prestation?.details_info?.cout_kit_linge ?? prestation?.cout_kit_linge} €</p>
                                                        <p style={{color: 'black'}}>Kit d’accueil ................... {prestation?.details_info?.cout_kit_accueil ?? prestation?.cout_kit_accueil}€</p>
                                                        <p style={{color: 'black'}}>Matériel nettoyage .................... {prestation?.details_info?.cout_kit_nettoyage ?? prestation?.cout_kit_nettoyage}€</p>
                                                        <p style={{color: 'black'}}>Produits ménagers ................ {prestation?.details_info?.cout_produit_menager ?? prestation?.cout_produit_menager}€</p>
                                                        <Divider/>
                                                        <p><strong><em>Total TVA ({profileVAT(userId?.profil ?? userId?.user_profil)}%) ................ {coutTTC(userId?.profil ?? userId?.user_profil, prestation)}€</em></strong></p>
                                                        </>
                                                    } >
                                                        <img src='/assets/img/info-pop-up_colored.png' className="title-img" width={`28px`} alt="info"/>
                                                    </Tooltip>
                                            </div>
                                    </Col>
                                    <Col lg={5}>
                                        <Button className="a-regler sans-pro-regular">A régler</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={10}>
                                        {moyenPaiement?.data === null &&
                                            <div className="card-zone">
                                                <Row>
                                                    <Col lg={4}>
                                                        <div className="card-image">
                                                            <img src="./assets/img/empty-payment-icon.png" alt="check"/>
                                                        </div>
                                                    </Col>
                                                    <Col lg={2}/>
                                                    <Col lg={17}>
                                                        <div className="card-number sans-pro-regular color-A7A7A7">
                                                            ****3182
                                                        </div>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <div className="check-image">
                                                            <img src="./assets/img/no card icon.png" alt="check"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>}
                                        {moyenPaiement?.data?.type === "sepa_debit" &&
                                            <div className="card-zone">
                                                <Row>
                                                    <Col lg={4}>
                                                        <div className="card-image">
                                                            <img src="./assets/img/sepa-icon.png" alt="check"/>
                                                        </div>
                                                    </Col>
                                                    <Col lg={2}/>
                                                    <Col lg={17}>
                                                        <div className="card-number sans-pro-regular color-A7A7A7">
                                                            **** {moyenPaiement?.data?.sepa_debit.last4}
                                                        </div>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <div className="check-image">
                                                            <img src="./assets/img/green-check.png" alt="check"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>}
                                        {moyenPaiement?.data?.type === "card" &&
                                            <div className="card-zone">
                                                <Row>
                                                    <Col lg={4}>
                                                        <div className="card-image">
                                                            <img
                                                                src={moyenPaiement?.data?.card?.brand === "visa" ? "./assets/img/visa icon.webp" : moyenPaiement?.data?.card?.brand === "visa" ? "./assets/img/mastercard icon.webp" : "./assets/img/amex icon.png"}
                                                                alt="check"/>
                                                        </div>
                                                    </Col>
                                                    <Col lg={2}/>
                                                    <Col lg={17}>
                                                        <div className="card-number sans-pro-regular color-A7A7A7">
                                                            **** {moyenPaiement?.data?.card.last4}
                                                        </div>
                                                    </Col>
                                                    <Col lg={1}>
                                                        <div className="check-image">
                                                            <img src="./assets/img/green-check.png" alt="check"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>}

                                    </Col>
                                    <Col lg={14}>
                                        <div className="indication-facturation sans-pro-regular color-A7A7A7">
                                            Facturé le 1er du mois suivant ou dès<br/> l’atteinte
                                            de votre plafond budgétaire de {subscriptionThreshold?.data?.amount}€
                                        </div>
                                    </Col>
                                </Row>
                                <div className="minTtile sans-pro-semi-bold">
                                    Ajustements
                                </div>
                                <Divider />
                                <div style={{textAlign: 'center'}}>
                                     <p style={{textAlign: 'center'}}>Marge brute</p>
                                     <Tag color={marginalProfitTagColor(prestation)} style={{fontSize: "10px"}}>{computeMarginalProfit(prestation)} €</Tag>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <UpdateTime
                        status={showModalCreneau}
                        handleCancel={() => setShowModalCreneau(false)}
                        prestationId={prestation?.id}
                        creneauDebut={prestation?.creneau_intervention_debut}
                        creneauFin={prestation?.creneau_intervention_fin}
                    />
                    <KitDeLingeModalView
                        status={showModalKit}
                        handleCancel={() => setShowModalKit(false)}
                    />
                    <KitAccueilModalView
                        status={showModalAccueil}
                        handleCancel={() => setShowModalAccueil(false)}
                    />
                    <ControleQualiteModalView
                        status={showModalControle}
                        handleCancel={() => setShowModalControle(false)}
                        quality={prestation?.quality_control}
                        getPercent={getPercent()}
                    />
                    <EvaluationModalView
                        status={showModalEvaluation}
                        handleCancel={() => setShowModalEvaluation(false)}
                    />
                    <AnnulerPrestationModalView
                        prestationId={prestationId}
                        status={showModalAnnulerP}
                        handleCancel={() => setShowModalAnnulerP(false)}
                    />
                    <TerminerPrestationModalView
                        prestationId={prestationId}
                        status={showModalTerminerP}
                        handleCancel={() => setShowModalTerminerP(false)}
                    />
                    <DemarrerPrestationModalView
                        prestationId={prestationId}
                        status={showModalDemarrerP}
                        handleCancel={() => setShowModalDemarrerP(false)}
                    />
                    <InstructionSpecialeModalView
                        status={showModalInstructionSpecial}
                        prestaInstruction={prestation?.instruction}
                        prestationId={prestation?.id}
                        handleCancel={() => setShowModalInstructionSpecial(false)}
                    />
                    <ControleFinPrestationPage
                        controle={prestation?.quality_control}
                        status={showControleSinPrestation}
                        dysfonctionnement = {prestation?.dysfunctions}
                        handleCancel={() => setShowControleSinPrestation(false)}
                    />
                </div>
            </Spin>
        </Modal>
    );
};
export default PlanningDetail;
