import React, { useState } from "react";
import {Modal, Divider, Row, Col, Spin, message} from "antd";
import {useClientUpdateBillingMutation} from "../../services/client-api";
import {useDispatch} from "react-redux";
import {setRefresh} from "../../../logements/services/logement-slice";

const ModifierPaiementDelaiModalView = ({ user, data, refresh, status, handleCancel }) => {
    const [billingTypeUpdate] = useClientUpdateBillingMutation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const handleUserUpdateBilling = async () => {
        billingTypeUpdate({id: user?.id , data: data}).unwrap()
            .then((res) => {
                dispatch(setRefresh(true));
                refresh();
                handleCancel();
                message.success('Délais de paiement modifié!')
            })
            .catch(() => {
                message.error('Erreur lors de la modification du délais de paiement!')
                setIsLoading(false);
            });
    };

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={600}
            className="delaiPaiementModal">

            <Spin spinning={isLoading}>
                <div className="supprimer">
                    <div className="title sans-pro-semi-bold"><img src="/assets/img/info-pop-up_colored.png" alt="airbnb" className="title-img" /><div className="title-label">Modifier de le delai de paiement
                    </div></div>
                    <Row>
                        <Col>
                            <span>
                                Vous vous apretez à modifier le mode le delai de paiement du client {user?.firstname} {user?.lastname}
                            </span>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={12} className="button-left">
                                <a href="#" onClick={() => handleCancel()} className="annuler"><span> Annuler la demande</span></a>
                            </Col>
                            <Col lg={11} className="button-right">
                                <a href="#" onClick={() =>  handleUserUpdateBilling()}
                                   className="suivant sans-pro-regular">Confirmer</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default ModifierPaiementDelaiModalView;
