import React, {useState} from 'react';
import {Col, Row} from "antd";

function QuelProfilPage() {
    const [selectItem, setSelectItem] = useState(null);

    return (
        <div className="content-in">
            <div className="head">
                <div className="image">
                    <img src="./assets/img/logon.png" alt="profil"/>
                </div>
                <div className="name">
                    Brice,
                </div>
            </div>
            <div className="titlePage sans-pro-bold">
                Quel est le profil de votre partenaire ménage ?
            </div>
            <div className="subTitle sans-pro-regular">
                Cela nous permettra de déterminer le type de relation que nous devrons mettre en
                place.
                <a href="https://help.kliner.com/fr/articles/9343012-comment-kliner-gere-le-defi-administratif-pour-integrer-vos-equipes-sur-la-plateforme" target="_blank">En savoir plus</a>
            </div>
            <div className="choice">
                <Row>
                    <Col lg={9}>
                        <div className={"blockChoice "+(selectItem === "PME" ? "active" : "")} onClick={()=> setSelectItem("PME")}>
                            <div className="image">
                                <img src="./assets/img/PME icon.png" alt="icone"/>
                            </div>
                            <div className="blockTitle sans-pro-semi-bold">
                                PME de nettoyage
                            </div>
                            <div className="blockDesc sans-pro-regular">
                                Une société qui emploie plusieurs <br/> aide-ménagères.
                            </div>
                        </div>
                    </Col>
                    <Col lg={9}>
                        <div className={"blockChoice "+(selectItem === "Auto" ? "active" : "")} onClick={()=> setSelectItem("Auto")}>
                            <div className="image">
                                <img src="./assets/img/Auto-E icon.png" alt="icone"/>
                            </div>
                            <div className="blockTitle sans-pro-semi-bold">
                                Auto-entrepreneur
                            </div>
                            <div className="blockDesc sans-pro-regular">
                                Une aide-ménagère qui travaille <br/> pour sa micro-entreprise.
                            </div>
                        </div>
                    </Col>
                    <Col lg={9}>
                        <div className={"blockChoice "+(selectItem === "Emploi" ? "active" : "")} onClick={()=> setSelectItem("Emploi")}>
                            <div className="image">
                                <img src="./assets/img/emploi direct icon.png" alt="icone"/>
                            </div>
                            <div className="blockTitle sans-pro-semi-bold">
                                Emploi direct
                            </div>
                            <div className="blockDesc sans-pro-regular">
                                Un particulier que vous payez en <br/> direct de façon déclarée ou non.
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default QuelProfilPage;