import React, {useState} from 'react';
import "../../styles/style.scss"
import {Col, Row} from "antd";
import HeureTerminerApercu from "../apercu/heure-terminer-apercu";
//prestation_finished_on_time
const HeureTerminerPart = ({next, setHeureTerminerPart}) => {
    const [choix, setChoix] = useState('');
    return (
        <div className='AideMenagePart'>
            <Row>
                <Col lg={2}>
                    <img src="./assets/img/green-check.png" alt="icon"/>
                </Col>
                <Col lg={19}>
                    <div className="question">
                        La prestation a-t-elle été terminée à l’heure prévue ?
                    </div>
                    <div className="decision">
                        <div className={"oui "+(choix === "oui" ? " active " : "")+(choix === "" ? " colorBlack" : "")} onClick={()=> {
                            setChoix("oui");
                            setHeureTerminerPart({prestation_finished_on_time:true});
                        }}>OUI</div>
                        <div className={"non "+(choix === "non" ? " active " : "")+(choix === "" ? " colorBlack" : "")} onClick={()=> {
                            setChoix("non");
                            setHeureTerminerPart({prestation_finished_on_time:false});
                        }}>NON</div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="link">
                        <a href="#" className={choix === "" ? " disable" : ""} onClick={choix !== "" ? next : null}>Suivant</a>
                    </div>
                </Col>
            </Row>
            {choix !== "" && <div className="apercu">
                <HeureTerminerApercu choix={choix}/>
            </div>}
        </div>
    );
}

export default HeureTerminerPart;
