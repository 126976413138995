import React from 'react';
import "../../styles/style.scss"
import {Col, Row} from "antd";

const AideMenageApercu = ({choix}) => {
    return (
        <div className='AideMenageApercu'>
            <Row>
                <Col lg={2}>

                </Col>
                <Col lg={22}>
                   <div className="apercu-content">
                        <Row>
                            <Col lg={2}>
                                <div className="img">
                                    <img src="./assets/img/light-colored.png" alt="icone"/>
                                </div>
                            </Col>
                            <Col lg={22}>
                                {choix === "oui" && <div className="text">
                                    Le système a automatiquement détecté l’assignation d’une aide-ménagère ménagère à
                                    cette prestation.
                                </div>}
                                {choix === "non" && <div className="text">
                                    Aucune aide-ménagère ne semble être assignée à cette prestation. Veuillez résoudre le problème en suivant ces étapes :
                                    <br/>
                                    <br/>
                                    <div>1. Demander au responsable de site de trouver une solution</div>
                                    <div>2. Attribuer directement la prestation à un autre partenaire</div>
                                    <div>3. Proposer la gestion du logement au réseau de partenaires</div>
                                </div>}
                            </Col>
                        </Row>
                   </div>
                </Col>
            </Row>
        </div>
    );
}

export default AideMenageApercu;
