import React, {useState} from 'react';
import "../styles/style.scss"
import {Button, Col, Row, Select} from "antd";

function EquipesPartPage() {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="equipe-part-page">
            <h2 className="sans-pro-bold">Equipe</h2>
            <div className="line">
                <Button>Inviter</Button>
            </div>
            <div className="equipe-content-profil">
                <div className="element">
                    <Row>
                        <Col lg={1}>
                            <div className="icone">
                                <img src="./assets/img/logon.png" alt="image"/>
                            </div>
                        </Col>
                        <Col lg={13}>
                            <div className="name">
                                <div className="nom sans-pro-regular">Brice Ngouo <div className="you">You</div></div>
                                <div className="email">bngouo@kliner.com</div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="select">
                                <Select
                                    disabled={true}
                                    defaultValue="Admin"
                                    onChange={null}
                                    suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                    options={[
                                        { value: 'Admin', label: 'Admin' },
                                        { value: 'Controleur', label: 'Controleur' },
                                    ]}
                                />
                            </div>
                        </Col>
                        <Col lg={5} />
                        <Col lg={1}>
                            <div className="action">
                                <img src="./assets/img/logon.png" alt="image"/>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="element">
                    <Row>
                        <Col lg={1}>
                            <div className="icone">
                                <img src="./assets/img/logon.png" alt="image"/>
                            </div>
                        </Col>
                        <Col lg={13}>
                            <div className="name">
                                <br/>
                                <div className="email">bngouo@kliner.com</div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="select">
                                <div className="invit-status">Invitation envoyée</div>
                            </div>
                        </Col>
                        <Col lg={5} />
                        <Col lg={1}>
                            <div className="action">
                                <img src="./assets/img/logon.png" alt="image"/>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="element">
                    <Row>
                        <Col lg={1}>
                            <div className="icone">
                                <img src="./assets/img/logon.png" alt="image"/>
                            </div>
                        </Col>
                        <Col lg={13}>
                            <div className="name">
                                <div className="nom sans-pro-regular">Brice Ngouo </div>
                                <div className="email">bngouo@kliner.com</div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="select">
                                <Select
                                    defaultValue="Manager"
                                    onChange={null}
                                    suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                    options={[
                                        { value: 'Manager', label: <div className="selectProfilPart">
                                                <div className="head">Manager</div>
                                                <div className="text">Dispose de tous les droits</div>
                                            </div> },
                                        { value: 'Chef de projet', label: <div className="selectProfilPart">
                                                <div className="head">Chef de projet</div>
                                                <div className="text">Tous les droits mais ne peut pas gérer les <br/>
                                                    équipes, accéder aux rapports ou facturer</div>
                                            </div> },
                                        { value: 'Analyste', label: <div className="selectProfilPart">
                                                <div className="head">Analyste</div>
                                                <div className="text">Accède à tous les menus en lecture seule.</div>
                                            </div> },
                                    ]}
                                    popupClassName="selectProfilPartSelect"
                                />
                            </div>
                        </Col>
                        <Col lg={5} />
                        <Col lg={1}>
                            <div className="action">
                                <img src="./assets/img/logon.png" alt="image"/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default EquipesPartPage;
