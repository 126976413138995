import React, {useEffect, useState} from 'react';
import {Button, Row, Col, Table, Dropdown, Menu, Progress, Avatar, Drawer, Badge} from 'antd';
import {IoEllipsisHorizontal} from "react-icons/io5";
import {useSelector} from "react-redux";
import "./styles/style.scss"
import Controle from "./controle";
import Reviews from "./review";
import {useEquipeEntretienFetchQuery} from "./services/dashboard-entretien";
import {API_ROOT, checkControlLevel, convertDateStringToTimeStringDateOnly} from "../../utils/global-var";
import moment from "moment";
import LogementsDrawerPage from "../logements/drawer/logement-drawer";
import PlanningDetail from "../planning/planning_detail";


function DashBoardPage() {
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModalDemand, setShowModalDemand] = useState(false);
    const [valid, setValid] = useState(false);
    const {user} = useSelector((state) => state.auth);
    const [showModalPub, setShowModalPub] = useState(false);
    const [showModalControle, setShowModalControle] = useState(false);
    const [showModalReview, setShowModalReview] = useState(false);
    const [showModalProgrammeApercu, setShowModalProgrammeApercu] = useState(false);
    const [currentLogementToCheck, setCurrentLogementToCheck] = useState(null);
    const getEntretienList = useEquipeEntretienFetchQuery({id:1, page: 100, order: "ASC"});
    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState(null);
    const [prestationId, setPrestationId] = useState(false);
    const [recorded, setRecord] = useState(null);

    useEffect(() => {
        if(valid){
            setShowModalDemand(true);
        }
        setValid(false);
    }, [valid]);

    const onClose = () => {
        setOpen(false);
    };

    console.log(getEntretienList);
    const getMenuItems = (record) => [
        {
            label: <div className="add-controler-prestation sans-pro-regular" onClick={() => {
                setShowModalControle(true);
                setCurrentLogementToCheck(record);
            }}>
                <div className="imageSide">
                    <img src="../assets/img/attribuer-lgt-icon.png" alt="add" />
                </div>
                Controler la prestation
            </div>,
            key: '1itemsOption',
        }
    ];

    const columns = [
        {
            title: "Logement",
            width: "20%",
            render: (record) => (
                <div className="name" style={{cursor:"pointer"}} onClick={()=> {setRecord(record);  setOpen(true) ;}}>
                    {record.logement_libelle}
                </div>
            ),
        },
        {
            title: 'Date de prestation',
            width: "15%",
            render: (record) => (
                <div  onClick={()=> {setPrestationId(record.prestation_id); setUserId("");  setShowModalProgrammeApercu(true);}} style={{cursor:"pointer"}}>
                    <div className="date">
                        {convertDateStringToTimeStringDateOnly(record.prestation_creneau_intervention_debut)}
                    </div>
                </div>
            ),
        },
        {
            title: 'Statut',
            width: "15%",
            render: (record) => (
                <div>
                    {(record.prestation_status === "Terminé") && (<div className="badge-green-plein" >Fait</div>)}
                    {(record.prestation_status === "Programmé") && (<div className="badge-black-plein" >A faire</div>)}
                    {(record.prestation_status === "En cours") && (<div className="badge-orange-plein">
                       En cours
                    </div>)}
                    {(record.prestation_status === "En attente") && (<div className="badge-black-plein">
                       En cours
                    </div>)}
                    {(record.prestation_status === "Annulé") && (<div className="badge-red-plein">
                        Annulé
                    </div>)}
                    {(record.prestation_status === "suspended") && (<div className="badge-violet-plein">
                        Suspendu
                    </div>)}
                    {(record.prestation_status === "Abandonnée") && (<div className="badge-red-plein">
                        Abandonnée
                    </div>)}
                    {(record.prestation_status === "Programmation en cours") && (<div className="badge-yellow-plein">
                        Prog
                    </div>)}
                    {(record.prestation_status === "Non effectué") && (<div className="badge-red-plein">
                        Non effectué
                    </div>)}
                </div>
            ),
        },

        {
            title: 'Responsable de site',
            width: "15%",
            render: (record) => (
                <div className="image">
                    <Avatar src={`${API_ROOT}/${record.responsable_avatar}`} alt="icone"/>
                </div>
            ),
        },
        {
            title: 'Type de contrôle',
            width: "15%",
            render: (record) => (
                <div>
                    <div className="type">
                        Distance
                    </div>
                </div>
            ),
        },
        {
            title: '',
            width: "20%",
            render: (record) => (
                <div>
                    {checkControlLevel(record) === 100 && <div className="complete">
                        <div className="image-statut">
                            <img src="./assets/img/green-check.png" alt="icone"/>
                        </div>
                        effectué
                    </div>}
                    {checkControlLevel(record) < 100 && <div  className="incomplete">
                        <div className="image-statut">
                            <img src="./assets/img/warning icon - Modifié - Modifié - Modifié.png" alt="icone"/>
                        </div>
                        {checkControlLevel(record)}%
                    </div>}
                </div>
            ),
        },
        {
            title: "",
            key: "option",
            width: "6%",
            render: (record) => (
                <div  className="tab-option">
                    {(record.prestation_status !== "Annulé") && (checkControlLevel(record) !== 100) && <Dropdown
                        overlay={<Menu items={getMenuItems(record)}/>}
                        placement="bottomRight" trigger={['click']}>
                        <IoEllipsisHorizontal style={{fontSize: "19px", marginRight: "10px"}}/>
                    </Dropdown>}
                </div>
            ),
        },
    ];

    console.log(getEntretienList);
    return (
        <>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Tableau de bord</h1>
                </div>
            </div>

            <div className='page-content'>
                <div className='section-1'>
                    <p className=''>Contrôle qualité à effectuer ce jour </p>
                    <Row>
                        <Col lg={24}>
                            <div className="tabDashZone">
                                <Table
                                    className="dashboard-table"
                                    pagination={{ pageSize: 5 }}
                                    dataSource={getEntretienList?.data?.prestations}
                                    columns={columns}
                                    rowKey={(record) => record.key}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>


                <div className='section-2'>
                    <p className='section-title'>Besoin d’aide, {user?.firstname} ? On est là pour toi !</p>
                    <Row gutter={24}>
                        <Col lg={8}>
                            <div className='blc'>
                                <div className='flex'>
                                    <img src='./assets/img/icon-support.png' className='img i1' alt='kliner'/>
                                    <div className='blc-text'>
                                        <h4>Assister les utilisateurs</h4>
                                        <p>Apportons un support de classe mondiale à tous nos utilisateurs et interlocuteurs.</p>
                                    </div>
                                </div>
                                <a href='https://app.intercom.com/a/inbox/zt3ti943/inbox/shared/all/conversation/9236?view=List' target="_blank">
                                    <Button type="default" className='btn-lg'>Ouvrir Intercom </Button>
                                </a>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className='blc'>
                                <div className='flex'>
                                    <img src='./assets/img/beret.png' className='img i2' alt='kliner'/>
                                    <div className='blc-text'>
                                        <h4>Formation</h4>
                                        <p>Consignes à suivre pour assister efficacement nos clients.</p>
                                    </div>
                                </div>
                                <a href="https://help.kliner.com/fr/collections/9530455" target="_blank">
                                    <Button type="default" className='btn-lg'>Ouvrir le centre de formation</Button>
                                </a>
                            </div>
                        </Col>

                        <Col lg={8}>
                            <div className='blc'>
                                <div className='flex'>
                                    <img src='./assets/img/icon-ameliorer.png' className='img' alt='kliner'/>
                                    <div className='blc-text'>
                                        <h4>Couverture des services Kliner</h4>
                                        <p>Aperçu détaillé de la couverture géographique des services Kliner</p>
                                    </div>
                                </div>
                                <Button type="default" className='btn-lg disable' onClick={() => null}>Ouvrir la carte</Button>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/*<div className='section-3'>
                    <p className='section-title'>Appréciation de nos services par les clients</p>
                    <div className="blc">
                        <Row>
                            <Col lg={4}>
                                <div className="image">
                                    <img src="./assets/img/logon.png" alt="icone"/>
                                </div>
                                <div className="review-number sans-pro-semi-bold">
                                    34
                                </div>
                                <div className="label-review sans-pro-semi-bold">
                                    Total reviews
                                </div>
                            </Col>
                            <Col lg={13}>
                                <Progress percent={70} showInfo={false} strokeColor="rgb(132,200,84)" trailColor="#EDEDED" />
                                <Progress percent={30} showInfo={false} strokeColor="rgb(255,181,44)" trailColor="#EDEDED" className="neutres" />
                                <Progress percent={5} showInfo={false} strokeColor="rgb(255,113,110)" trailColor="#EDEDED" className="detracteur" />
                            </Col>
                            <Col lg={2} />
                            <Col lg={5}>
                                <div className="avis-review-percent">Ambassadeur(<a href="#" onClick={() => setShowModalReview (true)}>68%</a>)</div>
                                <br/>
                                <div className="avis-review-percent">Neutres(<a href="#"  onClick={() => setShowModalReview (true)}>22%</a>)</div>
                                <br/>
                                <div className="avis-review-percent">Détracteurs(<a href="#"  onClick={() => setShowModalReview (true)}>12%</a>)</div>
                            </Col>
                        </Row>
                    </div>
                </div>*/}
            </div>
            {currentLogementToCheck !== null && <Controle
                status={showModalControle}
                currentLogementToCheck={currentLogementToCheck}
                handleCancel={() => setShowModalControle(false)}
            />}
            {/*<Reviews
                status={showModalReview}
                handleCancel={() => setShowModalReview(false)}
            />*/}
            {userId !== null && <PlanningDetail
                prestationId={prestationId}
                status={showModalProgrammeApercu}
                userId={userId}
                handleCancel={() => setShowModalProgrammeApercu(false)}
            />}
            <br/>
            {recorded !== null && <Drawer title="" onClose={onClose} open={open} width={500} className="client-custom-drawer-class">
                <LogementsDrawerPage record={recorded} close={() => {
                    setOpen(false);
                }}/>
            </Drawer>}
        </>
    );
}

export default DashBoardPage;
