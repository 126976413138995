import React, {useEffect, useState} from 'react';
import "../../styles/style.scss"
import {Col, Row, Select} from "antd";

const AvisFinalPart = ({setAvisFinalPart, setAvisFinalPartNote}) => {
    const [selected, setSelected] = useState('');
    console.log(selected);
    useEffect(() => {
        setSelected('');
    }, []);
    const onChangeSelect = (value) =>{
        setAvisFinalPart({controle_manuelle_sur_photo: false, controle_video_en_direct: false});
        if(value === "oui-analyse"){
            setAvisFinalPart({controle_manuelle_sur_photo: true});
        }
        if(value === "oui-video"){
            setAvisFinalPart({controle_video_en_direct: true});
        }
    }
    return (
        <div className='AideMenagePart'>
            <Row>
                <Col lg={2}>
                    <img src="./assets/img/green-check.png" alt="icon"/>
                </Col>
                <Col lg={19}>
                    <div className="question">
                        Comment jugez-vous la qualité de la prestation ?
                    </div>
                    <div className="decision">
                        <div className="sticker">
                            <Row>
                                <Col lg={5}><div className={"only-sticker "+(selected === "nul" ? "active" : "")} onClick={()=> {
                                    setSelected("nul");
                                    setAvisFinalPartNote({bo_stars:1});
                                }}>🥲</div></Col>
                                <Col lg={5}><div className={"only-sticker "+(selected === "peu" ? "active" : "")} onClick={()=> {
                                    setSelected("peu");
                                    setAvisFinalPartNote({bo_stars:2});
                                }}>🙁️</div></Col>
                                <Col lg={5}><div className={"only-sticker "+(selected === "moyen" ? "active" : "")} onClick={()=> {
                                    setSelected("moyen");
                                    setAvisFinalPartNote({bo_stars:3});
                                }}>😕</div></Col>
                                <Col lg={5}><div className={"only-sticker "+(selected === "bien" ? "active" : "")} onClick={()=> {
                                    setSelected("bien");
                                    setAvisFinalPartNote({bo_stars:4});
                                }}>🙂</div></Col>
                                <Col lg={4}><div className={"only-sticker "+(selected === "excelent" ? "active" : "")} onClick={()=> {
                                    setSelected("excelent");
                                    setAvisFinalPartNote({bo_stars:5});
                                }}>😍</div></Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col lg={4}><div className="texte">Nul</div></Col>
                                <Col lg={5}><div className=""></div></Col>
                                <Col lg={5}><div className=""></div></Col>
                                <Col lg={4}><div className=""></div></Col>
                                <Col lg={5}><div className="text">Excellent</div></Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col lg={3}>
                </Col>
            </Row>
            <br/>
            {selected !== null && selected !== "" && selected !== "excelent" && <Row>
                <Col lg={2}>
                    <img src="./assets/img/green-check.png" alt="icon"/>
                </Col>
                <Col lg={19}>
                    <div className="question">
                        Quels sont les points du processus qui ont failli ?
                    </div>
                    <div className="decision">
                        <Select
                            className="controle-prestation-select scroll-none"
                            placeholder="Sélectionner une ou plusieurs réponses"
                            suffixIcon={<div className='icon-logement-select' style={{marginTop: '-8px'}}><img
                                src="../assets/img/arrow-down-gray.png" alt="add"/></div>}
                            onChange={onChangeSelect}
                            mode="multiple"
                            options={[
                                {
                                    value: 'Services Kliner mal compris par le client',
                                    label: <div>Services Kliner mal compris par le client</div>,
                                },
                                {
                                    value: 'Incompréhension sur la mise en place par le client',
                                    label: <div>Incompréhension sur la mise en place par le client</div>,
                                },
                                {
                                    value: 'Partenaire pas suffisamment formé sur l’application',
                                    label: <div>Partenaire pas suffisamment formé sur l’application</div>,
                                },
                                {
                                    value: 'Partenaire pas suffisamment formé aux standards Kliner',
                                    label: <div>Partenaire pas suffisamment formé aux standards Kliner</div>,
                                },
                                {
                                    value: 'Manque de stocks de produits',
                                    label: <div>Manque de stocks de produits</div>,
                                },
                                {
                                    value: 'Le partenaire n’a pas reçu le linge (ou amenities) adéquat',
                                    label: <div>Le partenaire n’a pas reçu le linge (ou amenities) adéquat</div>,
                                },
                                {
                                    value: 'Le partenaire n’était pas équipé (matériel et/ou personnel)',
                                    label: <div>Le partenaire n’était pas équipé (matériel et/ou personnel)</div>,
                                },
                                {
                                    value: 'La prestation n’a pas été contrôlée à temps)',
                                    label: <div>La prestation n’a pas été contrôlée à temps</div>,
                                },
                                {
                                    value: 'Le contrôle photos n’est pas précis',
                                    label: <div>Le contrôle photos n’est pas précis</div>,
                                },
                                {
                                    value: 'Bug rencontré dans les applications',
                                    label: <div>Bug rencontré dans les applications</div>,
                                },
                            ]}
                        />
                    </div>
                </Col>
                <Col lg={3}>
                </Col>
            </Row>}
        </div>
    );
}

export default AvisFinalPart;
