import React, {useEffect, useState} from "react";
import {Modal, Row, Col, Popover, Button, Select, Carousel, message, Form, Spin} from "antd";
import {IoChevronBack, IoChevronForwardOutline} from "react-icons/io5";
import {useMediaprestationFetchOneQuery, useMediaprestationUploadMutation} from "../services/mediaprestation-api";
import moment from "moment/moment";
import {API_ROOT} from "../../../utils/global-var";
import {setRefresh} from "../../logements/services/logement-slice";
function AddPhotoPrestation({ status, logement, position, handleCancel }) {
    const [images, setImages] = useState([]);
    const [upload, setUpload] = useState(false);
    const [addNewPhoto] = useMediaprestationUploadMutation();
    useEffect(() => {
        setImages([]);
        setUpload(false);
    }, [logement, position, status]);
    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const imageUrls = files.map((file) => URL.createObjectURL(file));
        setImages((prevImages) => [...prevImages, ...imageUrls]);
        files.forEach(file => onUpload(file));
    };

    const onUpload = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        try {
            setUpload(true);
            const response = await fetch(`${API_ROOT}/api/v1/upload`, {
                method: "POST",
                body: formData,
            });
            if (response.ok) {
                const image =  await response.text()
                const data = ({
                    libelle: "Photo prestation "+logement.logement_libelle,
                    image: image,
                    prestation: logement.prestationId,
                    status: position,
                    logement: logement.logement_id,
                });
                    //console.log(data);
                   addNewPhoto(data).unwrap()
                        .then((res) => {
                            setUpload(false);
                            message.success(`Photo ajoutée avec succès !`);
                        })
                        .catch((error) => {
                            setUpload(false);
                            message.error(`Erreur lors de l'enregistrement des informations sur la prestation: ${error.toString()}`);
                        });
            } else {
                message.error('Erreur lors du téléchargement du fichier');
            }
        } catch (error) {
            message.error(`Erreur lors de l'ajout de la photo sur la prestation : ${error.toString()}`);
        }
    };

    const handleDeleteImage = (indexToDelete) => {
        setImages(images.filter((_, index) => index !== indexToDelete));
    };
    const onCancel = () => {
        handleCancel();
    };

    return (
        <>
            <Modal
                destroyOnClose
                open={status} footer={null} closable={true} width={1040} onCancel={onCancel} className="AddPhotoPrestation" style={{top:250}}>
                <div className="rapport">
                    <input
                        id="image-upload"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                    />
                    <Spin spinning={upload} style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                        <label htmlFor="image-upload" style={{cursor: "pointer", display: "inline-block",  margin: '10px 20px', width:"120px", height: '120px', textAlign:"center"}}>
                            <img
                                src="../assets/img/cross-add.png"
                                alt="Add"
                                style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                            /> <br/>
                            <div style={{color:"#ff7f0a"}}> Ajouter un photo</div>
                        </label>
                        {logement.catalogs.map((catalogImage, index) => catalogImage.status === position && <div key={index}
                        style={{position: 'relative', margin: '10px 20px', display: "inline-block"}}>
                        <img src={`${API_ROOT}/${catalogImage.image}`} alt={`Preview ${index}`}
                             style={{width: '120px', height: '120px', borderRadius: "10px"}}/>
                        <button
                            onClick={() => handleDeleteImage(index)}
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                backgroundColor: 'lightgray'/*'red'*/,
                                color: 'white',
                                padding: '5px 10px',
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                        >
                            X
                        </button>
                </div>)}
                        {images.map((image, index) => (
                            <div key={index} style={{ position: 'relative', margin: '10px 20px', display:"inline-block" }}>
                                <img src={image} alt={`Preview ${index}`} style={{ width: '120px', height: '120px', borderRadius: "10px" }} />
                                <button
                                    onClick={() => handleDeleteImage(index)}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        backgroundColor: 'red',
                                        color: 'white',
                                        padding: '5px 10px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        borderRadius: '5px'
                                    }}
                                >
                                    X
                                </button>
                            </div>
                        ))}
                    </Spin>
                </div>
                <Button type={"primary"} onClick={handleCancel} style={{float:"right", marginTop: "375px"}}>
                   Valider les imports
                </Button>
            </Modal>
        </>
    );
}

export default AddPhotoPrestation;