import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const paiementclientApi = createApi({
    reducerPath: "paiementclientApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Paiementclient"],
    endpoints: (builder) => ({
        paiementclientFetch: builder.query({
            query: (payload) => `/api/v1/stripe/users/${payload.id}/default-payment-method-details/`,
            providesTags: ["Paiementclient"],
        }),
    }),
});

export const {
    usePaiementclientFetchQuery,
} = paiementclientApi;
