import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const clientApi = createApi({
    reducerPath: "clientApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Client"],
    endpoints: (builder) => ({
        clientFetch: builder.query({
            query: (payload) => `/api/v1/user/customer/dashboard?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search}&order=DESC`,
            providesTags: ["Client"],
        }),
        clientUpdateBilling:  builder.mutation({
            query: (payload) => ({
                url: `api/v1/billing/customer/billing-details?userId=${payload.id}`,
                method: "PATCH",
                body: payload.data,
            }),
            invalidatesTags: ["ClientBilling"],
        }),
        /*ratingPrestation: builder.mutation({
            query: (payload) => ({
                url: "/api/v1/prestation-quality-control",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Client"],
        }),*/
    }),
});

export const {
    useClientFetchQuery,
    useClientUpdateBillingMutation,
    useClientMutation,
} = clientApi;
