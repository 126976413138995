import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const infoUserApi = createApi({
    reducerPath: "infoUserApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["InfoUser"],
    endpoints: (builder) => ({
        infoUserFetchOne: builder.query({
            query: (id) => `/api/v1/user/${id}`,
            providesTags: ["InfoUser"],
        }),
        infoUserUpdate: builder.mutation({
            query: (payload) => ({
                url: `/api/v1/user/${payload.id}`,
                method: "PATCH",
                body: payload.data,
            }),
            invalidatesTags: ["InfoUser"],
        }),
    }),
});

export const {
    useInfoUserFetchOneQuery,
    useInfoUserUpdateMutation,
} = infoUserApi;
