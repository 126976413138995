import React from 'react';
import "../../styles/style.scss"
import {Col, Row} from "antd";

const HeureDemarrageApercu = ({choix}) => {
    return (
        <div className='AideMenageApercu'>
            <Row>
                <Col lg={2}>

                </Col>
                <Col lg={22}>
                    <div className="apercu-content">
                        <Row>
                            <Col lg={2}>
                                <div className="img">
                                    <img src="./assets/img/light-colored.png" alt="icone"/>
                                </div>
                            </Col>
                            <Col lg={22}>
                                {choix === "oui" && <div className="text">
                                    Le système a détecté que la prestation a bien démarré à l’intérieur du créneau d’intervention défini.
                                </div>}
                                {choix === "non" && <div className="text">
                                    Il semble que l’aide-ménagère a démarré sa prestation avec un certain retard. Le client doit être prévenu au cas où une arrivée de voyageurs est imminente.
                                </div>}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default HeureDemarrageApercu;
