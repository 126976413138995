import React from 'react';
import {Col, Row} from "antd";

function InviteEnvoyePage() {
    return (
        <div className="content-in">
            <div className="invitationSend">
                <Row>
                    <Col lg={2} />
                    <Col lg={12}>
                        <div className="image">
                            <img src="./assets/img/Sent invit.png" alt="thx"/>
                        </div>
                        <div className="titre sans-pro-semi-bold">
                            Invitation envoyée !
                        </div>
                        <div className="message sans-pro-regular">
                            Merci Brice, nous venons d’inviter Paul à vous retrouver sur Kliner! Nous vous préviendrons par mail dès que son compte sera prêt sur votre espace.
                        </div>
                        <div className="greet sans-pro-regular">
                            N’hésitez pas à contacter votre partenaire <br/>
                            pour l’inciter à accélérer la démarche.
                        </div>
                    </Col>
                    <Col lg={10} />
                </Row>
            </div>
        </div>
    );
}

export default InviteEnvoyePage;