import React, {useState} from 'react';
import "../styles/style.scss"
import {EditOutlined} from "@ant-design/icons";
import {Col, Row} from "antd";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import ProfilModalView from "./modal";

function ProfilPartPage() {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="profil-part-page">
            <h2 className="sans-pro-semi-bold">Mon profil</h2>
            <div className="headprofil">
                <div className="imageSide">
                    <img src="/assets/img/user-empty.png" alt="airbnb"/>
                </div>
                <div className="infSide">
                    <div className="name sans-pro-regular color-595959">
                        Brice William
                    </div>
                    <div className="function sans-pro-regular">
                        Gérant de conciergerie
                    </div>
                    <div className="location">
                        Paris, France
                    </div>
                </div>
            </div>

            <div className="contentProfil">
                <div className="head sans-pro-semi-bold">
                    Informations personnelles
                    <div className="icone" onClick={() => setShowModal(true)}>
                        <EditOutlined style={{color:'#566F8F', fontSize:'22px', opacity:'0.8'}}/>
                    </div>
                </div>
                <div className="content">
                    <Row>
                        <Col lg={12}>
                            <div className="label">
                                Prénom
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                Brice
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="label">
                                Nom
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                William
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="label">
                                Adresse email
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                brice.william@gmail.com
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="label">
                                Téléphone (mobile)
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                <div className="number-chef sans-pro-regular color-595959">
                                    <PhoneInput
                                        flags={flags}
                                        className="sans-pro-regular color-595959"
                                        value={"+33 6 47 46 71 09"}
                                        defaultCountry="FR"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="label">
                                Rôle
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                Administrateur
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <ProfilModalView
                status={showModal}
                handleCancel={() => setShowModal(false)}
            />
        </div>
    );
}

export default ProfilPartPage;
