import React, {useState} from 'react';
import "../../styles/style.scss"
import {IoCloseSharp} from "react-icons/io5";
import ChoisirModalView from "./modal/logement-choix";
import ChoisirForfaitView from "./modal/forfait";

function TarifsLibresTabPage() {
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    return (
        <div className='tarifsLibres'>
            <div className="disclaimer sans-pro-regular">
                Tous les logements où le tarif que vous avez défini s’applique <br/>
                lorsque ce prestataire y est assigné.
            </div>
            <div className="listElement">
                <div className="element">
                    <div className="zone">
                        ALbertville (Lyon)
                    </div>
                    <div className="villeBoss">
                        <div className="ville">
                            <div className="text">
                                65.00€
                            </div>
                            <div className="iconMore" onClick={() => setShowModal2(true)}>
                                <img src="./assets/img/pen-colored.png" alt="more"/>
                            </div>
                        </div>
                        <div className=" span disable">vous nous payez</div>
                    </div>
                    <div className="forfaitBoss">
                        <div className="forfait">
                            61.75€
                        </div>
                        <div className=" span disable">votre partenaire reçoit</div>
                    </div>
                    <di className="icone">
                        <IoCloseSharp />
                    </di>
                </div>
            </div>

            <div className="add" onClick={() => setShowModal(true)}>
                    + Ajouter un tarif libre
            </div>

            <ChoisirModalView
                status={showModal}
                handleCancel={() => {setShowModal(false); setShowModal2(true)}}
            />
            <ChoisirForfaitView
                status={showModal2}
                handleCancel={() => setShowModal2(false)}
            />
        </div>
    );
}

export default TarifsLibresTabPage;