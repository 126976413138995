import React, {useState} from 'react';
import "../styles/style.scss"
import {Col, Flex, Input, Layout, Row, Space} from "antd";
import {CloseOutlined, SearchOutlined} from "@ant-design/icons";
import {FaRegCircleQuestion} from "react-icons/fa6";
import {NavLink} from "react-router-dom";
import {IoArrowBackOutline, IoCloseSharp, IoSettingsOutline} from "react-icons/io5";
import QuelProfilPage from "./pages/quel-profil-page";
import ContacterPartenairePage from "./pages/contacter-partenaire-page";
import RemunerationPartenairePage from "./pages/remuneration-page";
import InviteEnvoyePage from "./pages/invite-envoye-page";

const {Header} = Layout;

function AddTiercePage() {
    const [itemSelected, setItemSelected] = useState("QuelProfilPage");

    const handleBack = () =>{
            if(itemSelected === "ContacterPartenairePage"){
                setItemSelected("QuelProfilPage");
            }
            if(itemSelected === "RemunerationPartenairePage"){
                setItemSelected("ContacterPartenairePage");
            }
    };
    const handleNext = () =>{
        if(itemSelected === "QuelProfilPage"){
            setItemSelected("ContacterPartenairePage");
        }
        if(itemSelected === "ContacterPartenairePage"){
            setItemSelected("RemunerationPartenairePage");
        }
        if(itemSelected === "RemunerationPartenairePage"){
            setItemSelected("InviteEnvoyePage");
        }
    };

    return (
        <div className='addtierce-page'>
            <Header>
                <Flex justify={"space-between"}>
                    <Col lg={23}>
                        <Row>
                            <Col lg={11}>
                                <div className="titre sans-pro-semi-bold">
                                    Intégration d'une équipe tierce à Kliner
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Space className="icons-head">
                            <NavLink to={"/equipes-entretien"}>
                                <CloseOutlined style={{fontSize: "30px", marginRight: "10px", color:"grey"}}/>
                            </NavLink>
                        </Space>
                    </Col>
                </Flex>
            </Header>
            <div className="context">
                <Row>
                    <Col lg={6}></Col>
                    <Col lg={14}>
                        {itemSelected === "QuelProfilPage" && <QuelProfilPage/>}
                        {itemSelected === "ContacterPartenairePage" && <ContacterPartenairePage />}
                        {itemSelected === "RemunerationPartenairePage" && <RemunerationPartenairePage />}
                        {itemSelected === "InviteEnvoyePage" && <InviteEnvoyePage />}
                    </Col>
                    <Col lg={5}></Col>
                </Row>
            </div>
            <div className="footer">
                {(itemSelected !== "QuelProfilPage" && itemSelected !== "InviteEnvoyePage") && <div className="button-retour sans-pro-semi-bold" onClick={handleBack}>
                   <IoArrowBackOutline /> Retour
               </div>}
                {(itemSelected === "QuelProfilPage" && itemSelected !== "InviteEnvoyePage") && <div className="button-next-center sans-pro-semi-bold" onClick={handleNext}>
                    <span>suivant</span>
               </div>}
                {itemSelected !== "InviteEnvoyePage" && itemSelected !== "QuelProfilPage" &&  <div className="button-next sans-pro-semi-bold" onClick={handleNext}><span>suivant</span></div>}
                {itemSelected === "InviteEnvoyePage" && itemSelected !== "QuelProfilPage" &&  <div className="button-next sans-pro-semi-bold" onClick={handleNext}><NavLink to={'/equipes-entretien'}>Fermer</NavLink></div>}
            </div>
        </div>
    );
}

export default AddTiercePage;