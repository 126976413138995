import React, {useEffect, useState} from 'react';
import "./styles/style.scss"
import {Col, Divider, Dropdown, Menu, Popover, Row, Select, Table} from "antd";
import {IoAdd, IoEllipsisHorizontal, IoEllipsisVertical, IoStar} from "react-icons/io5";
import {ArrowRightOutlined} from "@ant-design/icons";
import RapportPhoto from "./modal/apercu";
import {useMediaprestationFetchQuery} from "./services/mediaprestation-api";
import moment from "moment";
import {convertDateStringToTimeString, convertDateStringToTimeStringDateOnly} from "../../utils/global-var";
function LogistiquePage() {
    const [showModal, setShowModal] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const { data: mediaList, refetch } = useMediaprestationFetchQuery({start:
        moment()
        .subtract(21, 'days').format('YYYY-MM-DD'),
       end: moment()
        .format('YYYY-MM-DD')
    });
    useEffect(() => {
        refetch();
    }, [showModal]);
    console.log(mediaList);
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const equipesList = [
        {
            key: 1,
            name: `Agnes Debangy`,
            corp: `Nettoyage SARL`,
            photo: `user-empty.png`,
            date: `10 jannvier 2023`,
            telephone: "667994414",
            statut: "actif",
            special: false,
            logement: 2,
        },
        {
            key: 2,
            name: `Carmelia megrad`,
            corp: `HS Cleaner`,
            photo: `user-empty.png`,
            date: `10 jannvier 2023`,
            telephone: "+33650722510",
            statut: "actif",
            special: true,
            logement: 6,
        },
        {
            key: 3,
            name: `Jean-Francois Danjon`,
            corp: `Clean Up`,
            photo: `user-empty.png`,
            date: `10 jannvier 2023`,
            telephone: "667994414",
            statut: "wait",
            special: false,
            logement: 2,
        },
        {
            key: 4,
            name: `Julia Mbengue`,
            corp: `Etincelle`,
            photo: `user-empty.png`,
            date: `10 jannvier 2023`,
            telephone: "667994414",
            statut: "block",
            special: false,
            logement: 1,
        },
        {
            key: 5,
            name: `Kenny CHEUNG-A-LONG`,
            corp: `Net-propre Services`,
            photo: `user-empty.png`,
            date: `10 jannvier 2023`,
            telephone: "667994414",
            statut: "actif",
            special: false,
            logement: 9,
        },
        {
            key: 6,
            name: `Marine BABEY`,
            corp: `Kiphone Pro`,
            photo: `user-empty.png`,
            date: `10 jannvier 2023`,
            telephone: "667994414",
            statut: "pending",
            special: true,
            logement: 0,
        },
    ];
    const photoList = [
        {
            key: 11,
            date: `Lundi 7 mars 2024`,
            nbPrestation: 3,
            photo: 47,
            video: 0,
        },
        {
            key: 12,
            date: `Mardi 8 mars 2024`,
            nbPrestation: 1,
            photo: 0,
            video: 1,
        },
        {
            key: 13,
            date: `Jeudi 10 mars 2024`,
            nbPrestation: 8,
            photo: 120,
            video: 0,
        },
        {
            key: 14,
            date: `Dimanche 13 mars 2024`,
            nbPrestation: 2,
            photo: 33,
            video: 1,
        },
    ];
    const columns = [
        {
            title: "Logement",
            width: "22%",
            render: (record) => (
                <div style={{cursor: 'pointer', color: "#263754", fontSize: "15px"}}>
                    <div className="avatar">
                        <img src={"./assets/img/" + record.photo} alt="avatar"/>
                    </div>
                    <div className="info">
                        <div className="name sans-pro-semi-bold">{record.name}</div>
                        <div className="desc">{record.corp}</div>
                    </div>
                </div>
            ),
        },

        {
            title: 'Prestation concernée',
            width: "22%",
            render: (record) => (
                <div>
                    {(record.statut === "actif") && (<div className="badge-green">Actif</div>)}
                    {(record.statut === "pending") && (<div className="badge-blue">Invitation envoyée</div>)}
                    {(record.statut === "wait") && (<div className="wait">
                        <div className="badge-grey">En attente de prestations</div>
                    </div>)}
                    {(record.statut === "block") && (<div className="wait">
                        <div className="badge-red">Bloqué</div>
                    </div>)}
                </div>
            ),
        },

        {
            title: 'Note reçu',
            width: "15%",
            render: (record) => (
                <div>
                    {record.telephone}
                </div>
            ),
        },
        {
            title: 'Justificatifs',
            width: "10%",
            render: (record) => (
                <div>
                    <div className="nbLogement">
                        {record.logement}
                    </div>
                </div>
            ),
        },
        {
            title: "Montant de l'avoir",
            width: "17%",
            render: (record) => (
                <div>{record.date} €</div>
            ),
        },
        {
            title: "Statut",
            width: "17%",
            render: (record) => (
                <div>{record.date} €</div>
            ),
        },
    ];

    return (
        <div className='logistique-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Suivi Qualité & Logistique</h1>
                </div>
            </div>

            <div className="header">
                <div className='flex justify-content-space-between'>
                    <div className='blc-left'>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Responsable:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={null}
                                suffixIcon={<div className='icon-logement-select'><img
                                    src="../assets/img/arrow-down-gray.png" alt="add"/></div>}
                                options={[
                                    {
                                        value: 'Tout', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter"/>
                                            <div className="text sans-pro-regular color-213856"
                                                 style={{marginLeft: "10px"}}>
                                                Camelia Megrard
                                            </div>
                                        </div>
                                    },
                                    {
                                        value: 'Tout', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter"/>
                                            <div className="text sans-pro-regular color-213856"
                                                 style={{marginLeft: "10px"}}>
                                                Marine Babey
                                            </div>
                                        </div>
                                    },
                                    {
                                        value: 'Tout', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter"/>
                                            <div className="text sans-pro-regular color-213856"
                                                 style={{marginLeft: "10px"}}>
                                                Julia Mbengue
                                            </div>
                                        </div>
                                    },
                                ]}
                                popupClassName="responsable-classe-select"
                            />
                        </div>

                    </div>
                </div>
            </div>

            <div className="stats">
                <Row>
                    <Col lg={12}>
                        <div className="content statistiqueLeft">
                            <div className="head">
                                <div className="image">
                                    <img src="./assets/img/white-stars-icon.png" alt="logo"/>
                                </div>
                                <div className="text">
                                    <div className="title sans-pro-semi-bold">Performance</div>
                                    <div className="description color-5B6E8C sans-pro-regular">Surveiller l’efficacité
                                        de vos équipes de ménage
                                    </div>
                                </div>
                            </div>
                            <div className="statistique">
                                <Row>
                                    <Col lg={12}>
                                        <div className="blocStat blockBlue">
                                            <div className="indication">
                                                <Popover placement="top" content={"test"}
                                                         overlayClassName="infoContentLogement">
                                                    <div className="info-equipe">
                                                        <img src="/assets/img/info-circled.png" alt="info"/>
                                                    </div>
                                                </Popover>
                                            </div>
                                            <div className="number">96%</div>
                                            <div className="label sans-pro-light">Prestations réussies</div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="blocStat blockBlue2">
                                            <div className="indication">
                                                <Popover placement="top" content={"test"}
                                                         overlayClassName="infoContentLogement">
                                                    <div className="info-equipe">
                                                        <img src="/assets/img/info-circled.png" alt="info"/>
                                                    </div>
                                                </Popover>
                                            </div>
                                            <div className="number">96%</div>
                                            <div className="label sans-pro-light">Prestations réussies</div>
                                        </div>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col lg={12}>
                                        <div className="blocStat blockBlue">
                                            <div className="indication">
                                                <Popover placement="top" content={"test"}
                                                         overlayClassName="infoContentLogement">
                                                    <div className="info-equipe">
                                                        <img src="/assets/img/info-circled.png" alt="info"/>
                                                    </div>
                                                </Popover>
                                            </div>
                                            <div className="number">96%</div>
                                            <div className="label sans-pro-light">Prestations réussies</div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="blocStat blockOrange">
                                            <div className="indication">
                                            </div>
                                            <div className="number">64%</div>
                                            <div className="label sans-pro-light">Taux de saturation</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="content statistiqueRight">
                            <div className="head">
                                <div className="image">
                                    <img src="./assets/img/activity.png" alt="logo"/>
                                </div>
                                <div className="text">
                                    <div className="title sans-pro-semi-bold">Activité générale</div>
                                    <div className="description color-5B6E8C sans-pro-regular">Garder un œil sur la
                                        répartition du travail au sein de vos équipes
                                    </div>
                                </div>
                            </div>
                            <div className="statistique">
                                <Row>
                                    <Col lg={12}>
                                        <div className="blocStat blockGreyLeft">
                                            <div className="indication">

                                            </div>
                                            <div className="number">2%</div>
                                            <div className="label sans-pro-light">De la totalité de vos prestations
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="blocStat blockGreyRight">
                                            <div className="indication">

                                            </div>
                                            <div className="number">303</div>
                                            <div className="label sans-pro-light">Prestations effectuées</div>
                                        </div>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col lg={12}>
                                        <div className="blocStat blockGreyLeft">
                                            <div className="indication">

                                            </div>
                                            <div className="number">2%</div>
                                            <div className="label sans-pro-light">De la totalité de vos prestations
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="blocStat blockOrange">
                                            <div className="indication">

                                            </div>
                                            <div className="number">303</div>
                                            <div className="label sans-pro-light">Prestations effectuées</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            {/*<div className="content statistique-tabContent">
                <div className="head">
                    <div className="image">
                        <img src="./assets/img/activity.png" alt="logo"/>
                    </div>
                    <div className="text">
                        <div className="title sans-pro-regular">Avoirs sur les prestations notées moins de 5 étoiles
                        </div>
                    </div>
                </div>
                <Table
                    className="equipe-table"
                    pagination={{pageSize: 10}}
                    rowSelection={rowSelection}
                    dataSource={[]}
                    columns={columns}
                    rowKey={(record) => record.key}
                    locale={{
                        emptyText: (
                            <Row>
                                <Col lg={8}></Col>
                                <Col lg={8}>
                                    <div className="empty-Tab-planning">
                                        <img src="/assets/img/prestation.png" alt="airbnb" className="plateforme-img"/>
                                        <div className="title">Aucun avoir pour le moment !</div>
                                        <p>
                                            <span>
                                                Il semblerait que toutes les prestations se soient <br/> parfaitement déroulées sur cette période. <br/> Bravo à nous !

                                            </span>
                                        </p>
                                        <a href="#">Plus sur notre politique d’avoirs liés à la
                                            qualité <ArrowRightOutlined className="primary-color"/></a>
                                        <br/>
                                        <br/>
                                    </div>
                                </Col>
                                <Col lg={8}></Col>
                            </Row>
                        ),
                    }}
                />
                <div className="indication">
                    aucun avoir généré sur 14 prestations
                </div>
            </div> */}

            <div className="photo">
                <div className="head">Reportages photos ou vidéos</div>
                <Row>
                    <Col lg={16} style={{height: "520px", overflowY:"scroll"}}>
                        {mediaList?.data?.length !== 0 && mediaList?.data?.map((item, index) => (
                            <Row className="rapport_photo" key={"rapport_" + index} onClick={() => {setSelectedDate(item["prestation_date"]);setShowModal(true)}}>
                                <Col lg={3}>
                                    {item["total_videos"] !== "0" && (
                                        (item["total_images"] !== "0" &&
                                            <div className="type-mini-video">
                                                <img src="./assets/img/photo-controle.png" alt="icon"/>
                                            </div>) ||
                                        (item["total_images"] === "0" &&
                                            <div className="type">
                                                <img src="./assets/img/video-prestation.png" alt="icon"/>
                                            </div>)
                                    )}
                                    {item["total_images"] !== "0" && (
                                        (item["total_videos"] !== "0" &&
                                            <div className="type-mini-photo">
                                                <img src="./assets/img/video-prestation.png" alt="icon"/>
                                            </div>) ||
                                        (item["total_videos"] === "0" &&
                                            <div className="type">
                                                <img src="./assets/img/photo-controle.png" alt="icon"/>
                                            </div>)
                                    )}
                                    {item["total_images"] === "0" && item["total_videos"] === "0" && <div className="type">
                                        <img src="./assets/img/no-file-controle.png" alt="icon"/>
                                    </div>}
                                </Col>
                                <Col lg={7}>
                                   <div className="date sans-pro-semi-bold">{convertDateStringToTimeStringDateOnly(item["prestation_date"], true)}</div>
                                </Col>
                                <Col lg={6}>
                                    <div className="prestation">{item["total_finished_prestation"]} prestations</div>
                                </Col>
                                <Col lg={6}>
                                    <div className="prestation">{item["total_images"] !== "0" && item["total_images"]+" photo"+(parseInt(item["total_images"]) > 1 ? "s": "")+(item["total_videos"] !== "0" ? " & " : " ")} {item["total_videos"] !== "0" && item["total_videos"]+" video"+(parseInt(item["total_videos"]) > 1 ? "s" : "")}</div>
                                </Col>
                                <Col lg={2}>
                                    <div className="menu">
                                        <Dropdown
                                            overlay={<Menu items={null} />}
                                            placement="bottomLeft" trigger={['click']}>
                                            <IoEllipsisHorizontal />
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </Col>
                    <Col lg={8}>
                        <div className="linge-a-recuperer">
                            <div className="titre">Linge à récuperer</div>
                            <div className="lingeList">
                                <div className="element">
                                    <div className="jourJ sans-pro-semi-bold">J+1</div>
                                    <div className="info">
                                        <div className="head">Monparnasse (Nantes)</div>
                                        <div className="min-info">Joie Paris</div>
                                        <div className="min-info-colored">2 kits de linge</div>
                                        <div className="min-info-link">
                                            <div className="min-info-link-image">
                                                <img src="./assets/img/logon.png" alt="icon"/>
                                            </div>
                                            <div className="min-info-link-text">
                                                Relancer ou facturer le client
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div className="element">
                                    <div className="jourJ sans-pro-semi-bold">J+2</div>
                                    <div className="info">
                                        <div className="head">Bellevue (Paris)</div>
                                        <div className="min-info">Joie Paris</div>
                                        <div className="min-info-colored">2 kits de linge</div>
                                        <div className="min-info-link">
                                            <div className="min-info-link-image">
                                                <img src="./assets/img/logon.png" alt="icon"/>
                                            </div>
                                            <div className="min-info-link-text">
                                                Relancer ou facturer le client
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <br/>
            <br/>
            <br/>
            {selectedDate !== null && <RapportPhoto
                status={showModal}
                date={selectedDate}
                resetDate={setSelectedDate}
                prestations={mediaList?.data}
                handleCancel={() => setShowModal(false)}
            />}
        </div>
    );
}

export default LogistiquePage;
