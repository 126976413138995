import React, {useState} from 'react';
import "../../styles/style.scss"
import {Col, Row} from "antd";
import AideMenageApercu from "../apercu/aide-menagere-apercu";
import LingeAmenitieApercu from "../apercu/linge-amenitie-apercu";
import HeureDemarrageApercu from "../apercu/heure-demarrage-apercu";
//prestation_started_on_time
const HeureDemarragePart = ({next, setHeureDemarragePart}) => {
    const [choix, setChoix] = useState('');
    return (
        <div className='AideMenagePart'>
            <Row>
                <Col lg={2}>
                    <img src="./assets/img/green-check.png" alt="icon"/>
                </Col>
                <Col lg={19}>
                    <div className="question">
                        La prestation a-t-elle bien démarrée à l’heure prévue ?
                    </div>
                    <div className="decision">
                        <div className={"oui "+(choix === "oui" ? " active " : "")+(choix === "" ? " colorBlack" : "")} onClick={()=> {
                            setChoix("oui");
                            setHeureDemarragePart({prestation_started_on_time:true});
                        }}>OUI</div>
                        <div className={"non "+(choix === "non" ? " active " : "")+(choix === "" ? " colorBlack" : "")} onClick={()=> {
                            setChoix("non");
                            setHeureDemarragePart({prestation_started_on_time:false});
                        }}>NON</div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="link">
                        <a href="#" className={choix === "" ? " disable" : ""} onClick={choix !== "" ? next : null}>Suivant</a>
                    </div>
                </Col>
            </Row>
            {choix !== "" && <div className="apercu">
                <HeureDemarrageApercu choix={choix}/>
            </div>}
        </div>
    );
}

export default HeureDemarragePart;
