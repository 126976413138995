import React, { useEffect, useState } from 'react';
import {Dropdown, Row, Col, Table, Input, Select, Badge, Button, Avatar, Modal, Divider} from 'antd';
import {
    SearchOutlined
} from '@ant-design/icons';
import { IoAdd } from "react-icons/io5";
import moment from "moment";
import {usePrestationFetchMutation} from "../../../planning/services/prestations-api";
import {
    API_ROOT,
    convertDateStringToTimeString,
    convertDateStringToTimeStringComplete,
    monthList
} from "../../../../utils/global-var";
import PlanningDetail from "../../../planning/planning_detail";

const InstructionLogementPage = ({status, handleCancel, record}) => {
    return (
        <>
            <Modal
                open={status}
                footer={null}
                closable={true}
                style={{top:100}}
                width={600}
                onCancel={handleCancel}
                className="instruction-client-modal"
            >
                <div className='planning-page'>
                    <div className='page-header'>
                        <div className='page-title'>
                            <h1>Instructions d'accès</h1>
                        </div>
                    </div>
                    <div className="instructionList">
                        {record?.instructions.length !== 0 && record?.instructions.map((item, index) =>
                            <div className="element" key={"instruction_element_"+index}>
                                <Row>
                                    <Col lg={8}>
                                        {item.image !== null ? <img src={`${API_ROOT}/${item.image}`} alt='instruction'
                                                                    className="instructionApercu"/> : <div style={{paddingTop:"10px" }}><em>(aucune image)</em></div>}
                                    </Col>
                                    <Col lg={16} style={{paddingTop:"10px" }}>{item.content === " " ? <em>(aucune instructions écrites)</em> : item.content}</Col>
                                </Row>
                                <Divider />
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default InstructionLogementPage;
