import React, {useState, useEffect} from "react";
import {
    Modal,
    Divider,
    Row,
    Col,
} from "antd";

const ValidProgrammationModalView = ({status, handleCancel}) => {

    const handleValid = () => {
        handleCancel();
    };


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{top: 400}}
            onCancel={() => {
                handleCancel();
            }}
            width={550}
            className="validProgramme"
        >

            <div className="programme">
                <div className="title sans-pro-semi-bold"><img src="/assets/img/logon.png" alt="airbnb"
                                                               className="title-img"/>
                    <div className="title-label">La prestation a été créée

                    </div>
                </div>
                <Row>
                    <Col>
                        <span>
                            La suppression d’un logement est une opération irréversible.
                        </span>
                    </Col>
                </Row>
                <div className="footer-modal">
                    <Divider/>
                    <Row>
                        <Col lg={11} className="button-left">
                            <a href="#" onClick={() => {
                                handleCancel();
                            }} className="modifier"><span> Voir la prestation</span></a>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={11} className="button-right">
                            <a href="#" onClick={handleValid}
                               className="suivant sans-pro-regular">Programmer une autre</a>
                        </Col>
                    </Row>
                </div>
            </div>

        </Modal>
    );
};
export default ValidProgrammationModalView;
