import React, { useState } from "react";
import {Modal, Tabs} from "antd";
import ToutTabPage from "../../../equipes/drawer/tabs/tout";
import TarifsLibresTabPage from "../../../equipes/drawer/tabs/tarifs-libres";
import FacturesTab from "./Tabs/factures";
import AttestationTab from "./Tabs/attestations";
import {useFactureclientFetchQuery} from "../../services/facture-api";


function DocumentClientModal({ status, handleCancel, record }) {
    const factures = useFactureclientFetchQuery({id: record.user_id});
    const onCancel = () => {
        handleCancel();

    };

    const onChange = (key) => {
        console.log(key);
    };

    const items = [
        {
            key: '1',
            label: <div className="sans-pro-light">Factures</div>,
            children: <FacturesTab factures={factures} />,
        },
        {
            key: '2',
            label: <div className="sans-pro-regular">Attestation</div>,
            children: <AttestationTab />,
        },
    ];

    return (
        <>
            <Modal
                open={status}
                footer={null}
                closable={true}
                style={{top:200}}
                width={900}
                onCancel={onCancel}
                className="document-client-modal"
            >
                <div className="document-client">
                    <div className="titre">
                        <div className="icone-doc">
                            <img src="./assets/img/doc icon2.png" alt="icon"/>
                        </div>
                        <div className="text sans-pro-semi-bold">
                            Documents de {record.user_lastname} {record.user_firstname}
                        </div>
                    </div>
                    <div className="tabList">
                        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                    </div>
                </div>
            </Modal>

        </>
    );
}

export default DocumentClientModal;
