import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../../utils/global-var";

export const tarifApi = createApi({
  reducerPath: "tarifApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["tarif"],
  endpoints: (builder) => ({
    updateTarif: builder.mutation({
      query: (type) => ({
        url: "/api/v1/tarifs/update",
        method: "POST",
        body: { paymentMethodType: type },
      }),
      invalidatesTags: ["tarif"],
    }),

    updatePricings: builder.mutation({
      query: (scriptId) => ({
        url: "/api/v1/prestation/pricings-script",
        method: "POST",
        body: { scriptId: scriptId },
      }),
      invalidatesTags: ["tarif"],
    }),    

    getTarif: builder.query({
      query: () => `/api/v1/tarifs`,
      providesTags: ["tarif"],
    }),
    
  }),
});

export const {
  useGetTarifQuery,
  useUpdatePricingsMutation
} = tarifApi;
