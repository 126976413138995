import React, {useEffect, useState, useReducer} from "react";
import {Modal, Divider, Row, Col, Spin, Form, Input, Tabs, message, Checkbox} from "antd";
import AutonomeTab from "./tabs/autonome";
import {useSuscriberPlanUserFetchOneQuery} from "../services/suscribe-plan-api";
import {useInfoUserFetchOneQuery, useInfoUserUpdateMutation} from "../services/indo-user-api";

const ModifierInformationModalView = ({status, handleCancel, record, suscriberPlan, userIfon, suscriberPlanUser}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [suscriber, setSuscriber] = useState(null);
    const [updateData, setUpdateDate] = useState({});
    const [newPlan, setNewPlan] = useState(null);
    const [nextPlan, setNextPlan] = useState(null);
    const [variableFees, setVariableFees] = useState({});
    const [acceleratedPayment, setAcceleratedPayment] = useState({});
    const [iban, setIban] = useState("");
    const [addresse, setAddresse] = useState({});
    const [form] = Form.useForm();
    const [updateUser] = useInfoUserUpdateMutation();
    //console.log(userIfon);
    //console.log(suscriber);
    //console.log(suscriberPlanUser);
    useEffect(() => {
        if (status && suscriberPlan !== null && suscriberPlan?.length !== 0) {
            if (suscriberPlanUser) {
                setSuscriber(
                    suscriberPlanUser
                );
             }
             if(userIfon?.data?.nextPlan) {
                setNextPlan(
                    suscriberPlan?.find(item => item.id === userIfon?.data?.nextPlan)
                );
             }
        }
    }, [status, suscriberPlan, suscriberPlanUser, userIfon]);
    useEffect(() => {
        if (status && userIfon?.data !== null && userIfon?.data?.length !== 0) {
            setIban(userIfon?.data?.ibanAccount === null ? "" : userIfon?.data?.ibanAccount);
            setAddresse(userIfon?.data?.residence_entreprise != null ? JSON.parse(userIfon?.data?.residence_entreprise) : null);
            setVariableFees(userIfon?.data?.variableFees ?? false);
            setAcceleratedPayment(userIfon?.data?.acceleratedPayment ?? false);
        }
    }, [userIfon, status]);
    //console.log(addresse);
    const items = [
        {
            key: 'Autonome',
            label: <div className="sans-pro-light">Autonome</div>,
            children: <AutonomeTab variableFees={variableFees} acceleratedPayment={acceleratedPayment}
            setVariableFees={(value)=>{updateUserInfo("variableFees", value); setVariableFees(value)}} setAcceleratedPayment={(value)=>{updateUserInfo("acceleratedPayment", value); setAcceleratedPayment(value)}}
                                   nextAcceleratedPayment={userIfon?.data?.nextAcceleratedPaymentStatus}
                                   nextVariableFees={userIfon?.data?.nextVariableFeesStatus}
                                   />,
        },
        {
            key: 'Confort',
            label: <div className="sans-pro-regular">Confort</div>,
            children: <AutonomeTab variableFees={variableFees} acceleratedPayment={acceleratedPayment}
                                   setVariableFees={(value)=>{updateUserInfo("variableFees", value); setVariableFees(value)}} setAcceleratedPayment={(value)=>{updateUserInfo("acceleratedPayment", value); setAcceleratedPayment(value)}}
                                   nextAcceleratedPayment={userIfon?.data?.nextAcceleratedPaymentStatus}
                                   nextVariableFees={userIfon?.data?.nextVariableFeesStatus}                                   
                                   />,
        },
    ];
    const onChange = (key) => {
        console.log(key);
        let plan = suscriberPlan?.find(item => item.name === key);
        setNewPlan(
            plan
        );

        if(plan)
         updateUserInfo("plan", plan?.id)
    };

    const updateUserInfo = (field, value) => {
        updateData[field] = value;
    }

    const onFinish = () => {
        setIsLoading(true);
        // const data = {
        //     ibanAccount: iban,
        //     variableFees: variableFees,
        //     acceleratedPayment: acceleratedPayment,
        // };

        // if(newPlan) {
        //     data["plan"] = newPlan.id;
        // }
        console.log("chek value");
        console.log(updateData);
        updateUser({id: record?.id, data: updateData})
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                message.success(`Informations de ${record?.lastname} ${record?.firstname} mis à jour!`);
                onCloseModal();
            })
            .catch((error) => {
                setIsLoading(false);
                message.error(error?.data?.message);
            });
    }
    const onFinishFormation = (state) => {
        setIsLoading(true);
        updateUser({id: record?.id, data: {is_formation_taken: state}})
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                message.success(`Informations de ${record?.lastname} ${record?.firstname} mis à jour!`);
                onCloseModal();
            })
            .catch((error) => {
                setIsLoading(false);
                message.error(error?.data?.message);
            });
    }

    const onCloseModal = () => {
        setSuscriber(suscriberPlan[0]);
        setVariableFees(false);
        setAcceleratedPayment(false);
        setIban("");
        setNextPlan(null);
        handleCancel();
    }
    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{top: 300}}
            onCancel={() => handleCancel()}
            width={650}
            className="modifierCompte">

            <Spin spinning={isLoading}>
                <div className="compte">
                    <div className="title sans-pro-semi-bold">
                        <div className="title-label">
                            Modifier les informations du compte
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <Form
                        name="basic"
                        form={form}
                        onFinish={null}
                        autoComplete="off"
                        layout="vertical"
                        className="form-edit-profil"
                    > <Row>
                        <Col lg={11}>
                            <Form.Item
                                name="prenom"
                                className="input-barrier"
                                rules={[{required: true, message: "Champs requis!"}]}>
                                <label htmlFor="prenom" className={"force-label-effet color-5B6E8C"}>Structure *</label>
                                <Input disabled={true} id="prenom" placeholder=""
                                       value={(userIfon?.data?.entreprise ?? "") + " " + (userIfon?.data?.profil !== null ? "(" + userIfon?.data?.profil + ")" : "").toUpperCase()}/>
                            </Form.Item>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={11}>
                            <Form.Item
                                name="nom"
                                className="input-barrier"
                                rules={[{required: true, message: "Champs requis!"}]}>
                                <label htmlFor="nom" className={"force-label-effet color-5B6E8C"}>ID (email) *</label>
                                <Input disabled={true} id="nom" placeholder="" value={userIfon?.data?.email}/>
                            </Form.Item>
                        </Col>
                    </Row>
                        <Row>
                            <Col lg={11}>
                                <Form.Item
                                    name="prenom"
                                    className="input-barrier"
                                    rules={[{required: true, message: "Champs requis!"}]}>
                                    <label htmlFor="prenom" className={"force-label-effet color-5B6E8C"}>Siège social
                                        *</label>
                                    <Input disabled={true} id="prenom" placeholder=""
                                           value={addresse !== null && addresse !== "" ? (addresse.street_number ?? "") + " " + (addresse.street !== null ? addresse.street + ", " : "") + (addresse.city !== null ? addresse.city + ", " : "") + (addresse.state !== null ? addresse.state + ", " : "") + (addresse.country ?? "") : ""}/>
                                </Form.Item>
                            </Col>
                            <Col lg={2}></Col>
                            <Col lg={11}>
                                <Form.Item
                                    name="nom"
                                    className="input-barrier"
                                    rules={[{required: true, message: "Champs requis!"}]}>
                                    <label htmlFor="nom" className={"force-label-effet color-5B6E8C"}>Téléphone
                                        *</label>
                                    <Input disabled={true} id="nom" placeholder="" value={userIfon?.data?.phone}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={11}>
                                <Form.Item
                                    name="iban"
                                    className="input-barrier"
                                    rules={[{required: true, message: "Champs requis!"}]}>
                                    <label htmlFor="prenom" className={"force-label-effet color-5B6E8C"}>IBAN*</label>
                                    <Input id="prenom" placeholder="" value={iban}
                                           onChange={(e) => {updateUserInfo("ibanAccount", e.target.value); setIban(e.target.value)}  }/>
                                </Form.Item>
                            </Col>
                            <Col lg={2}></Col>
                        </Row>
                    </Form>

                    <div className="mintitle">
                        FORMULE D'ABONNEMENT
                    </div>
                    {
                         (
                           <div className="option">
                            {
                                nextPlan && nextPlan.id !== suscriber?.id && (
                                    <em style={{color: "red"}}>La mise à jour à la formule {nextPlan.name} prendra effet le 1er du mois prochain</em>
                                )
                            }
                            <Tabs key={suscriber} defaultActiveKey={suscriber?.name} items={items} onChange={onChange}/>
                           </div>
                        )
                    }

                    <div className="mintitle">
                        FORMATION
                    </div>
                    <div className="is_formation_taken">
                        <Checkbox checked={userIfon?.data?.is_formation_taken} onChange={(e)=> {
                            onFinishFormation(e.target.checked);
                        }}> <span style={{fontSize:"14px"}}>Le partenaire a été formé aux standards Kliner</span> </Checkbox>
                    </div>

                    <div className="footer-modal">
                        <Divider/>
                        <Row>
                            <Col lg={12} className="button-left">
                                <a href="#" onClick={() => {
                                    onCloseModal()
                                }} className="annuler"><span> Annuler la demande</span></a>
                            </Col>
                            <Col lg={11} className="button-right">
                                <a href="#" onClick={() => onFinish()}
                                   className="suivant sans-pro-regular">Valider</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default ModifierInformationModalView;
