import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const controleFinPrestaApi = createApi({
    reducerPath: "controleFinPrestaApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["PrestationFinPresta"],
    endpoints: (builder) => ({
        controleFetch: builder.query({
            query: (payload) => ({
                url: `/api/v1/prestation-quality-control/prestation/${payload.id}`,
                method: "GET",
            }),
            invalidatesTags: ["PrestationFinPresta"],
        }),
        controleListFetch: builder.query({
            query: () => ({
                url: `/api/v1/prestation-dysfunction/list-dysfunction`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useControleFetchQuery,
    useControleListFetchQuery,
} = controleFinPrestaApi;
