import React from 'react';
import "../../styles/style.scss"
import {Col, Row} from "antd";

const LingeAmenitieApercu = ({choix}) => {
    return (
        <div className='AideMenageApercu'>
            <Row>
                <Col lg={2}>

                </Col>
                <Col lg={22}>
                    <div className="apercu-content">
                        <Row>
                            <Col lg={2}>
                                <div className="img">
                                    <img src="./assets/img/light-colored.png" alt="icone"/>
                                </div>
                            </Col>
                            <Col lg={22}>
                                {choix === "oui" && <div className="text">
                                    Le nécessaire a été mis à disposition du responsable de site suffisamment à temps, conformément à son abonnement Confort ou Autonome.
                                    <br/>
                                    <br/>
                                    Et en cas de difficultés, une solution d’appoint (achat local avec note de frais remboursée) a été mise en place pour que la prestation se déroule convenablement.
                                </div>}
                                {choix === "non" && <div className="text">
                                    Il faut trouver une solution qui ne dégrade pas l’expérience des voyageurs :
                                    <br/>
                                    <br/>
                                    <div>1. Reporter la prestation si possible et trouver une solution d’appoint</div>
                                    <div>2. Solliciter l’aide du propriétaire</div>
                                </div>}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default LingeAmenitieApercu;
