import React from 'react';
import "../../styles/style.scss"

function BasicSAPDepotPage() {
    return (
        <>
            <div className="title-label sans-pro-semi-bold">Comment ça marche ?</div>
            <div className="liste gray-zone">
                <div className="point-label">
                    <div className="point">
                        <img src="/assets/img/light-colored.png" alt="done"/>
                    </div>
                    <div className="label sans-pro-semi-bold">
                        Plus sur le service d’appoint
                    </div>
                </div>
                <div className="trait-explication">
                    <div className="trait trait-invisible"></div>
                    <div className="explication">
                        L’option ‘Dépôt de linge’ du service d’appoint vous permet de demander à une aide <br/>
                        ménagère de vous déposer des kits de linge en supplément de ceux déjà fournis. <br/>
                        Vous serez facturé (e) d’un forfait de déplacement plus les frais de location du linge.
                    </div>
                </div>
            </div>
        </>
    );
}

export default BasicSAPDepotPage;
