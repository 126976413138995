import React, { useState } from "react";
import { Modal } from "antd";


function Reviews({ status, handleCancel }) {
    const onCancel = () => {
        handleCancel();

    };

    return (
        <>
            <Modal
                open={status}
                footer={null}
                closable={true}
                style={{top:130, left:100}}
                width={800}
                onCancel={onCancel}
                className="review-modal"
            >
                <div className="review">
                    <div className="titre sans-pro-semi-bold">
                        34 Commentaires reçus
                    </div>

                    <div className="commentaires">
                        <div className="element">
                            <div className="note sans-pro-regular">5 sur 10 <div className="point-orange"></div></div>
                            <div className="message sans-pro-regular">
                                Manque de fonctionnalités mais service ok. <br/>
                                Besoin de progresser sur la qualité du linge et la réactivité du support.
                            </div>
                            <div className="info sans-pro-regular">
                                <div className="user">
                                    <div className="icone-user">
                                        <img src="./assets/img/logon.png" alt="user"/>
                                    </div>
                                    <a href="#">John Doe</a>
                                </div>
                                <div className="date">
                                    <div className="icone-date">
                                        <img src="./assets/img/planning_icon.png" alt="user"/>
                                    </div>
                                    23.01.24
                                </div>
                                <div className="notePre">
                                    <div className="icone-etoile">
                                        <img src="./assets/img/etoile.webp" alt="user"/>
                                    </div>
                                    Note précédente : 8   (il y a 4 jours)
                                </div>
                            </div>
                        </div>
                        <div className="divider-comment"></div>
                        <div className="element">
                            <div className="note sans-pro-regular">5 sur 10 <div className="point-green"></div></div>
                            <div className="message sans-pro-regular">
                                Manque de fonctionnalités mais service ok. <br/>
                                Besoin de progresser sur la qualité du linge et la réactivité du support.
                            </div>
                            <div className="info sans-pro-regular">
                                <div className="user">
                                    <div className="icone-user">
                                        <img src="./assets/img/logon.png" alt="user"/>
                                    </div>
                                    <a href="#">John Doe</a>
                                </div>
                                <div className="date">
                                    <div className="icone-date">
                                        <img src="./assets/img/planning_icon.png" alt="user"/>
                                    </div>
                                    23.01.24
                                </div>
                                <div className="notePre">
                                    <div className="icone-etoile">
                                        <img src="./assets/img/etoile.webp" alt="user"/>
                                    </div>
                                    Note précédente : 8   (il y a 4 jours)
                                </div>
                            </div>
                        </div>
                        <div className="divider-comment"></div>
                    </div>
                </div>
            </Modal>

        </>
    );
}

export default Reviews;
