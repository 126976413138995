import React, {useState} from 'react';
import "../styles/style.scss"
import {Avatar, Badge, Col, Drawer, Dropdown, Input, Menu, Popover, Progress, Row, Select, Table} from "antd";
import {IoAdd, IoEllipsisHorizontal, IoStar, IoSyncSharp} from "react-icons/io5";
import {EditOutlined, PlusCircleOutlined, SearchOutlined} from "@ant-design/icons";
import EquipeDrawerPage from "../drawer";
import {NavLink} from "react-router-dom";

function ChercheurEmploiPage() {
    const [open, setOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState([]);
    const showDrawer = (record) => {
        setCurrentRecord(record);
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onChange = (key) => {
        console.log(key);
    };
    const getMenuItems = (record) => [
        {
            label: <div className="add-logement2-item sans-pro-regular">
                <div className="imageSide">
                    <img src="../assets/img/attribuer-lgt-icon.png" alt="add" />
                </div>
                Attribuer un logement
            </div>,
            key: '1itemsOption',
        },
        {
            label: <div className="add-logement2-item sans-pro-regular">
                <div className="imageSide">
                    <img src="../assets/img/pourboire-icon.png" alt="add" />
                </div>
                Verser un pourboire
            </div>,
            key: '2itemsOption',
        },
        {
            type: 'divider',
        },
        {
            label:
                <div className="add-logement2-item sans-pro-regular" style={{ color: "red" }} onClick={() => null}>
                    <div className="imageSide">
                        <img src="../assets/img/bloquer-profil-icon.png" alt="add" />
                    </div>
                    Bloquer ce profil
                </div>,
            key: '6itemsOption',
        },
    ];

    const columns = [
        {
            title: "Prénom, Nom, Entreprise",
            width: "25%",
            render: (record) => (
                <div style={{ cursor: 'pointer', color: "#263754", fontSize: "15px" }} onClick={()=>showDrawer(record)}>
                    <div className="info">
                        <div className="name sans-pro-semi-bold">{record.name}</div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Email',
            width: "20%",
            render: (record) => (
                <div>
                    {record.telephone}
                </div>
            ),
        },
        {
            title: 'Numéro de téléphone',
            width: "15%",
            render: (record) => (
                <div>
                    {record.telephone}
                </div>
            ),
        },
        {
            title: 'Localité',
            width: "20%",
            render: (record) => (
                <div>
                    <div className="nbLogement">
                        {record.logement}
                    </div>
                </div>
            ),
        },
        {
            title: 'Créé depuis le…',
            width: "17%",
            render: (record) => (
                <div>{record.date}</div>
            ),
        },
        {
            title: "",
            key: "option",
            width: "6%",
            render: (record) => (
                <div  className="tab-option">
                    <Dropdown
                        overlay={<Menu items={getMenuItems(record)} />}
                        placement="bottomRight" trigger={['click']}>
                        <IoEllipsisHorizontal style={{ fontSize: "19px", marginRight: "10px" }} />
                    </Dropdown>
                </div>
            ),
        },
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const contentInfoBlocked = (
        <div className="infoLogement">
            <p>
                Ce responsable de site ne pourra <br/>
                plus être assigné sur vos <br/>
                logements. Il sera sorti de votre <br/>
                équipe lorsque tous vos <br/>
                logements où il est encore <br/>
                assigné seront transférés à <br/>
                d'autres responsables
            </p>
        </div>
    );
    const contentInfo = (
        <div className="infoLogement">
            <p>
                Des frais de gestion de 50€ <br/>
                s’appliquent si 30 jours après la <br/>
                mise en place d’une nouvelle <br/>
                équipe, celle-ci n’a effectuée <br/>
                aucune prestation.
            </p>
        </div>
    );
    const contentInfoSuccess = (
        <div className="infoLogement">
            <p>
                Représente le pourcentage de <br/>
                prestations effectuées sur vos <br/>
                logements dont la note globale <br/>
                est supérieure ou égale à 4 sur <br/>
                5.
            </p>
        </div>
    );
    const contentInfoSaturation = (
        <div className="infoLogement">
            <p>
                Représente le taux de remplissage <br/>
                du planning de l’équipe <br/>
                sélectionnée. Plus ce taux est <br/>
                élevé, moins l’équipe est flexible <br/>
                et la qualité des prestations est <br/>
                souvent affectée.
            </p>
        </div>
    );
    const items = [
        {
            label: <div onClick={() => null}  className="sans-pro-regular color-5B6E8C">
                <IoSyncSharp />  <span className="text">Demander une équipe pour un nouveau logement</span>
            </div>,
            key: '2',
        },
        {
            label: <div  className="sans-pro-regular color-5B6E8C" onClick={() => null}>
                <EditOutlined />  <span className="text">Changer l’équipe sur un logement actif</span>
            </div>,
            key: '3',
        },
        {
            label: <div  className="sans-pro-regular color-5B6E8C">
                <NavLink to={"/inviter-un-tierce"} className="color-5B6E8C"><PlusCircleOutlined /> <span className="text">Inviter une équipe tierce sur Kliner</span></NavLink>
            </div>,
            key: '4',
        },
    ];
    const equipesList = [
        {
            key: 1,
            name: `Agnes Debangy`,
            corp: `Nettoyage SARL`,
            photo: `user-empty.png`,
            date: `10 jannvier 2023`,
            telephone: "667994414",
            statut: "actif",
            special: false,
            logement: 2,
        },
        {
            key: 2,
            name: `Carmelia megrad`,
            corp: `HS Cleaner`,
            photo: `user-empty.png`,
            date: `10 jannvier 2023`,
            telephone: "+33650722510",
            statut: "actif",
            special: true,
            logement: 6,
        },
        {
            key: 3,
            name: `Jean-Francois Danjon`,
            corp: `Clean Up`,
            photo: `user-empty.png`,
            date: `10 jannvier 2023`,
            telephone: "667994414",
            statut: "wait",
            special: false,
            logement: 2,
        },
        {
            key: 4,
            name: `Julia Mbengue`,
            corp: `Etincelle`,
            photo: `user-empty.png`,
            date: `10 jannvier 2023`,
            telephone: "667994414",
            statut: "block",
            special: false,
            logement: 1,
        },
        {
            key: 5,
            name: `Kenny CHEUNG-A-LONG`,
            corp: `Net-propre Services`,
            photo: `user-empty.png`,
            date: `10 jannvier 2023`,
            telephone: "667994414",
            statut: "actif",
            special: false,
            logement: 9,
        },
        {
            key: 6,
            name: `Marine BABEY`,
            corp: `Kiphone Pro`,
            photo: `user-empty.png`,
            date: `10 jannvier 2023`,
            telephone: "667994414",
            statut: "pending",
            special: true,
            logement: 0,
        },
    ];

    return (
        <div className=''>
            <br/>
            <div className="header">
                <div className='flex justify-content-space-between'>
                    <div className='blc-left'>
                        <div className='blc-filter-alter'>
                            <Input
                                className='search-input'
                                placeholder="Chercher par nom de partenaire…"
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={null}
                            />
                        </div>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Responsable:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={null}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add"/> </div>}
                                options={[
                                    {
                                        value: 'Tout', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter"/>
                                            <div className="text sans-pro-regular color-213856" style={{marginLeft:"10px"}}>
                                                Camelia Megrard
                                            </div>
                                        </div>
                                    },
                                    {
                                        value: 'Tout', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter"/>
                                            <div className="text sans-pro-regular color-213856" style={{marginLeft:"10px"}}>
                                                Marine Babey
                                            </div>
                                        </div>
                                    },
                                    {
                                        value: 'Tout', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter"/>
                                            <div className="text sans-pro-regular color-213856" style={{marginLeft:"10px"}}>
                                                Julia Mbengue
                                            </div>
                                        </div>
                                    },
                                ]}
                                popupClassName="responsable-classe-site"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <Table
                    className="equipe-table"
                    pagination={{ pageSize: 10 }}
                    rowSelection={rowSelection}
                    dataSource={equipesList}
                    columns={columns}
                    rowKey={(record) => record.key}
                />
            </div>
            <Drawer title="" onClose={onClose} open={open} width={550} className="equipe-custom-drawer-class">
                <EquipeDrawerPage equipe={currentRecord}/>
            </Drawer>
        </div>
    );
}

export default ChercheurEmploiPage;
