import React, { useState, useEffect } from "react";
import { Col, Row, Divider } from "antd";
import { useNavigate, Link } from "react-router-dom";
import "../styles/style.scss";

export function RegisterSuccessPage() {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5);


    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);
        return () => clearInterval(countdownInterval);
    }, []);

    useEffect(() => {
        if (countdown === 0) {
            navigate("/dashboard")
        }
    }, [countdown]);


    return (
        <div className="auth" data-aos="fade-in">
            <div className="login-page">
                <a href="https://kliner.com">
                    <img src="/assets/img/logo-black-small.png" alt="kliner" className="login-logo-topleft" />
                </a>
                <Row>
                    <Col lg={5}>
                        <div className="blc-content">
                            <h1>Merci d’avoir rejoint Kliner !</h1>
                            <p>Vous faîtes désormais partie de la communauté des milliers de Superhôtes qui utilisent notre plateforme pour l’entretien de leurs locations saisonnières.</p> <br />
                            <Divider plain>Un instant</Divider>
                            <p>Vous allez être redirigé vers votre espace dans <span className="countdown">{countdown}</span> secondes...</p>
                        </div>
                    </Col>
                </Row>
                <div className="footer">
                    <center>
                        <p><Link to={"/dashboard"}>Accéder à votre espace</Link></p>
                    </center>
                </div>
            </div>

        </div>
    );
}

export default RegisterSuccessPage;
