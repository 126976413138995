import React from 'react';
import "../../styles/style.scss"

function BasicMCPage() {
    return (
        <>
            <div className="title-label sans-pro-semi-bold">Comment ça marche ?</div>
            <div className="liste gray-zone">
                <div className="point-label">
                    <div className="point">
                        <img src="/assets/img/light-colored.png" alt="done"/>
                    </div>
                    <div className="label sans-pro-semi-bold">
                        Plus sur le ménage classique
                    </div>
                </div>
                <div className="trait-explication">
                    <div className="trait trait-invisible"></div>
                    <div className="explication">
                        Ce service consiste à remettre du propre dans un logement à la fin d’une <br/>
                        réservation, en vue de l’arrivée des prochains voyageurs.
                    </div>
                </div>
            </div>
        </>
    );
}

export default BasicMCPage;
