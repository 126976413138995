import React, { useEffect, useState } from 'react';
import {Dropdown, Row, Col, Table, Input, Select, Calendar, Badge, Button, Avatar} from 'antd';
import {
    CalendarFilled,
    CaretLeftOutlined,
    CaretRightOutlined,
    EditOutlined,
    PlusCircleOutlined,
    ReloadOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { IoAdd } from "react-icons/io5";
import "./styles/style.scss";
import "./styles/styleMax1020.scss";
import {API_ROOT, convertDateStringToTimeString, monthList} from "../../utils/global-var";
import dayjs from 'dayjs';
import ProgrammerManuellementModalView from "./modal/programmer-manuellement";
import ValidProgrammationModalView from "./modal/valid_programmation";
import { useDispatch, useSelector } from "react-redux";
import {usePrestationFetchMutation} from "./services/prestations-api";
import moment from "moment";
import PlanningDetail from "./planning_detail";
import AnnulerPrestationModalView from "./modal/Annuler-prestation";

function PlanningPage() {
    const [showModal, setShowModal] = useState(false);
    const [showModalProgrammerService, setShowModalProgrammerService] = useState(false);
    const [showModalProgrammerValid, setShowModalProgrammerValid] = useState(false);
    const [showModalProgrammeApercu, setShowModalProgrammeApercu] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(monthList[parseInt(new Date().getMonth() + 1)] + " " + (new Date().getFullYear()));
    const [selectedMonth, setSelectedMonth] = useState(dayjs());
    const { user } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const [prestationGet] = usePrestationFetchMutation();
    const [prestationList, setPrestationList] = useState([]);
    const [statutFilter, setStatutFilter] = useState({});
    const [prestationId, setPrestationId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [startDate, setStartDate] = useState(new Date().getFullYear()+"-"+(new Date().getMonth()).toString().padStart(2, '0')+"-01");
    const [endDate, setEndDate] = useState(new Date().getFullYear()+"-"+(new Date().getMonth() + 2).toString().padStart(2, '0')+"-01");
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [selectedTable, setSelectedTable] = useState([]);
    useEffect(() => {
        if(prestationList.data?.length !== 0 && prestationList?.data !== null){
            setSelectedTable(getListData(selectedMonth));
        }
    }, [prestationList.data]);
    useEffect(() => {
        handleGet();
    }, [selectedMonth, showModalProgrammeApercu, statutFilter]);
    useEffect(() => {
        setCurrentMonth(monthList[selectedMonth.month() + 1] + " " + selectedMonth.year());
        setSelectedTable(getListData(selectedMonth));
    }, [selectedMonth, statutFilter]);
    const onPanelChange = (value, mode) => {
        //console.log(value.format('YYYY-MM-DD'), mode);
    };
    const handleChange = (value) => {
        //console.log(`selected ${value}`);
    };
    const handleGet = async () => {
        const data = ({ start_date: startDate, end_date: endDate, ...statutFilter })
        setIsLoading(true);
        prestationGet(data)
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                setShowModal(true)
                setPrestationList(res);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    }

    const columns = [
        {
            title: 'LOGEMENT',
            key: "logement",
            render: (record) => <div onClick={() => {
                setPrestationId(record.id);

                setUserId(record.user);
                setShowModalProgrammeApercu(true);
            }} style={{ cursor: "pointer" }}>
                <div className="titleTable color-213856 sans-pro-regular">{record.logement?.libelle}</div>
                <div className="subtitleTable sans-pro-regular color-A7A7A7">{record.logement?.ville}</div>
            </div>,
        },
        {
            title: '',
            key: "",
            render: (record) => <div onClick={() => {
                setPrestationId(record.id);
                setShowModalProgrammeApercu(true);
            }} style={{ cursor: "pointer" }}>
                <div className="imageEtoile">
                    {/*<img src="./assets/img/etoile.webp" alt=""/>*/}
                </div>
            </div>,
        },
        {
            title: 'PRESTATION',
            key: "prestation",
            render: (record) => <div onClick={() => {
                setPrestationId(record.id);
                setShowModalProgrammeApercu(true);
            }} style={{ cursor: "pointer" }}>
                <div className="titleTable color-213856 sans-pro-regular">{record.service}</div>
                <div className="subtitleTable sans-pro-regular color-A7A7A7">Entre {convertDateStringToTimeString(record?.creneau_intervention_debut)} et {convertDateStringToTimeString(record?.creneau_intervention_fin)}</div>
            </div>,
        },
        {
            title: 'INTERVENANT',
            key: "intervenant",
            render: (record) => <>
                {record.aide_menagere !== null && <div className="imageTableContent">
                    {record.aide_menagere?.avatar !== null &&
                        <Avatar className="imageTable" src={`${API_ROOT}/${record.aide_menagere.avatar}`}/>}
                    {record.aide_menagere?.avatar === null &&
                        <Avatar className="imageTable" src="./assets/img/logon.png"/>}
                </div>}
                {record.aide_menagere === null && <div className="imageTableContent">
                    <Avatar className="imageTable" src="./assets/img/logon.png"/>
                </div>}
            </>,
        },
        {
            title: 'STATUT',
            key: "statut",
            render: (record) => <div className="badgeTable">
                {(record.status === "En cours") && (<Badge color="#faad14" count={"En cours"} />)}
                {(record.status === "Programmé" || record.status === "suspended") && (<Badge color="black" count={"A faire"} />)}
                {(record.status === "Terminé") && (<Badge color={"rgb(0,192,112)"} count={"Terminé"} />)}
                {(record.status === "Annulé") && (<Badge color="red" count={"Annulé"} />)}
            </div>,
        }
    ];


    const onSelect = (value) => {
        setSelectedMonth(value);
        setCurrentMonth(monthList[value.format('M')] + " " + value.format('YYYY'));
    };

    const getListData = (value) => {
        let listData = [];
        const dateToCompare = value.format('YYYY-MM-DD');
        if (prestationList && prestationList.data) {
            listData = prestationList.data.filter(item => {
                const interventionDate = item.creneau_intervention_debut.split('T')[0];
                return interventionDate === dateToCompare;
            });
        }
        //console.log(listData);
        return listData;
    };

    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <div className="events">
                {(listData.length !== 0) && (
                    <div className="line" key={value.month() + "_" + value.date()}></div>
                )}
            </div>
        );
    };
    const cellRender = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        return info.originNode;
    };
    const onMenuClick = (e) => {
       // console.log('click', e);
    };
    const items2 = [
        {
            key: '1items2',
            label: '1st item',
        },
        {
            key: '2items2',
            label: '2nd item',
        },
        {
            key: '3items2',
            label: '3rd item',
        },
    ];

    const items = [
        {
            label: <div className="sans-pro-regular color-5B6E8C">
                <ReloadOutlined /> <span className="text">Automatiser le planning sur un logement</span>
            </div>,
            key: '1items',
        },
        {
            label: <div onClick={() => setShowModalProgrammerService(true)} className="sans-pro-regular color-5B6E8C">
                <EditOutlined />  <span className="text">Programmer un service manuellement</span>
            </div>,
            key: '2items',
        },
        {
            label: <div className="sans-pro-regular color-5B6E8C" onClick={() => null}>
                <PlusCircleOutlined />  <span className="text">Créer un nouveau logement & programmer</span>
            </div>,
            key: '3items',
        },
        {
            label: <div className="sans-pro-regular color-5B6E8C">
                <CalendarFilled /> <span className="text">Booster votre taux d’occupation</span>
            </div>,
            key: '4items',
        },
    ];

    const customHeader = () => {
        return (
            <>
                <div className="monthhead">
                    <div className="left" onClick={goToPreviousMonth}><CaretLeftOutlined /></div>
                    <div className="month">{currentMonth}</div>
                    <div className="right" onClick={goToNextMonth}><CaretRightOutlined /></div>
                </div>
                <div className="custom-weekdays">
                    <div>Dim</div>
                    <div>Lun</div>
                    <div>Mar</div>
                    <div>Mer</div>
                    <div>Jeu</div>
                    <div>Ven</div>
                    <div>Sam</div>
                </div>
            </>
        );
    };

    // Fonction pour changer au mois suivant
    const goToNextMonth = async () => {
        await setSelectedMonth(selectedMonth.add(1, 'month')); // Ajouter un mois à la date actuelle
        await setStartDate(selectedMonth.year() + "-" + (selectedMonth.subtract(2, 'month').month() === 0 ? 1: selectedMonth.subtract(2, 'month').month()).toString().padStart(2, '0') + "-01");
        let tmpDate = selectedMonth.add(4, 'month');
        await setEndDate(tmpDate.year() + "-" + (tmpDate.month() === 0 ? 1 : tmpDate.month()).toString().padStart(2, '0') + "-01");
    };

    // Fonction pour changer au mois précédent
    const goToPreviousMonth = async () => {
        await setSelectedMonth(selectedMonth.subtract(1, 'month')); // Soustraire un mois à la date actuelle
        await setStartDate(selectedMonth.year() + "-" + (selectedMonth.subtract(2, 'month').month() === 0 ? 1: selectedMonth.subtract(2, 'month').month()).toString().padStart(2, '0') + "-01");
        let tmpDate = selectedMonth.add(3, 'month');
        await setEndDate(tmpDate.year() + "-" + (tmpDate.month() === 0 ? 1 : tmpDate.month()).toString().padStart(2, '0') + "-01");
    };

    //console.log(startDate);
    //console.log(endDate);
    return (
        <div className='planning-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Planning de ménage</h1>
                </div>
            </div>

            <div>
                <Row>
                    <Col lg={4}>
                        <Input
                            className='search-input'
                            placeholder="Chercher par nom de logement..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                        />
                    </Col>
                </Row>
                <br />
                <div className='flex justify-content-space-between'>
                    <div className='blc-left'>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Statut:</span>
                            <Select
                                defaultValue="a faire"
                                onChange={(value) => setStatutFilter(value === 'tout' ? {} :{status: [value]})}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    {
                                        value: 'tout',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/a-faire-icon.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">
                                                Tout (0)
                                            </div>
                                        </div>,
                                    },
                                    {
                                        value: 'Programmé',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/a-faire-icon.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">
                                                A faire (0)
                                            </div>
                                        </div>,
                                    },
                                    {
                                        value: 'En cours',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/Play-Icon-black.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">
                                                En cours (0)
                                            </div>
                                        </div>,
                                    },
                                    {
                                        value: 'Terminé',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/done-icon-black.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">
                                                Terminé (0)
                                            </div>
                                        </div>,
                                    },
                                    {
                                        value: 'Annulé',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/Presta-annulee-icone.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">
                                                Annulée (0)
                                            </div>
                                        </div>,
                                    },
                                ]}
                                popupClassName="status-classe-select-prestation"
                            />
                        </div>

                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Responsable:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={handleChange}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    {
                                        value: 'jack',
                                        label: 'Jack',
                                    },
                                    {
                                        value: 'Tout',
                                        label: 'Tout',
                                    },
                                    {
                                        value: 'Yiminghe',
                                        label: 'yiminghe',
                                    },
                                    {
                                        value: 'disabled',
                                        label: 'Disabled',
                                    },
                                ]}
                            />
                        </div>

                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Types:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={handleChange}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    {
                                        value: 'jack',
                                        label: 'Jack',
                                    },
                                    {
                                        value: 'Tout',
                                        label: 'Tout',
                                    },
                                    {
                                        value: 'Yiminghe',
                                        label: 'yiminghe',
                                    },
                                    {
                                        value: 'disabled',
                                        label: 'Disabled',
                                    },
                                ]}
                            />
                        </div>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Ville:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={handleChange}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    {
                                        value: 'jack',
                                        label: 'Jack',
                                    },
                                    {
                                        value: 'Tout',
                                        label: 'Tout',
                                    },
                                    {
                                        value: 'Yiminghe',
                                        label: 'yiminghe',
                                    },
                                    {
                                        value: 'disabled',
                                        label: 'Disabled',
                                    },
                                ]}
                            />
                        </div>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Client:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={handleChange}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    {
                                        value: 'jack',
                                        label: 'Jack',
                                    },
                                    {
                                        value: 'Tout',
                                        label: 'Tout',
                                    },
                                    {
                                        value: 'Yiminghe',
                                        label: 'yiminghe',
                                    },
                                    {
                                        value: 'disabled',
                                        label: 'Disabled',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className='blc-right'>
                        <Button type={"primary"} disabled={true} className="programmer-menage-button" onClick={() => setShowModalProgrammerService(true)} >
                            <div className="image">
                                <img src="./assets/img/icon-white-stars.png" alt="icone"/>
                            </div>
                            Programmer un ménage
                        </Button>
                    </div>
                </div>
                <br />
                <Row>
                    <Col lg={14} className="calendar-zone">
                        <Calendar value={selectedMonth} onPanelChange={onPanelChange} onSelect={onSelect} cellRender={cellRender} headerRender={customHeader} className="planning-calendar" />
                    </Col>

                    <Col lg={10} className="apercu-zone scroll-none">
                        <div className="content-zone">
                            <div className="title sans-pro-semi-bold" >Prestation du jour</div>

                            <Table
                                className="planning-table scroll-none"
                                dataSource={selectedTable}
                                columns={columns}
                                pagination={{ pageSize: 7 }}
                                rowKey={(record) => record.key}
                                locale={{
                                    emptyText: (
                                        <Row>
                                            <Col lg={6}></Col>
                                            <Col lg={12}>
                                                <div className="empty-Tab-planning-prestation">
                                                    <img src="/assets/img/empty-tab.png" alt="airbnb" className="plateforme-img" />
                                                    <div className="title">Aucune prestation prévue ce jour </div>
                                                </div>
                                            </Col>
                                            <Col lg={6}></Col>
                                        </Row>
                                    ),
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <ProgrammerManuellementModalView
                status={showModalProgrammerService}
                setValidProgram={setShowModalProgrammerValid}
                handleCancel={() => setShowModalProgrammerService(false)}
                profil={"null"}
            />
            <ValidProgrammationModalView
                status={showModalProgrammerValid}
                handleCancel={() => setShowModalProgrammerValid(false)}
            />
            {/*<ApercuProgrammeModalView
                status={showModalProgrammeApercu}
                prestationId={prestationId}
                handleCancel={() => setShowModalProgrammeApercu(false)}
            />*/}
            {userId !== null && <PlanningDetail
                prestationId={prestationId}
                status={showModalProgrammeApercu}
                userId={userId}
                handleCancel={() => setShowModalProgrammeApercu(false)}
            />}
        </div>
    );
}

export default PlanningPage;
