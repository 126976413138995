import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const paiementApi = createApi({
    reducerPath: "paiementApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Paiement"],
    endpoints: (builder) => ({
        moyenPaiement: builder.query({
            query: (payload) => ({
                url: `/api/v1/user/${payload.id}`,
                method: "GET",
            }),
            invalidatesTags: ["Paiement"],
        }),
        getSubscriptionThreshold: builder.query({
            query: (userId) => ({
                url: `/api/v1/stripe/customer/${userId}/subscription/threshold`,
                method: "GET",
            }),
            invalidatesTags: ["Paiement"],
        }),        
    }),
});

export const {
    useMoyenPaiementQuery,
    useGetSubscriptionThresholdQuery
} = paiementApi;
