import React, {useEffect, useState} from 'react';
import "./styles/style.scss"
import {Tabs} from "antd";
import ResponsableSitePage from "./tabs/responsable-site";
import AideMenagerePage from "./tabs/aide-menagere";
import ChercheurEmploiPage from "./tabs/chercheur-emploi";
import {useEquipeEntretienFetchMutation} from "./services/equipe-entretien-api";
import {setRefresh} from "../logements/services/logement-slice";

function EquipePage() {
    const [open, setOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState([]);
    const [equipeEntretienFetch] = useEquipeEntretienFetchMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [keyTab, setKeyTab] = useState({});
    const [request, setRequest] = useState(null);
    const [listerEquipEntre, setListerEquipEntre] = useState([]);
    useEffect(() => {
        onChange("RESPONSABLE", request);
    }, [request]);
   /* useEffect(() => {
        if(open === false){
            setRequest(null);
        }
    }, [request]);*/
    const showDrawer = (record) => {
        setCurrentRecord(record);
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onChange = (key, request) => {
        const data = ({ pageView: key, status: "ALL", ...request })
        setIsLoading(true);
        equipeEntretienFetch(data)
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                setListerEquipEntre(res);
                if(request === null){
                    setKeyTab(res.stats);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    };
    const items = [
        {
            key: 'RESPONSABLE',
            label: <div className="sans-pro-light">Responsables de site</div>,
            children: <ResponsableSitePage record={listerEquipEntre} loading={isLoading} setRequest={setRequest} request={request} stats={keyTab} />,
        },
        {
            key: 'AIDE_MENAGERE',
            label: <div className="sans-pro-regular">Aides ménagères</div>,
            children: <AideMenagerePage record={listerEquipEntre} loading={isLoading} />,
        },
        {
            key: 'AIDE_MENAGERE222',
            label: <div className="sans-pro-regular">Chercheurs d'emploi</div>,
            children: <ChercheurEmploiPage record={listerEquipEntre} loading={isLoading} />,
        },
    ];
    return (
        <div className='equipe-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Equipe d'entretien</h1>
                </div>
            </div>
            <br/>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
    );
}

export default EquipePage;
