

export const addressChecked = (logement) => {
    if (logement?.libelle === "Logement de test") {
        return false
    } else {
        return true
    }
};

export const gestionCleChecked = (logement) => {
    if (logement?.gestion_cle === "None" || logement?.gestion_cle === null) {
        return false
    } else {
        return true
    }
};

export const surfaceChecked = (logement) => {
    if (logement?.superficie === null) {
        return false
    } else {
        return true
    }
};

export const gestionLingeChecked = (logement) => {
    if (logement?.laundryManagementType === "None" || logement?.laundryManagementType === "full") {
        return false
    } else {
        return true
    }
};

export const kitAccueilChecked = (cart) => {
    if (cart?.length === 0) {
        return false
    } else {
        return true
    }
};

export const materielProduitChecked = (logement) => {
    if (logement?.statut_kit_linge || logement?.statut_produit_menager) {
        return true
    } else {
        return false
    }
};


export const renameLogement = (logements) => {
    const nameValueLookup = {};
    return logements.map((obj) => {
        if (nameValueLookup[obj.logement_libelle]) {
            return {
                ...obj,
                logement_libelle: `${obj.logement_libelle} ${nameValueLookup[obj.logement_libelle]++}`,
            };
        } else {
            nameValueLookup[obj.logement_libelle] = 1;
            return obj;
        }
    });
};

export const customStatus = (status) => {
    switch (status) {
        case "ORANGE":
            return "#fa7f0d";
        case "GREEN":
            return "#65ae04";
        case "RED":
            return "#ff0000e3";
        default:
            return "gray";
    }
};