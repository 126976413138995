import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const factureclientApi = createApi({
    reducerPath: "factureclientApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Factureclient"],
    endpoints: (builder) => ({
        factureclientFetch: builder.query({
            query: (payload) => `api/v1/stripe/customer/${payload.id}/invoices/`,
            providesTags: ["Factureclient"],
        }),

        facturePartenaireFetch: builder.query({
            query: (userId) => `api/v1/billing/invoices?user=${userId}`,
            providesTags: ["Factureclient"],
        }),        
    }),
});

export const {
    useFactureclientFetchQuery,
    useFacturePartenaireFetchQuery
} = factureclientApi;
