import React, {useState} from 'react';
import {Col, Form, Input, Row} from "antd";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import {countryCodesMap} from "../../../../utils/global-var";
import {UserOutlined} from "@ant-design/icons";

function ContacterPartenairePage() {
    const [form] = Form.useForm();
    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState(countryCodesMap['FR']);
    const handleCountryChange = (value) => {
        setCountryCode(countryCodesMap[value]);
    };
    
    return (
        <div className="content-in">
            <div className="head">
                <div className="image">
                    <img src="./assets/img/logon.png" alt="profil"/>
                </div>
                <div className="name">
                    Brice,
                </div>
            </div>
            <div className="titlePage sans-pro-bold">
                Comment pouvons-nous contacter votre <br/> prestataire ?
            </div>
            <div className="subTitle sans-pro-regular">
                Nous utiliserons ces informations pour l’inviter à vous retrouver sur Kliner.
            </div>
            <div className="form-partenaire">
                <Form
                    name="basic"
                    form={form}
                    onFinish={null}
                    autoComplete="off"
                    layout="vertical">
                <Row>
                    <Col lg={6}>
                        <label className="sans-pro-semi-bold">Prénom</label>
                        <Form.Item name="email" rules={[{ required: true, message: "Champs requis!" }]}>
                            <Input placeholder="Prénom"  />
                        </Form.Item>
                    </Col>
                    <Col lg={1} />
                    <Col lg={8}>
                        <label className="sans-pro-semi-bold">Portable</label>
                        <PhoneInput
                            className="phone-input"
                            placeholder="0611220011"
                            flags={flags}
                            value={phone}
                            onChange={setPhone}
                            defaultCountry="FR"
                            onCountryChange={handleCountryChange}
                        />
                        <div style={{ position: 'absolute', left: 105, top: '56%', transform: 'translateY(-50%)', color: "gray", borderRight: "1.5px solid #8080805c", paddingRight: "6px", width: "35px", height:"15px", verticalAlign:"top" }}>
                           <div className="inCountry" style={{marginTop:"-2px"}}>
                               {countryCode}
                           </div>
                        </div>
                        <div style={{ position: 'absolute', right: 10, top: '56%', transform: 'translateY(-50%)' }}>
                            <UserOutlined />
                        </div>
                    </Col>
                </Row>
                </Form>
            </div>
        </div>
    );
}

export default ContacterPartenairePage;