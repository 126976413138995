import React, {useEffect, useState, useReducer} from "react";
import {Modal, Divider, Row, Col, Spin, Form, Input, Tabs, message, Checkbox} from "antd";
import {useLogementUpdateMutation} from "../../services/logement-api";


const GestionStatusLingeModalView = ({status, handleCancel, productAvailable, idLogement, refetch}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLinge, setIsLinge] = useState(productAvailable);
    const [updateLogement] = useLogementUpdateMutation();

    const onFinishFormation = (state) => {
        setIsLoading(true);
        setIsLinge(state);
        updateLogement({id: idLogement, data: {is_product_available: state}})
            .unwrap()
            .then((res) => {
                refetch();
                setIsLoading(false);
                message.success(`Informations du status du linge mis à jour!`);
            })
            .catch((error) => {
                setIsLoading(false);
                message.error(error?.data?.message);
            });

    }
    const onCloseModal = () => {
        handleCancel();
    }
    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{top: 200}}
            onCancel={() => handleCancel()}
            width={500}
            className="gererStatusLinge">

            <Spin spinning={isLoading}>
                <div className="compte">
                    <div className="title sans-pro-semi-bold">
                        <div className="title-label">
                            Gérer le statut du linge
                        </div>
                    </div>
                    <p>
                        Lorsque la case est cochée, cela signifie que le partenaire
                        dispose déjà du linge nécessaire pour gérer ce logement ou que
                        l’expédition dudit linge est en cours.
                    </p>
                    <p>
                        Si vous décochez la case, le système programmera l’envoi au
                        partenaire sous 7 jours, du linge pour ce logement.
                    </p>
                    <p>
                        <strong>ATTENTION :</strong> En cas de changement de partenaire sur le
                        logement, la restitution et la mise à disposition du linge doivent
                        être gérées manuellement.
                    </p>
                    <div className="is_formation_taken">
                        <Checkbox checked={isLinge ?? false} onChange={(e)=> {
                            onFinishFormation(e.target.checked);
                        }}> <span style={{fontSize:"14px"}}>Le partenaire dispose du linge nécessaire pour ce logement</span> </Checkbox>
                    </div>

                    <div className="footer-modal">
                        <Divider/>
                        <Row>
                            <Col lg={12} className="button-left">

                            </Col>
                            <Col lg={11} className="button-right">
                                <a href="#" onClick={() => onCloseModal()}
                                   className="suivant sans-pro-regular">Valider</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default GestionStatusLingeModalView;
