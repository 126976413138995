import React from 'react';
import "./styles/style.scss"

function ConciergeriePage() {
    return (
        <div className='conciergerie-page'>
            Conciergerie page
        </div>
    );
}

export default ConciergeriePage;
