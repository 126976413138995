import React, { useState } from "react";
import {Modal, Divider, Row, Col, Spin, message, Form, Input, Checkbox, Alert, Radio} from "antd";
import { useChargeLaundryCautionFeesMutation } from "../services/logement-api";

const LaundryCautionFeesModal = ({ status, logement, handleCancel, handleCancelSuccess }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [mode, setMode] = useState(null);
    const [chargeCautionFees] = useChargeLaundryCautionFeesMutation();

    const handleSubmit = () => {
        setIsLoading(true);
        chargeCautionFees({ id: logement.id, mode })
            .unwrap()
            .then(() => { handleCancelSuccess(); setIsLoading(false); message.success("") })
            .catch((error) => {
                setIsLoading(false);
                message.error(error.data.message);
            });        
    }

    const onChange = (value) => {
        setMode(value);
    }

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={600}
            className="suppressionLogementNotPossible">

            <Spin spinning={isLoading}>
                <div className="supprimer">
                    <div className="title sans-pro-semi-bold">
                        <div className="title-label">Gestion de la caution linge</div>
                    </div>
                    {
                        logement?.caution_fee_status === "inProgress" &&
                        <div><br/><em style={{textAlign: "center"}}>&nbsp; Le paiement de la caution est en cours</em><br/></div> 
                    } 
                    {
                        logement?.caution_fee_status !== "inProgress" &&
                        <Row>
                            <Form
                            name="basic"
                            onFinish={null}
                            autoComplete="off"
                            layout="vertical"
                            className="form-edit-profil"
                        >
                            <br/>
                                <Radio.Group onChange={(e)=>onChange(e.target.value)}>
                            
                                            <Row>
                                            <Col lg={24}>
                                            <Radio value={"manual"}>
                                                   Ce logement est exempté de caution
                                                </Radio> 
                                            </Col>
                                            </Row>                                       
                                </Radio.Group>                               
    {/*                         <Row>
                                <Col lg={24}>
                                    <label htmlFor="prenom" className={"force-label-effet "}>Evolution duréee forfaitaire</label>
                                    <Form.Item name="remember" valuePropName="checked">
                                    <Checkbox>Rembourser la caution linge</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row> */}
                            {/* <Row>
                                <Col lg={24}>
                                    <Form.Item name="debit_man_caution_fees" valuePropName="checked">
                                    <Checkbox>La caution a été encaissée manuellement</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>   */}                                                                                         
                            </Form>
                        </Row>                        
                    }  
                    {/* {error && <Alert message={error} type="error" closable />}   */}
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={14} className="button-left">
                                <a href="#" onClick={() => {
                                    handleCancel();
                                }} className="annuler"><span> Annuler</span></a>
                            </Col>

                           {
                            logement?.caution_fee_status !== "inProgress" &&
                            <Col lg={10} className="button-right">
                                <a href="#" onClick={() => handleSubmit()}
                                    className="suivant sans-pro-regular">Lancer l'opération</a>
                            </Col>                            
                           }
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default LaundryCautionFeesModal;
