import React from 'react';
import "./styles/style.scss"

function RapportPage() {
    return (
        <div className='rapport-page'>
            <span className="normal">Ce menu sera bientôt <br/> activé. </span>
            <span className="underline">Restez connectés!</span>

            <div className="line">
                <div className="coverLine"></div>
                <img src="./assets/img/rocket-page-attente.png" alt="rocket"/>
            </div>
        </div>
    );
}

export default RapportPage;
