import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, DatePicker, Form } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import { usePrestationUpdateMutation } from "../services/planning-api";
import { setRefresh } from "../services/planning-slice";


const PrestationFordward = ({ handleCancel, prestationId, creneauDebut }) => {
    const [date, setDate] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const [prestationUpdate] = usePrestationUpdateMutation();

    const [form] = Form.useForm();

    const onChangeDate = (date, dateString) => {
        setDate(dateString);
    };

    const handleForward = () => {
        const timePart = creneauDebut.substring(creneauDebut.indexOf('T'));
        const combinedDateTime = `${date}${timePart}`;
        const data = { creneau_intervention_debut: combinedDateTime }
        setLoading(true)
        prestationUpdate({ id: prestationId, data: data })
            .unwrap()
            .then(() => {
                dispatch(setRefresh(true));
                form.resetFields();
                setLoading(false);
                handleCancel()
            })
            .catch(() => setLoading(false));
    }

    return (
        <div>
            <h4> Choisir une date</h4>
            <Form name="basic" onFinish={handleForward} form={form} layout="vertical">
                <Col lg={24}>
                    <Form.Item rules={[{ required: true, message: " " }]}>
                        <DatePicker locale={locale} format={"YYYY-MM-DD"} onChange={onChangeDate} />
                    </Form.Item>
                </Col>
                <Button type="primary" htmlType="submit" className="btn-lg" loading={loading}>
                    Reporter la prestation
                </Button>
            </Form>
        </div>
    );
};
export default PrestationFordward;
