import React, { useState } from "react";

import { Badge, Col, Divider, Row, Switch, Table } from "antd";

const AutonomeTab = ({
  setVariableFees,
  setAcceleratedPayment,
  variableFees,
  acceleratedPayment,
  nextVariableFees,
  nextAcceleratedPayment,
}) => {
  return (
    <div className="">
      <div className="">
        <br />
        <div className="mintitle">OPTIONS</div>
        <Row>
          <Col lg={12}>
            <Switch
              value={acceleratedPayment}
              onChange={(checked) => setAcceleratedPayment(checked)}
            />
            <div className="labelSwitch">Paiement accéléré</div>
          </Col>
          <Col lg={12}>
            <Switch
              value={variableFees}
              onChange={(checked) => setVariableFees(checked)}
            />
            <div className="labelSwitch">Frais variables</div>
          </Col>
        </Row>
        {(nextAcceleratedPayment !== null || nextVariableFees !== null) && (
          <Row>
            <Col lg={24}>
             { nextAcceleratedPayment !== null && nextAcceleratedPayment !== undefined && (
              <em style={{color: "red"}}>
                {nextAcceleratedPayment === false
                  ? "La désactivation"
                  : "L'activation"}{" "}
                de l'option paiement accéléré prendra effet le 1er du mois
                prochain
              </em>)
             }
             <br />
              {nextVariableFees !== null && nextVariableFees !== undefined && (
              <em style={{color: "red"}}>
                {nextVariableFees === false
                  ? "La désactivation"
                  : "L'activation"}{" "}
                de l'option frais varible prendra effet le 1er du mois prochain
              </em>
              )}
            </Col>
          </Row>
          
        )}
         <br />
      </div>
    </div>
  );
};

export default AutonomeTab;
