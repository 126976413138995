import React, {useEffect, useState} from "react";
import {Modal, Row, Col, Alert, Space, Button, Form, Input, Select, Divider, message} from "antd";
import AideMenagePart from "./etapes/aide-menagere-conforme";
import AideMenageApercu from "./apercu/aide-menagere-apercu";
import LingeAmenitiePart from "./etapes/linge-amenitie-necessaire";
import HeureDemarragePart from "./etapes/heure-demarrage-prestation";
import HeureTerminerPart from "./etapes/heure-terminer-prestation";
import AvisFinalPart from "./etapes/avis-final";
import moment from "moment/moment";
import {useRatingPrestationMutation} from "../services/dashboard-entretien";
import {convertDateStringToTimeStringDateOnly} from "../../../utils/global-var";


function Controle({ status, handleCancel, currentLogementToCheck }) {
    const [element, setElement] = useState('AideMenagePart');
    const [level, setLevel] = useState(20);
    const [aideMenagePart, setAideMenagePart] = useState(null);
    const [lingeAmenitiePart, setLingeAmenitiePart] = useState(null);
    const [heureDemarragePart, setHeureDemarragePart] = useState(null);
    const [heureTerminerPart, setHeureTerminerPart] = useState(null);
    const [avisFinalPart, setAvisFinalPart] = useState(null);
    const [avisFinalPartNote, setAvisFinalPartNote] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [ratingSet] = useRatingPrestationMutation();

    useEffect(() => {
        setAideMenagePart({presence_confirmee:true});
        setLingeAmenitiePart(null);
        setHeureDemarragePart(null);
        setHeureTerminerPart(null);
        setAvisFinalPart(null);
        setAvisFinalPartNote(null);
        setLevel(20);
        setElement('AideMenagePart');
        if(currentLogementToCheck?.quality_control_presence_confirmee){
            setLevel(40);
            setElement('LingeAmenitiePart');
            if(currentLogementToCheck?.quality_control_linge_amenities_deposes){
                setLevel(60);
                setElement('HeureDemarragePart');
                if(currentLogementToCheck?.quality_control_prestation_started_on_time !== null){
                    setLevel(80);
                    setElement('HeureTerminerPart');
                    if(currentLogementToCheck?.quality_control_prestation_finished_on_time !== null){
                        setLevel(100);
                        setElement('AvisFinalPart');
                    }
                }
            }
        }

    }, [currentLogementToCheck?.prestation_id, currentLogementToCheck?.quality_control_presence_confirmee, currentLogementToCheck?.quality_control_linge_amenities_deposes, currentLogementToCheck?.quality_control_prestation_started_on_time, currentLogementToCheck?.quality_control_prestation_finished_on_time]);
    const onCancel = () => {
        setIsLoading(true);
        var data = {prestation: currentLogementToCheck.prestation_id};
        if(level >= 20){
            console.log(aideMenagePart);
            data = {...data, ...aideMenagePart}
        }
        if(level >= 40){
            if(lingeAmenitiePart !== null){
                data = {...data, ...lingeAmenitiePart}
            }
        }
        if(level >= 60){
            if(heureDemarragePart !== null){
                data = {...data, ...heureDemarragePart};
            }
        }
        if(level >= 80){
            if(heureTerminerPart !== null){
                data = {...data, ...heureTerminerPart};
            }
        }
        if(level >= 100){
            if(avisFinalPart !== null || avisFinalPartNote !== null){
                data = {...data, ...avisFinalPart, ...avisFinalPartNote};
            }
        }
        ratingSet(data)
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                message.success("Controle effectué.");
                handleCancel();
            })
            .catch((error) => {
                setIsLoading(false);
                message.error(error.data.message);
            });
    };
    console.log(currentLogementToCheck?.prestation_status);
    console.log(avisFinalPart);
    console.log(avisFinalPartNote);
    return (
        <>
            <Modal
                open={status}
                footer={null}
                closable={true}
                style={{top:190, left:100}}
                width={550}
                onCancel={handleCancel}
                className="controle-prestation-modal"
            >
                <div className="controle-prestation">
                    <div className="head">
                        <div className="sticker">
                            👀
                        </div>
                        <div className="title sans-pro-semi-bold">
                            Contrôle de prestation
                        </div>
                        <div className="info sans-pro-semi-bold">
                            <div className="date">{convertDateStringToTimeStringDateOnly(currentLogementToCheck?.prestation_creneau_intervention_debut)}</div>
                            <div className="point1"></div>
                            <div className="name">Joie Paris</div>
                            <div className="point2"></div>
                            <div className="lieu">{currentLogementToCheck?.logement_libelle}</div>
                        </div>
                        <div className="p">Assurons-nous que cette prestation soit complètement réussie.</div>
                        <div className="level sans-pro-semi-bold">
                            {level}%
                        </div>
                    </div>
                    <div className="loader">
                        <div className="barIndincator">
                            <div className="barIndincator-level" style={{width:level+"%"}}></div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="element">
                            {element === "AideMenagePart" && <AideMenagePart setAideMenagePart={setAideMenagePart} next={()=> {setElement('LingeAmenitiePart'); setLevel(40);}}/>}
                            {element === "LingeAmenitiePart" && <LingeAmenitiePart setLingeAmenitiePart={setLingeAmenitiePart} next={()=> {setElement('HeureDemarragePart'); setLevel(60);}}/>}
                            {element === "HeureDemarragePart" && <HeureDemarragePart setHeureDemarragePart={setHeureDemarragePart} next={()=> {setElement('HeureTerminerPart'); setLevel(80);}}/>}
                            {element === "HeureTerminerPart" && <HeureTerminerPart setHeureTerminerPart={setHeureTerminerPart} next={()=> {setElement('AvisFinalPart'); setLevel(100);}}/>}
                            {element === "AvisFinalPart" && <AvisFinalPart setAvisFinalPart={setAvisFinalPart} setAvisFinalPartNote={setAvisFinalPartNote}/>}
                        </div>
                    </div>
                    <div className="footer">
                        <Button onClick={(element === "AvisFinalPart" && avisFinalPart !== null && avisFinalPartNote !== null && currentLogementToCheck?.prestation_status === "Terminé") || (element !== "AvisFinalPart") ? onCancel : null} className={(element === "AvisFinalPart" && avisFinalPart !== null && avisFinalPartNote !== null && currentLogementToCheck?.prestation_status === "Terminé") || (element !== "AvisFinalPart") ? "" : "disable"}>Enregister & fermer</Button>
                    </div>
                </div>
            </Modal>

        </>
    );
}

export default Controle;
