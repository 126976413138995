import React, { useState } from "react";
import {Modal, Tabs} from "antd";
import {useFacturePartenaireFetchQuery} from "../../clients/services/facture-api";
import FacturesPartTab from "../../clients/drawer/modal/Tabs/factures_partenaire";


function DocumentEquipeModal({ status, handleCancel, record }) {
    const factures = useFacturePartenaireFetchQuery(record.id);
    console.log(record);
    const onCancel = () => {
        handleCancel();
    };

    return (
        <>
            <Modal
                open={status}
                footer={null}
                closable={true}
                style={{top:250, left:100}}
                width={900}
                onCancel={onCancel}
                className="document-client-modal"
            >
                <div className="document-client">
                    <div className="titre">
                        <div className="icone-doc">
                            <img src="./assets/img/doc icon2.png" alt="icon"/>
                        </div>
                        <div className="text sans-pro-semi-bold">
                            Documents de {record?.lastname} {record?.firstname}
                        </div>
                    </div>
                    <div className="tabList">
                        <FacturesPartTab factures={factures}/>
                    </div>
                </div>
            </Modal>

        </>
    );
}

export default DocumentEquipeModal;
