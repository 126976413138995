import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover, Space, Radio, Spin, message
} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";

import {useSelector} from "react-redux";

const DepotLingeModalView = ({status, handleCancel, laundryKit, plan}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { user } = useSelector((state) => state.auth);
    const [planSelected, setPlanSelected] = useState(null);
    const [litUnePlace, setLitUnePlace] = useState( 0 );
    const [litDeuxPlace, setLitDeuxPlace] = useState(0 );
    const [litKQSize, setLitKQSize] = useState( 0 );

    const handleChangePlan = (value) => {
        //plan(value);
        setPlanSelected(value);
    };

    const onCloseModal = () => {
        plan(planSelected);
        laundryKit(litUnePlace + litDeuxPlace + litKQSize);
        handleCancel();
    };

    const changeNbMoins = (value) => {
        if (value === "litUnePlace") {
            setLitUnePlace(litUnePlace - 1);
        }
        if (value === "litDeuxPlace") {
            setLitDeuxPlace(litDeuxPlace - 1);
        }
        if (value === "litKQSize") {
            setLitKQSize(litKQSize - 1);
        }
    };

    const handleChange = (e, value) => {
        if (value === "litUnePlace") {
            setLitUnePlace(e);
        }
        if (value === "litDeuxPlace") {
            setLitDeuxPlace(e);
        }
        if (value === "litKQSize") {
            setLitKQSize(e);
        }
    };

    const changeNbPlus = (value) => {
        if (value === "litUnePlace") {
            setLitUnePlace(parseInt(litUnePlace) + 1);
        }
        if (value === "litDeuxPlace") {
            setLitDeuxPlace(parseInt(litDeuxPlace) + 1);
        }
        if (value === "litKQSize") {
            setLitKQSize(parseInt(litKQSize) + 1);
        }
    };


    const handleBlur = (e, value) => {
        if (value === "litUnePlace") {
            setLitUnePlace(e);
        }
        if (value === "litDeuxPlace") {
            setLitDeuxPlace(e);
        }
        if (value === "litKQSize") {
            setLitKQSize(e);
        }
    };


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 150, left: -0.5}}
            closable={true}
            onCancel={() => {
                handleCancel();
            }}
            width={585}
            className="programmer-manuellement"
        >

            <div className="depot-linge">
                <div className="gestion-complete blc-right">
                    {contextHolder}
                    <div className="subTitle sans-pro-regular">De combien de kits de linge avez-vous besoin (1 lit = 1 kit) ?</div>
                    <Spin spinning={false}>
                        <Row gutter={24}>
                            <div className="blc-content">
                                <br />
                                <Row gutter={24}>
                                    <Col lg={24}>
                                        <div className="contour-point-gris">
                                            <Row gutter={24}>
                                                <Col lg={17}>
                                                    <p>Nombre de lits une (1) place </p>
                                                </Col>
                                                <Col className="counter-side">
                                            <span className={"moins " + (litUnePlace === 0 ? "disable-Button" : "")}
                                                  onClick={litUnePlace === 0 ? null : () => changeNbMoins("litUnePlace")}> <MinusOutlined /> </span>
                                                    <div className="nombre4">
                                                        <Input className="nombre-input" value={litUnePlace}
                                                               onChange={(e) => handleChange(e.target.value, "litUnePlace")}
                                                               onBlur={(e) => handleBlur(e.target.value, "litUnePlace")} />
                                                    </div>
                                                    <span className="plus"
                                                          onClick={() => changeNbPlus("litUnePlace")}> <PlusOutlined />
                                            </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Divider />
                                    <Col lg={24}>
                                        <div className="contour-point-gris">
                                            <Row gutter={24}>
                                                <Col lg={17}>
                                                    <p>Nombre de lits deux (2) places </p>
                                                </Col>
                                                <Col className="counter-side">
                                            <span className={"moins " + (litDeuxPlace === 0 ? "disable-Button" : "")}
                                                  onClick={litDeuxPlace === 0 ? null : () => changeNbMoins("litDeuxPlace")}> <MinusOutlined /> </span>
                                                    <div className="nombre4">
                                                        <Input className="nombre-input" value={litDeuxPlace}
                                                               onChange={(e) => handleChange(e.target.value, "litDeuxPlace")}
                                                               onBlur={(e) => handleBlur(e.target.value, "litDeuxPlace")} />
                                                    </div>
                                                    <span className="plus"
                                                          onClick={() => changeNbPlus("litDeuxPlace")}> <PlusOutlined />
                                            </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Divider />
                                    <Col lg={24}>
                                        <div className="contour-point-gris">
                                            <Row gutter={24}>
                                                <Col lg={17}>
                                                    <p>Nombre de lits KING ou QUEEN size </p>
                                                </Col>
                                                <Col className="counter-side">
                                            <span className={"moins " + (litKQSize === 0 ? "disable-Button" : "")}
                                                  onClick={litKQSize === 0 ? null : () => changeNbMoins("litKQSize")}> <MinusOutlined /> </span>
                                                    <div className="nombre4">
                                                        <Input className="nombre-input" value={litKQSize}
                                                               onChange={(e) => handleChange(e.target.value, "litKQSize")}
                                                               onBlur={(e) => handleBlur(e.target.value, "litKQSize")} />
                                                    </div>
                                                    <span className="plus"
                                                          onClick={() => changeNbPlus("litKQSize")}> <PlusOutlined />
                                            </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                    </Spin>
                    <br/>
                    <br/>
                    <br/>
                    <div className="subTitle sans-pro-regular">Qualité du linge</div>
                    <Spin spinning={false}>
                        <Row gutter={24}>
                            <div className="blc-content">
                                <br/>
                                <Row gutter={24}>
                                    <Col lg={24}>
                                        <Row>
                                            <Col lg={6} className="qualite-linge-choix">
                                                <div className={"qualite-linge-image-zone " + (planSelected === "standard" ? 'qualite-linge-image-zone-selected' : '')}
                                                     onClick={() => handleChangePlan("standard")}>
                                                    <div className="checkButton">
                                                        <img src="./assets/img/green-check.png" alt="check" />
                                                    </div>
                                                    <img src="./assets/img/standard.png" className="qualite-linge-image" alt="standard" />
                                                </div>
                                                <p className={"qualite-linge-label "+ (planSelected === "standard" ? ' sans-pro-semi-bold' : ' sans-pro-regular color-5B6E8C')}>Standard</p>
                                            </Col>
                                            <Col lg={1}></Col>
                                            <Col lg={6} className="qualite-linge-choix">
                                                <div className={"qualite-linge-image-zone " + (planSelected === "luxe" ? 'qualite-linge-image-zone-selected' : '')} onClick={() => handleChangePlan("luxe")}>
                                                    <div className="checkButton">
                                                        <img src="./assets/img/green-check.png" alt="check" />
                                                    </div>
                                                    <img src="./assets/img/luxe.png" className="qualite-linge-image" alt="luxe" />
                                                </div>
                                                <p className={"qualite-linge-label "+ (planSelected === "luxe" ? ' sans-pro-semi-bold' : ' sans-pro-regular color-5B6E8C')}>Luxe</p>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col lg={24}>
                                        <p className="qualite-linge-description sans-pro-semi-bold"><strong>Description</strong></p>
                                        <p className="color-5B6E8C sans-pro-light">
                                            Draps, taies et housses de couette 100% coton, 57 fils/cm2. Serviettes de bain et draps de douche en coton doux, ultra absorbant.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                    </Spin>
                </div>
                <div className="footer-modal">
                    <Row>
                        <Col lg={7} >
                        </Col>
                        <Col lg={10} className="button-right">
                            <a href="#" onClick={() => onCloseModal()}
                               className="suivant sans-pro-regular">Confimer</a>
                        </Col>
                    </Row>
                </div>
            </div>


        </Modal>
    );
};
export default DepotLingeModalView;
