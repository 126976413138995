import React, { useState, useEffect } from 'react';
import {Layout, Divider, Dropdown, Button} from 'antd';
import { useDispatch, useSelector } from "react-redux";
import {DesktopOutlined, PieChartOutlined, StarOutlined} from '@ant-design/icons';
import { NavLink } from "react-router-dom";
import { AiOutlineDashboard } from "react-icons/ai";
import {
    IoHomeSharp,
    IoCheckboxOutline,
    IoStatsChartSharp,
    IoSettingsOutline,
    IoConstruct,
    IoRadioSharp, IoLogOutOutline
} from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaUsers } from "react-icons/fa6";
import "./styles/style.scss"
import { useAuthFetchTokenMutation } from '../../pages/auth/services/auth-api';
import { setUser } from '../../pages/auth/services/auth-slice';
const { Sider } = Layout;


function Sidebar() {
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const [authFetchToken] = useAuthFetchTokenMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const { user } = useSelector((state) => state.auth);

    const handleFecthUser = async () => {
        setIsLoading(true);
        authFetchToken()
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                dispatch(setUser(res));
            })
            .catch((error) => {
                setError(error.data.message);
                setIsLoading(false);
            });
    };

    const items= [
        {
            key: '1',
            label: (
                <NavLink to={"/"} className="menuProfil"> <span>Se déconnecter</span> <IoLogOutOutline style={{fontSize:"20px"}} /></NavLink>
            ),
        },
    ];

    useEffect(() => {
        handleFecthUser()
    }, []);

    return (
        <Sider width={250} className='sidebar' style={{ background: "White" }} trigger={null} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <div className="logo">
                <NavLink to={"/dashboard"}>
                    <img src='./assets/img/logo-black-hd.png' />
                    <div style={{paddingLeft:"40px", color:"black", fontSize:"17px", verticalAlign:"top", marginTop:"-10px"}} className="sans-pro-regular">BackOffice</div>
                </NavLink>
            </div>
            <div className='menu'>
                <ul style={{ marginTop: '46px' }}>
                    <li><NavLink to={"/dashboard"}> <AiOutlineDashboard className='icon' /> <span>Tableau de bord</span></NavLink></li>
                </ul>
                <Divider />
                <ul>
                    <li><NavLink to={"/clients"}> <FaUsers className='icon' /> <span>Clients</span></NavLink></li>
                    <li><NavLink to={"/logements"}> <IoHomeSharp className='icon' /> <span>Logements</span></NavLink></li>
                    <li><NavLink to={"/planning-menage"}> <FaRegCalendarAlt className='icon' /> <span>Prestations</span></NavLink></li>
                    <li><NavLink to={"/equipes-entretien"}> <FaUsers className='icon' /> <span>Équipes d’entretien</span></NavLink></li>
                    <li><NavLink to={"/qualite-Logistique"}> <IoCheckboxOutline className='icon' /> <span>Qualité & Logistique</span></NavLink></li>
                    <li><NavLink to={"/notes-commentaires"}> <StarOutlined className='icon' style={{marginLeft:"0px"}} /> <span>Notes & commentaires</span></NavLink></li>
                </ul>

               <div className="beforBottom">
                   <ul className="mb-toBottom">
                       <li><NavLink to={"/rapports"}> <IoStatsChartSharp className='icon' /> <span>Rapports</span></NavLink></li>
                       <li><NavLink to={"/parrainage"}> <div className="isImage"><img src="./assets/img/parrainage.png" alt="parrainage"/></div> <span>Parrainage</span></NavLink></li>
                       {/*<li><NavLink to={"/conciergerie-en-reseau"}> <DesktopOutlined className='icon' /> <span>Conciergerie en réseau</span></NavLink></li>*/}
                   </ul>
                   <Dropdown menu={{ items }} placement="top" overlayClassName="menuProfil-dropdown">
                       <div className="userBox">
                           <div className="imgSide">
                               <div className="online"></div>
                               <img src="/assets/img/logon.png" alt="user"/>
                           </div>
                           <div className="nameSide">
                               {user?.firstname} {user?.lastname}
                           </div>
                       </div>
                   </Dropdown>
               </div>
            </div>
            {/* <Button
                type="text"
                icon={collapsed ? <PieChartOutlined /> : <DesktopOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                }}
            /> */}

        </Sider>
    );
}

export default Sidebar;