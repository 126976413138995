import React from 'react';
import "./styles/style.scss"

function HommeToutFairePage() {
    return (
        <div className='dashboard-page'>
            Homme page
        </div>
    );
}

export default HommeToutFairePage;
