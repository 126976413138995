import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover, Space, Radio, Spin, message, TimePicker
} from "antd";
import {ArrowLeftOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";

import {useSelector} from "react-redux";

const KitDeLingeModalView = ({status, handleCancel}) => {

    const timeFormat = 'HH:mm';

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 410, left: -410}}
            closable={false}
            onCancel={() => {
                handleCancel();
            }}
            width={300}
            className="kit-linge"
        >

            <div className="kit">
                <div className="head">
                    <div className="arrow" onClick={handleCancel}><ArrowLeftOutlined /></div>
                    <div className="title"><div  className="title-in">Modifier les kits de linge</div></div>
                </div>
                <br/>
                <p>Kits à fournir pour cette prestation</p>
                <br/>
                <Row>
                    <Col lg={16}>
                        <div className="libelle">Kit de linge Simple</div>
                    </Col>
                    <Col lg={8}>
                        <span className={"moins "}
                              onClick={null}> <MinusOutlined/> </span>
                        <div className="nombre">
                            <Input className="nombre-input" value={0}
                                   onChange={(e) => null}
                                   onBlur={(e) => null}/>
                        </div>
                        <span className="plus"
                              onClick={null}> <PlusOutlined/>
                                            </span>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col lg={16}>
                        <div className="libelle">Kit de linge Double</div>
                    </Col>
                    <Col lg={8}>
<span className={"moins "}
      onClick={null}> <MinusOutlined/> </span>
                        <div className="nombre">
                            <Input className="nombre-input" value={0}
                                   onChange={(e) => null}
                                   onBlur={(e) => null}/>
                        </div>
                        <span className="plus"
                              onClick={null}> <PlusOutlined/>
                                            </span>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col lg={16}>
                        <div className="libelle">Tapis de bain</div>
                    </Col>
                    <Col lg={8}>
<span className={"moins "}
      onClick={null}> <MinusOutlined/> </span>
                        <div className="nombre">
                            <Input className="nombre-input" value={0}
                                   onChange={(e) => null}
                                   onBlur={(e) => null}/>
                        </div>
                        <span className="plus"
                              onClick={null}> <PlusOutlined/>
                                            </span>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col lg={16}>
                        <div className="libelle">Torchon de cuisine</div>
                    </Col>
                    <Col lg={8}>
<span className={"moins "}
      onClick={null}> <MinusOutlined/> </span>
                        <div className="nombre">
                            <Input className="nombre-input" value={0}
                                   onChange={(e) => null}
                                   onBlur={(e) => null}/>
                        </div>
                        <span className="plus"
                              onClick={null}> <PlusOutlined/>
                                            </span>
                    </Col>
                </Row>

                <div className="footer-modal">

                    <Row>
                        <Col lg={24} className="button-right">
                            <a href="#" onClick={handleCancel}
                               className="suivant sans-pro-regular">Confimer</a>
                        </Col>
                    </Row>
                </div>
            </div>


        </Modal>
    );
};
export default KitDeLingeModalView;
