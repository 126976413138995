import React, { useState } from "react";
import { Button, Form, Input, Alert, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { setToken } from "../services/auth-slice";
import { useAuthloginMutation } from "../services/auth-api";
import { saveTokenState } from "../../../utils/local-storage";
import "../styles/style.scss";

export function ResetPasswordPage() {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const onFinish = async (values) => {
        console.log(values);
        navigate("/dashboard");
    };


    return (
        <div className="auth" data-aos="fade-in">
            <div className="login-page">
                <a href="https://kliner.com">
                    <img src="/assets/img/logo-black-small.png" alt="kliner" className="login-logo-topleft" />
                </a>                <div className="blc-content">
                <h1>Définir un nouveau mot de passe</h1>
                <p></p>
                <Form
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical">
                    <label>Nouveau mot de passe</label>
                    <Form.Item name="password" rules={[{ required: true, message: "Champs requis!" }]}>
                        <Input.Password placeholder="Saisir votre mot de passe" />
                    </Form.Item>
                    <label>Confirmer le mot de passe</label>
                    <Form.Item name="password-bis" rules={[{ required: true, message: "Champs requis!" }]}>
                        <Input.Password placeholder="Saisir à nouveau" />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-lg"
                            loading={isLoading}
                            style={{ fontWeight: "600" }}
                        >
                            Enregistrer

                        </Button>
                    </Form.Item>
                </Form>
                {error && <Alert message={error} type="error" closable />}
            </div>
            </div>
        </div>
    );
}

export default ResetPasswordPage;
