import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const dashboardEntretienApi = createApi({
    reducerPath: "dashboardEntretienApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["DashboardEntretien"],
    endpoints: (builder) => ({
        equipeEntretienFetch: builder.query({
            query: (payload) => `/api/v1/prestation-quality-control/to-do?page=${payload.id}&pageSize=${payload.page}&order=${payload.order}`,
            providesTags: ["EquipeEntretien"],
        }),
        ratingPrestation: builder.mutation({
            query: (payload) => ({
                url: "/api/v1/prestation-quality-control",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["EquipeEntretien"],
        }),
    }),
});

export const {
    useEquipeEntretienFetchQuery,
    useRatingPrestationMutation,
} = dashboardEntretienApi;
