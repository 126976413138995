import { createSlice } from "@reduxjs/toolkit";

export const logementSlice = createSlice({
  name: "auth",
  initialState: {
    user: "",
    type: "",
    isRefresh: false,
  },
  reducers: {
    // fetch: (state, { payload }) => {
    //   state.logements = payload.logements;
    //   state.isRefresh = payload.isRefresh;
    //   return state;
    // },

    setRefresh: (state, { payload }) => {
      state.isRefresh = payload;
      return state;
    },

    setUser: (state, { payload }) => {
      state.user = payload;
      return state;
    },

    checkPage: (state, { payload }) => {
      state.type = payload;
      return state;
    },
  },
});

export const { setRefresh, setToken, setUser, logout, checkPage } = logementSlice.actions;
export default logementSlice.reducer;
