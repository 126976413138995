import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const statusColorApi = createApi({
    reducerPath: "statusColorApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["statusColor"],
    endpoints: (builder) => ({
        statusColorsFetch: builder.query({
            query: (ids) => `/api/v1/logement/status-colors?logementIds=${ids}`,
            providesTags: ["statusColor"],
        }),

    }),
});

export const {
    useStatusColorsFetchQuery
} = statusColorApi;
