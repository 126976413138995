import React, {useEffect, useState} from "react";
import {Modal, Divider, Row, Col, Spin, Input, Select, message} from "antd";
import { useLogementAssignmentMutation} from "../services/logement-api";
import {SearchOutlined} from "@ant-design/icons";
import {useEquipeEntretienFetchMutation} from "../../equipes/services/equipe-entretien-api";

const AssignerAideEquipeLogementModalView = ({ status, logementId, logementIds, nbLogement, handleCancel }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState(null);
    const [equipeCreate] = useLogementAssignmentMutation();
    const [listAideMenagere, setListAideMenagere] = useState([]);
    const [equipeEntretienFetch] = useEquipeEntretienFetchMutation();
    useEffect(() => {
        getEquipeEntretienAide();
    }, [logementId]);
    const handleChange = (value) => {
        setSelectedValue(value);
    };
    const getEquipeEntretienAide = () => {
        const data = ({ pageView: "AIDE_MENAGERE", status: "ACTIVE" })
        setIsLoading(true);
        equipeEntretienFetch(data)
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                setListAideMenagere(res.data.aidemenageres);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };
    const handleDemandeEquipe = () => {
        setIsLoading(true)
        equipeCreate({ logement: logementId, aidemenagere: selectedValue}).unwrap()
            .then(() => { handleCancel(); setIsLoading(false); message.success("Assignation en cours.") })
            .catch((error) => {
                setIsLoading(false);
                message.error(error.data.message);
            });
    }

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={570}
            className="suppressionLogementNotPossible">

            <Spin spinning={isLoading}>
                <div className="supprimer assigner">
                    <div className="title sans-pro-semi-bold"><img src="/assets/img/launch-icon.png" alt="airbnb" className="title-img" />
                        <div className="title-label">Assigner une aide-ménagère</div>
                    </div>
                    <Row>
                        <Col>
                            <br/>
                            <span>
                                Si une aide-ménagère est déjà assignée sur le logement, elle sera remplacée par la <br/>
                                nouvelle.

                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={20}>
                            <br/>
                            <Select
                                className='search-input'
                                placeholder="Cliquer dans le champ pour afficher la liste"
                                prefix={<SearchOutlined className="site-form-item-icon"/>}
                                suffixIcon={<></>}
                                onChange={handleChange}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                showSearch
                                options={[
                                    ...listAideMenagere.map((responsable) => ({
                                        key: "responsable_"+responsable?.id,
                                        value: responsable?.id,
                                        label: `${responsable?.firstname} ${responsable?.lastname}`,
                                    })),
                                ]}
                            />
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={14} className="button-left">
                                <a href="#" onClick={() => {
                                    handleCancel();
                                }} className="annuler"><span> Annuler la demande</span></a>
                            </Col>
                            <Col lg={10} className="button-right">
                                <a href="#" onClick={() => handleDemandeEquipe()}
                                   className="suivant sans-pro-regular">Valider</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default AssignerAideEquipeLogementModalView;
