import React, { useEffect, useState } from 'react';
import "./styles/style.scss"
import {Avatar, Col, message, Popover, Row} from "antd";
import {CalendarFilled, CheckCircleFilled, CheckCircleOutlined, CheckOutlined, StarFilled} from "@ant-design/icons";
import {useNoteAddStatusMutation, useNoteFetchOneStatusMutation, useNoteFetchQuery} from "./services/note-api";
import {AiOutlineCalendar} from "react-icons/ai";
import {API_ROOT, convertDateStringToTimeStringDateOnly} from "../../utils/global-var";
import moment from "moment/moment";
import Calendar from "react-calendar";

function NotesPage() {
    const [dateFilter, setDateFilter] = useState(String(new Date().getFullYear()) +'-'+String(new Date().getMonth() + 1).padStart(2, '0')+'-'+String(new Date().getDate()).padStart(2, '0'));
    const [dateFilterLetter, setDateFilterLetter] = useState(new Date().toLocaleDateString('fr-FR', { weekday: 'long' }).charAt(0)
        .toUpperCase() + new Date().toLocaleDateString('fr-FR', { weekday: 'long' }).slice(1)+""+convertDateStringToTimeStringDateOnly(dateFilter));
    const noteListByDate = useNoteFetchQuery({date : dateFilter});
    const [noteStatus] = useNoteFetchOneStatusMutation();
    const [addNoteStatus] = useNoteAddStatusMutation();
    const [dataList, setDtataList] = useState([]);
    const [dataListStatus, setDtataListStatus] = useState([]);
    const [dataListSelected, setDtataListSelected] = useState(null);
    const [actionList, setActionList] = useState([]);
    //console.log(noteListByDate);
    //console.log(noteListByDate?.data && noteListByDate?.data.data.length > 0);
    //console.log(dataList);
    console.log(dataListStatus);
    const handleChangeDate = (value) => {
        //console.log(value.toString());
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // Utiliser moment pour formater la date en fonction du fuseau horaire de l'utilisateur
        const userDate = moment(value, userTimeZone).format("YYYY-MM-DD");
        //console.log(userTimeZone);
        //console.log(userDate);
        const date = new Date(userDate.toString());
        const jours = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
        const mois = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
        const jourSemaine = jours[date.getUTCDay()];
        const jour = date.getUTCDate();
        const moisAnnee = mois[date.getUTCMonth()];
        const annee = date.getUTCFullYear();
        const formattedDate = `${jourSemaine} ${jour} ${moisAnnee} ${annee}`;
        //console.log(value.toISOString());
        setDateFilter(value.toISOString().split('T')[0]);
        setDateFilterLetter(formattedDate);
        setOpen(false);
    };

    useEffect(() => {

    }, [dataListStatus,dataListSelected]);
    const handleGetStatusNote = (data) =>{
        noteStatus(data).unwrap(data)
            .then((res) => {
                setDtataListStatus(res);
            })
            .catch((error) => {
                message.error(`Erreur: ${error.toString()}`);
            });
    }
    const handleAddStatusNote = (data) =>{
       // console.log(data);
        addNoteStatus(data).unwrap(data)
            .then((res) => {
                //console.log(res);
                handleGetStatusNote(dataListSelected["id"]);
            })
            .catch((error) => {
                message.error(`Erreur: ${error.toString()}`);
            });
    }
    const [open, setOpen] = useState(false);
    const handleOpenChange = (newOpen) => setOpen(newOpen);
    const content = (<Calendar onChange={handleChangeDate} value={dateFilter} />);

    useEffect(() => {
            if(noteListByDate?.data && noteListByDate?.data?.data.length > 0){
                setDtataList(noteListByDate?.data.data);
            }else{
                setDtataList([]);
            }
    }, [noteListByDate, dateFilter, dateFilterLetter]);


    return (
        <div className='notes-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Notes & commentaires</h1>
                </div>
                <div className="dateChange">
                    <div className="libelle">Prestations du </div>
                    <div className="icone"><img src="/assets/img/calendar.png" style={{width:"18px"}} alt=""/> </div>
                    <div className="dateSelected">
                        <Popover content={content} trigger="click" open={open} onOpenChange={handleOpenChange}>
                            {dateFilterLetter} <div className='icon-logement-select'><img
                            src="../assets/img/arrow-down-gray.png" alt="add"/></div>
                        </Popover>
                    </div>
                </div>
            </div>
            <div className="content-note">
                <Row>
                    <Col lg={15}>
                        {  dataList.length > 0 ?
                            dataList.map((item, index)=> (
                                <div key={"item_"+item["id"]} className={"comment "+(dataListSelected !== null && dataListSelected["id"] === item["id"] && parseInt(item["quality_control"]["customer_stars"]) !== 5 ? " active-comment" : "")} onClick={() => {
                                    if(parseInt(item["quality_control"]["customer_stars"]) !== 5) {
                                        setDtataListSelected(item);
                                        handleGetStatusNote(item["id"]);
                                    }else {
                                        setDtataListStatus(null);
                                        setDtataListSelected(null);
                                    }
                                    setActionList([]);
                                }}>
                                    <Row>
                                        <Col lg={14}>
                                            <div className="identity">
                                                <div className="image">
                                                    <Avatar size={40}  src={`${API_ROOT}/${item["user"]["avatar"]}`} />
                                                </div>
                                                <div className="infos">
                                                    <div className="name"> {item["user"]["lastname"]} {item["user"]["firstname"]}</div>
                                                    <div className="logement"> Logement {item["logement"]["libelle"]}</div>
                                                </div>
                                            </div>
                                            <div className="pointtocorrect">
                                                <div className="title">
                                                    Points à améliorer
                                                </div>
                                                {item["quality_control"]["customer_comment"]?.split("#")[0].length > 0 && item["quality_control"]["customer_comment"]?.split("#")[0]?.split(",").map((item, index)=> ( <div className="element" key={"element-point-a-ameliorer-"+index}>
                                                    {item}
                                                    </div>))}
                                            </div>
                                        </Col>
                                        <Col lg={10}>
                                            <div className="start-note">
                                                <StarFilled style={{marginRight:"5px",color: parseInt(item["quality_control"]["customer_stars"]) >= 1 ? "rgba(249,195,0,1)" : "rgba(0,0,0,0.2)" }} />
                                                <StarFilled style={{marginRight:"5px",color: parseInt(item["quality_control"]["customer_stars"]) >= 2 ? "rgba(249,195,0,1)" : "rgba(0,0,0,0.2)"}} />
                                                <StarFilled style={{marginRight:"5px",color: parseInt(item["quality_control"]["customer_stars"]) >= 3 ? "rgba(249,195,0,1)" : "rgba(0,0,0,0.2)"}} />
                                                <StarFilled style={{marginRight:"5px",color: parseInt(item["quality_control"]["customer_stars"]) >= 4 ? "rgba(249,195,0,1)" : "rgba(0,0,0,0.2)"}} />
                                                <StarFilled style={{marginRight:"5px",color: parseInt(item["quality_control"]["customer_stars"]) >= 5 ? "rgba(249,195,0,1)" : "rgba(0,0,0,0.2)"}} />
                                                {parseInt(item["quality_control"]["customer_stars"]) !== 5 && item["action"]["actionStatus"] === "A Traiter" && <div style={{color:"red", fontSize:"12px", marginTop:"15px", paddingRight:"20px", marginBottom:"-30px"}}>A traiter</div>}
                                                {parseInt(item["quality_control"]["customer_stars"]) !== 5 && item["action"]["actionStatus"] === "Plan d ’action s en cours" && <div style={{color:"#ff7f0a", fontSize:"12px", marginTop:"15px", paddingRight:"20px", marginBottom:"-30px"}}>A traiter</div>}
                                                {parseInt(item["quality_control"]["customer_stars"]) !== 5 && item["action"]["actionStatus"] === "Traité" && <div style={{color:"green", fontSize:"12px", marginTop:"15px", paddingRight:"20px", marginBottom:"-30px"}}>{item["action"]["actionStatus"]}</div>}
                                            </div>
                                            <div className="commentary">
                                                <div className="title">Commentaire</div>
                                                <div className="content-commentary">
                                                    { item["quality_control"]["customer_comment"]?.split("#")[1].length > 0 && <div>{item["quality_control"]["customer_comment"]?.split("#")[1]}</div>}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ))
                            : <div style={{color:"grey", fontSize:"13px"}}>Aucun avis pour la date du {dateFilterLetter}</div>
                        }

                    </Col>
                    <Col lg={1} >
                        <div className="line"></div>
                    </Col>
                    <Col lg={8}>
                        <div className="action">
                            {dataListSelected !== null ?
                                <div className="action-option">
                                    <div className="title">
                                        Compléter le plan d'actions
                                    </div>
                                    <div className="subtitle">
                                        Cocher chaque étape lorsqu’elle a été réellement
                                        effectuée
                                    </div>
                                    <div className="list-action">
                                        {dataListStatus !== null && dataListStatus?.bulletPoints?.map((item, index) => (
                                                <div className="element-action" key={"element_to_treat_"+index} onClick={() => handleAddStatusNote({action: item["id"], prestation: dataListSelected["id"] })}>
                                                    <div className="image">
                                                        { !item["isDone"] ? <CheckCircleOutlined style={{fontSize:"30px", color:"rgba(0,0,0,0.07)"}} /> :  <CheckCircleFilled style={{fontSize:"30px"}} />}
                                                    </div>
                                                    <div className="content-action">
                                                        <div className="title-action">{item["name"]}</div>
                                                        <div className="subtitle-action">{item["description"]}</div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                            </div>:<div className="message">
                                Sélectionner un commentaire pour le traiter
                            </div>}
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    );
}

export default NotesPage;
