import React, { useState } from "react";
import { Modal, Divider, Row, Col, Spin } from "antd";
import { useLogementDeleteMutation } from "../services/logement-api";
import { useDispatch } from "react-redux";
import { setRefresh } from "../services/logement-slice";

const SupprimerLogementModalView = ({ logementId, logementIds, status, nbLogement, handleShowNotPossibleModal, handleCancel }) => {
    const [logementDelete] = useLogementDeleteMutation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = () => {
        setIsLoading(true)
        const data = ({ id: logementId })
        logementDelete(data).unwrap()
            .then(() => {
                dispatch(setRefresh(true));
                handleCancel();
                setIsLoading(false)
            })
            .catch(() => {
                handleCancel();
                handleShowNotPossibleModal(true);
                setIsLoading(false)
            });
    };


    const handleDeleteMultiple = () => {
        setIsLoading(true)
        const deletePromises = [];

        logementIds.forEach(logementId => {
            const data = { id: logementId };
            const deletePromise = logementDelete(data).unwrap()
                .catch(() => console.error(`Failed to delete logement with ID ${logementId}`));
            deletePromises.push(deletePromise);
        });

        // Attendre que toutes les suppressions soient terminées
        Promise.all(deletePromises)
            .then(() => {
                dispatch(setRefresh(true));
                handleCancel();
                setIsLoading(false);
            })
            .catch(() => {
                handleCancel();
                handleShowNotPossibleModal(true);
                setIsLoading(false);
            });
    };

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={600}
            className="suppressionLogement">

            <Spin spinning={isLoading}>
                <div className="supprimer">
                    <div className="title sans-pro-semi-bold"><img src="/assets/img/warning-icon.png" alt="airbnb" className="title-img" /><div className="title-label">Êtes-vous sûr de vouloir supprimer ces logements ({nbLogement}) ?
                    </div></div>
                    <Row>
                        <Col>
                            <span>
                                La suppression d’un logement est une opération irréversible.
                            </span>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={12} className="button-left">
                                <a href="#" onClick={() => handleCancel()} className="annuler"><span> Non, garder ces logements</span></a>
                            </Col>
                            <Col lg={11} className="button-right">
                                <a href="#" onClick={() => (logementIds.length > 0 ? handleDeleteMultiple() : handleDelete())}
                                    className="suivant sans-pro-regular">Oui, supprimer définitivement</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default SupprimerLogementModalView;
