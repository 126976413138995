import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover, Space, Radio, Spin, message, Form, Button
} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";

import {useSelector} from "react-redux";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { useMajoratePricingsMutation } from "../services/logement-api";

const ModifierForfaitView = ({status, logement,handleCancel}) => {
    const [form] = Form.useForm();
    const [majoratePricings] = useMajoratePricingsMutation();
    const [newMajoration, setNewMajoration] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const majorateLogementPricings = () => {
        majoratePricings({logementId: logement?.id, data: newMajoration})
        .unwrap()
        .then((res) => {
            setIsLoading(false);
            handleCancel()
        })
        .catch((error) => {
            setError(error.data.message);
            setIsLoading(false);
        });
    }

    const updateNewMajoration = (field, value) => {
        newMajoration[field] = value;
    }

    useEffect(() => {
        if(logement) {
            form.setFieldsValue({
                "laundryDurationMajorationPercentage": logement?.cout_duree?.laundry_duration_majoration_percentage || 0.00,
                "laundryHourCostMajorationPercentage": logement?.cout_duree?.laundry_hourly_cost_majoration_percentage || 0.00,
            });
        }

    }, [logement, form]);


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 250}}
            closable={true}
            onCancel={() => {
                handleCancel();
            }}
            width={600}
            className="editer-forfait"
        >

            <div className="informations">
                <div className="contentIfon">
                    <div className="title">
                        <div className="text sans-pro-semi-bold">
                            Modification du forfait par service
                        </div>
                        <p>
                            Indiquer le taux d’ajustement pour ce logement. La rémunération du partenaire sera impactée proportionnellement.
                        </p>
                    </div>
                    <Form
                        name="basic"
                        form={form}
                        onFinish={null}
                        autoComplete="off"
                        layout="vertical"
                        className="form-edit-profil"
                    >
                        <Row>
                            <Col lg={8}>
                                <label htmlFor="prenom" className={"force-label-effet "}>Evolution duréee forfaitaire</label>
                                <Form.Item
                                    name="laundryDurationMajorationPercentage"
                                    className="input-barrier"
                                    rules={[{required: true, message: "Champs requis!"}]}>
                                    <Input id="prenom" placeholder=""   addonAfter="%" onChange={(e)=>updateNewMajoration("laundryDurationMajorationPercentage", e.target.value)}/>
                                </Form.Item>
                            </Col>
                            <Col lg={5}></Col>
                            <Col lg={10}>
                                <label htmlFor="nom" className={"force-label-effet "}>Évolution coût horaire du ménage</label>
                                <Form.Item
                                    name="laundryHourCostMajorationPercentage"
                                    className="input-barrier"
                                    rules={[{required: true, message: "Champs requis!"}]}>
                                    <Input id="nom" placeholder=""   addonAfter="%" onChange={(e)=>updateNewMajoration("laundryHourCostMajorationPercentage", e.target.value)}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <br/>
                    </Form>
                </div>
                <div className="footer-modal">
                    <Divider/>
                    <Row>
                        <Col lg={19} className="button-left">
                            <a href="#" onClick={() => {
                                handleCancel();
                            }} className="annuler"><span> Annuler la modification</span></a>
                        </Col>
                        <Col lg={5} className="button-right">
                            {/* <a h onClick={() => {
                                majorateLogementPricings();
                            }}
                               className="suivant sans-pro-regular">Enregistrer</a> */}
                            <Button type={"primary"} onClick={()=>majorateLogementPricings()} loading={isLoading} >Enregistrer</Button>
                        </Col>
                    </Row>
                </div>
            </div>


        </Modal>
    );
};
export default ModifierForfaitView;
