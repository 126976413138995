import React from 'react';
import "../../styles/style.scss"

function BasicSAPClePage() {
    return (
        <>
            <div className="title-label sans-pro-semi-bold">Comment ça marche ?</div>
            <div className="liste gray-zone">
                <div className="point-label">
                    <div className="point">
                        <img src="/assets/img/light-colored.png" alt="done"/>
                    </div>
                    <div className="label sans-pro-semi-bold">
                        Plus sur le service d’appoint
                    </div>
                </div>
                <div className="trait-explication">
                    <div className="trait trait-invisible"></div>
                    <div className="explication">
                        L’option ‘Pose d’une boîte à clés’ du service d’appoint vous permet de demander à un <br/>
                        prestataire de poser une boîte à clés où vous souhaitez. Lors de la commande vous <br/>
                        êtes facturé du coût forfaitaire de la main d’œuvre. Une fois un prestataire assigné, <br/>
                        vous devrez lui transmettre les instructions d’achat de boite à clés et de pose, puis lui <br/>
                        <a href="#">verser le montant nécessaire</a> à l’achat de la boite à clés souhaitée.
                    </div>
                </div>
            </div>
        </>
    );
}

export default BasicSAPClePage;
