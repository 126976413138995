import React, {useEffect, useState} from 'react';
import "../styles/style.scss"
import {Avatar, Button, Col, Divider, Dropdown, Menu, Row, Tabs} from "antd";
import {IoEllipsisVertical} from "react-icons/io5";
import DocumentEquipeModal from "../modal/facture";
import ModifierInformationModalView from "../modal/modifier-information";
import {API_ROOT} from "../../../utils/global-var";
import {NavLink} from "react-router-dom";
import {usePaiementclientFetchQuery} from "../../clients/services/paiement-client";
import {useSuscriberPlanFetchOneQuery, useSuscriberPlanUserFetchOneQuery} from "../services/suscribe-plan-api";
import {useInfoUserFetchOneQuery} from "../services/indo-user-api";
import {useEquipeEntretienStatusFetchOneQuery} from "../services/equipe-entretien-api";
import PlanningFilteredPage from "./modal/prestation-filtered-modal";

const EquipeDrawerPage = ({equipe, setRequest, request}) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalModifier, setShowModalModifier] = useState(false);
    const [showModalPrestation, setShowModalPrestation] = useState(false);
    const [statusPrestation, setStatusPrestation] = useState("");
    const moyenPaiement = usePaiementclientFetchQuery({id: equipe.id});
    const suscriberPlan = useSuscriberPlanFetchOneQuery();
    const userIfon = useInfoUserFetchOneQuery(equipe.id);
    const suscriberPlanUser = useSuscriberPlanUserFetchOneQuery(equipe.id);
    const suscriberPlanUserStatus = useEquipeEntretienStatusFetchOneQuery(equipe.id);
    useEffect(() => {
        setRequest({...request, users: [equipe.id]})
    }, [equipe.id]);
    useEffect(() => {

    }, [equipe.id]);
    const onChange = (key) => {
        console.log(key);
    };
    //console.log("equipe");
    console.log(userIfon);
    console.log(moyenPaiement);
    //console.log(suscriberPlanUserStatus);
    //console.log(suscriberPlan);
    const getMenuItemsInfo = (record) => [
        {
            label:
                <div className="add-logement3-item sans-pro-regular" onClick={() => setShowModalModifier(true)}>
                    Modifier le profil
                </div>,
            key: '1itemsOptionInfo',
        },
    ];

    //console.log(equipe);
    //console.log(moyenPaiement);

    const menuCle = (
        <Menu>
            <Menu.Item key="email">
                <p>
                    {userIfon?.data?.email ?? "Adresse mail manquante."}
                </p>
            </Menu.Item>
        </Menu>
    );

    const menuNumber = (
        <Menu>
            <Menu.Item key="email">
                <p>
                    {equipe.phone ?? "Numéro de téléphone manquant."}
                </p>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="record-client">
            <div className="head">
                <div className="type">
                    <div className="icone">
                        <img src="./assets/img/sac.png" alt="icone"/>
                    </div>
                    <div className="text sans-pro-semi-bold">
                        Adoption
                    </div>
                </div>
            </div>
            <div className="photo">
                <Avatar src={`${API_ROOT}/${equipe.avatar}`}/>
                {userIfon?.data?.is_formation_taken !== null && userIfon?.data?.is_formation_taken !== "" && userIfon?.data?.is_formation_taken !== false &&
                    <img src="./assets/img/forme_icon.png"
                         style={{width: "50px", marginTop: "50px", verticalAlign: "top"}} alt="formé"/>}
            </div>
            <div className="content" style={{paddingBottom: "0"}}>
                <div className="info">
                    <div className="name">
                        <div className="nom sans-pro-semi-bold">{equipe?.lastname} {equipe?.firstname} </div>
                        <div className="sigle">{userIfon?.data?.role === "auto-entrepreneur" ? "AE" : "PME"}</div>
                        <div className="menu"></div>
                        <div className="pays sans-pro-regular"><span
                            style={{color: "black"}}>{equipe?.entreprise}</span>, Paris, France
                        </div>
                        <div className="menu">
                            <Dropdown
                                overlay={<Menu items={getMenuItemsInfo(equipe)}/>}
                                placement="bottomLeft" trigger={['click']}>
                                <IoEllipsisVertical/>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="info-supp">
                        <Dropdown
                            overlay={menuCle}
                            placement="topLeft"
                            arrow
                        >
                            <div className="icone-enveloppe">
                                <NavLink to={"mailto:" + userIfon?.data?.email}>
                                    <img src="./assets/img/mail-subscription - Modifié.png" alt="icon"/>
                                </NavLink>
                            </div>
                        </Dropdown>
                        <Dropdown
                            overlay={menuNumber}
                            placement="topLeft"
                            arrow
                        >
                            <div className="icone-call">
                                <NavLink to={"tel:" + equipe.phone}>
                                    <img src="./assets/img/phone icon - Modifié.png" alt="icon"/>
                                </NavLink>
                            </div>
                        </Dropdown>
                        {userIfon?.data?.ibanAccount === null && <div className="paiement">
                            <div className="icone-paiement">
                                <img src="./assets/img/empty-payment-icon.png" alt="icon"/>
                            </div>
                            <div className="text">
                                Aucun moyen
                            </div>
                            <div className="icone-close">
                                <img src="./assets/img/no card icon.png" alt="icon"/>
                            </div>
                        </div>}
                        {userIfon?.data?.ibanAccount !== null && <div className="paiement">
                            <div className="icone-paiement">
                                <img src="./assets/img/sepa-icon.png" alt="icon"/>
                            </div>
                            <div className="text">
                                **** {userIfon?.data?.ibanAccount.replace(/\s+/g, '').slice(-4)}
                            </div>
                            <div className="icone-close">
                                <img src="./assets/img/green-check.png" alt="icon"/>
                            </div>
                        </div>}
                        {/*userIfon?.data?.ibanAccount !== null && <div className="paiement">
                            <div className="icone-paiement">
                                <img
                                    src={/*moyenPaiement?.data?.card?.brand === "visa" ? "./assets/img/visa icon.webp" : moyenPaiement?.data?.card?.brand === "visa" ? "./assets/img/mastercard icon.webp" : "./assets/img/amex icon.png"}
                                    alt="icon"/>
                            </div>
                            <div className="text">
                                **** {userIfon?.data?.ibanAccount.replace(/\s+/g, '').slice(-4)}
                            </div>
                            <div className="icone-close">
                                <img src="./assets/img/green-check.png" alt="icon"/>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
            <Divider/>
            <div className="content" style={{paddingTop: "0"}}>
                <div className="info">
                    <div className="logement-client">
                        <div className="titre">
                            <div className="text sans-pro-semi-bold"> Activité</div>
                        </div>
                        <div className="contenu">
                            <div className="stats">
                                <div className="prestations">
                                    <div
                                        className="number sans-pro-semi-bold">{suscriberPlanUserStatus?.data?.prestation?.nb_prestations_total ?? (parseInt(suscriberPlanUserStatus?.data?.prestation?.nb_prestations_finies ?? "0") + parseInt(suscriberPlanUserStatus?.data?.prestation?.nb_prestations_programmees ?? "0") + parseInt(suscriberPlanUserStatus?.data?.prestation?.nb_prestations_en_cours ?? "0") + parseInt(suscriberPlanUserStatus?.data?.prestation?.nb_prestations_annules ?? "0"))}</div>
                                    <div className="label sans-pro-regular">Prestations</div>
                                </div>
                                <div className="traitStat">

                                </div>
                                <div className="percent">
                                    <div
                                        className="number sans-pro-semi-bold">{parseInt(suscriberPlanUserStatus?.data?.prestation?.performance_percentage ?? "0")}% <div
                                        className="icon"><img src="./assets/img/pousse.png" alt="pousse"/></div></div>
                                    <div className="label sans-pro-regular">Taux de performance</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="logement-client">
                        <div className="titre">
                            <div className="text sans-pro-semi-bold"> Aides ménagères</div>
                            <div className="indice">({equipe?.nb_aide_menagere})</div>
                        </div>
                        <div className="contenu">
                            <div className="element">
                                <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/clients icon green.png" alt="icone"/>
                                    </div>
                                </div>
                                <div className="label">Actives (4)</div>
                            </div>
                            <div className="element">
                                <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/clients icon orange.png" alt="icone"/>
                                    </div>
                                </div>
                                <div className="label">Inactives (2)</div>
                            </div>
                        </div>
                    </div>
                    <div className="logement-client">
                        <div className="titre">
                            <div className="text sans-pro-semi-bold"> Logements gérés</div>
                        </div>
                        <div className="contenu">
                            <div className="element">
                                <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/Logements icon - Modifié - Modifié.png" alt="icone"/>
                                    </div>
                                </div>
                                <div className="label">Actifs (4)</div>
                            </div>
                            <div className="element">
                                <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/Logements icon - Modifié - Modifié (1).png" alt="icone"/>
                                    </div>
                                </div>
                                <div className="label">Inactifs (2)</div>
                            </div>
                        </div>
                    </div>
                    <div className="logement-client">
                        <div className="titre">
                            <div className="text sans-pro-semi-bold"> Prestations</div>
                        </div>
                        <div className="contenu">
                            <div className="element" onClick={() => {
                                setShowModalPrestation(true);
                                setStatusPrestation("Terminé");
                            }}>
                                <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/Play Icon - Modifié (1).png" alt="icone"/>
                                    </div>
                                </div>
                                <div className="label">Terminé
                                    ({suscriberPlanUserStatus?.data?.prestation?.nb_prestations_finies ?? 0})
                                </div>
                            </div>
                            <div className="element" onClick={() => {
                                setShowModalPrestation(true);
                                setStatusPrestation("En cours");
                            }}>
                                <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/Play Icon - Modifié.png" alt="icone"/>
                                    </div>
                                </div>
                                <div className="label">En cours
                                    ({suscriberPlanUserStatus?.data?.prestation?.nb_prestations_en_cours ?? 0})
                                </div>
                            </div>
                            <div className="element" onClick={() => {
                                setShowModalPrestation(true);
                                setStatusPrestation("Programmé");
                            }}>
                                <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/Play Icon - Modifié (2).png" alt="icone"/>
                                    </div>
                                </div>
                                <div className="label">Programmé
                                    ({suscriberPlanUserStatus?.data?.prestation?.nb_prestations_programmees ?? 0})
                                </div>
                            </div>
                            <div className="element" onClick={() => {
                                setShowModalPrestation(true);
                                setStatusPrestation("Annulé");
                            }}>
                                <div className="Himage">
                                    <div className="image">
                                        <img src="./assets/img/presta del icon.png" alt="icone"/>
                                    </div>
                                </div>
                                <div className="label">Annulé
                                    ({suscriberPlanUserStatus?.data?.prestation?.nb_prestations_annules ?? 0})
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Divider/>
            <div className="content">
                <div className="info">
                    <Row>
                        <Col lg={11}>
                            <div className="score">
                                <div className="titre">
                                    <div className="text sans-pro-semi-bold"> Facturation</div>
                                </div>
                                <Button onClick={() => setShowModal(true)}>
                                    <div className="icone-button">
                                        <img src="./assets/img/euro icon - Modifié.png" alt="icon"/>
                                    </div>
                                    Factures
                                </Button>
                            </div>
                        </Col>
                        <Col lg={2}>
                            <div className="verticalDivider">

                            </div>
                        </Col>
                        <Col lg={11}>
                            <div className="score">
                                <div className="titre">
                                    <div className="text sans-pro-semi-bold"> Conversations</div>
                                </div>
                                <Button>
                                    <div className="icone-button">
                                        <img src="./assets/img/intercom logo - Modifié.png" alt="icon"/>
                                    </div>
                                    Voir dans intercom
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <DocumentEquipeModal
                status={showModal}
                record={equipe}
                handleCancel={() => setShowModal(false)}
            />
            <ModifierInformationModalView
                key={suscriberPlanUser?.data}
                record={equipe}
                status={showModalModifier}
                suscriberPlan={suscriberPlan?.data}
                suscriberPlanUser={suscriberPlanUser?.data}
                userIfon={userIfon}
                handleCancel={() => setShowModalModifier(false)}
            />
            <PlanningFilteredPage
                status={showModalPrestation}
                userId={equipe.id}
                userInfo={equipe}
                statusPrestation={statusPrestation}
                handleCancel={() => setShowModalPrestation(false)}
            />
        </div>
    );
}

export default EquipeDrawerPage;
