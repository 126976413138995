import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const logApi = createApi({
    reducerPath: "logApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Log"],
    endpoints: (builder) => ({
        logFetch: builder.query({
            query: (payload) => `/api/v1/events/by-items?service=${payload.service}&page=${payload.page}&pageSize=${payload.pagesize}&order=${payload.order}&item=${payload.item}`,
            providesTags: ["Log"],
        }),
    }),
});

export const {
    useLogFetchQuery,
} = logApi;
