import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const noteApi = createApi({
    reducerPath: "noteApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Note", "NoteStatus"],
    endpoints: (builder) => ({
        noteFetch: builder.query({
            query: (payload) => `/api/v1/prestation-quality-control/review?start_date=${payload.date}`,
            providesTags: ["Note"],
        }),
        noteFetchOneStatus: builder.mutation({
            query: (payload) => ({
                url: `/api/v1/prestation-quality-control/prestation/action/${payload}`,
                method: "GET",
            }),
            providesTags: ["NoteStatus"],
        }),
        noteAddStatus: builder.mutation({
            query: (payload) => ({
                url: `/api/v1/prestation-quality-control/prestation/action/`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["NoteStatus"],
        }),
    }),
});

export const {
    useNoteFetchQuery,
    useNoteFetchOneStatusMutation,
    useNoteAddStatusMutation,
} = noteApi;
