import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover, Space, Radio, Spin, message, Form
} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";

import {useSelector} from "react-redux";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

const ProfilModalView = ({status, handleCancel}) => {
    const [form] = Form.useForm();


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 250}}
            closable={true}
            onCancel={() => {
                handleCancel();
            }}
            width={750}
            className="editer-information"
        >

            <div className="informations">
                <div className="contentIfon">
                    <div className="title">
                        <div className="icone">
                            <img src="./assets/img/data-icon.png" alt="image"/>
                        </div>
                        <div className="text sans-pro-semi-bold">
                            Informations personnelles
                        </div>
                    </div>
                    <Form
                        name="basic"
                        form={form}
                        onFinish={null}
                        autoComplete="off"
                        layout="vertical"
                        className="form-edit-profil"
                    >
                        <Row>
                            <Col lg={11}>
                                <Form.Item
                                    name="prenom"
                                    className="input-barrier"
                                    rules={[{required: true, message: "Champs requis!"}]}>
                                    <label htmlFor="prenom" className={"force-label-effet color-5B6E8C"}>Prénom *</label>
                                    <Input id="prenom" placeholder=""  value="Brice"/>
                                </Form.Item>
                            </Col>
                            <Col lg={2}></Col>
                            <Col lg={11}>
                                <Form.Item
                                    name="nom"
                                    className="input-barrier"
                                    rules={[{required: true, message: "Champs requis!"}]}>
                                    <label htmlFor="nom" className={"force-label-effet color-5B6E8C"}>Nom *</label>
                                    <Input id="nom" placeholder=""  value="WIlliam"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={11}>
                                <Form.Item
                                    name="email"
                                    className="input-barrier"
                                    rules={[{required: true, message: "Champs requis!"}]}>
                                    <label htmlFor="email" className={"force-label-effet color-5B6E8C"}>Email *</label>
                                    <Input id="email" placeholder=""  value="brice.william@gmail.com"/>
                                </Form.Item>
                            </Col>
                            <Col lg={2}></Col>
                            <Col lg={11}>
                                <Form.Item
                                    name="prenom"
                                    className="input-barrier-number"
                                    rules={[{required: true, message: "Champs requis!"}]}>
                                    <label htmlFor="prenom" className={"force-label-effet color-5B6E8C"}>Téléphone (mobile) *</label>
                                    <PhoneInput
                                        flags={flags}
                                        value={"+33 6 47 46 71 09"}
                                        defaultCountry="FR"
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="footer-modal">
                    <Divider/>
                    <Row>
                        <Col lg={19} className="button-left">
                            <a href="#" onClick={() => {
                                handleCancel();
                            }} className="annuler"><span> Annuler</span></a>
                        </Col>
                        <Col lg={5} className="button-right">
                            <a href="#" onClick={() => {
                                handleCancel();
                            }}
                               className="suivant sans-pro-regular">Mettre à jour</a>
                        </Col>
                    </Row>
                </div>
            </div>


        </Modal>
    );
};
export default ProfilModalView;
