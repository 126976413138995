import React from 'react';
import "../../styles/style.scss"

function BasicMEPPage() {
    return (
        <>
            <div className="title-label sans-pro-semi-bold">Comment ça marche ?</div>
            <div className="liste gray-zone">
                <div className="point-label">
                    <div className="point">
                        <img src="/assets/img/light-colored.png" alt="done"/>
                    </div>
                    <div className="label sans-pro-semi-bold">
                        Plus sur le ménage en profondeur
                    </div>
                </div>
                <div className="trait-explication">
                    <div className="trait trait-invisible"></div>
                    <div className="explication">
                        Le ménage en profondeur implique un nettoyage approfondi d’un logement pour le <br/>
                        préparer à la mise en location ou alors pour le remettre en l’état après une longue <br/>
                        période d’occupation. La société de nettoyage fournit les produits de nettoyage <br/>
                        adaptés pour un service de qualité.

                    </div>
                </div>
            </div>
        </>
    );
}

export default BasicMEPPage;
