import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover
} from "antd";
import {SearchOutlined} from "@ant-design/icons";

const ImpossibleSupprimerLogementModalView = ({status, nbLogement, handleCancel}) => {
    const [mydate, setMysate] = useState(null);

    const handleValid = () => {
        handleCancel();
    };


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => {
                handleCancel();
            }}
            width={600}
            className="suppressionLogementNotPossible"
        >

            <div className="supprimer">
                <div className="title sans-pro-semi-bold"><img src="/assets/img/bloc-icon.png" alt="airbnb" className="title-img"/><div className="title-label">Impossible de supprimer cette sélection
                </div></div>
                <Row>
                    <Col>
                        <span>
                            Les logements qui ont au moins une prestation programmée ou déjà <br/>
                            réalisée ne peuvent être supprimés. Veuillez modifier votre sélection.

                        </span>
                    </Col>
                </Row>
                <div className="footer-modal">
                    <Divider/>
                    <Row>
                        <Col lg={5}>
                        </Col>
                        <Col lg={10} className="button-right">
                            <a href="#" onClick={handleValid}
                               className="suivant sans-pro-regular">Ok j’ai compris</a>
                        </Col>
                    </Row>
                </div>
            </div>

        </Modal>
    );
};
export default ImpossibleSupprimerLogementModalView;
