import React, {useEffect, useState} from "react";
import {Modal, Divider, Row, Col, Spin, Input, Select, message} from "antd";
import { useLogementAssignmentMutation} from "../services/logement-api";
import {SearchOutlined} from "@ant-design/icons";
import {useEquipeEntretienFetchMutation} from "../../equipes/services/equipe-entretien-api";

const AssignerEquipeLogementModalView = ({ status, logementId, logementIds, nbLogement, handleCancel }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [listResponsable, setListResponsable] = useState([]);
    const [search, setSearch] = useState([]);
    const [equipeEntretienFetch] = useEquipeEntretienFetchMutation();
    const [equipeCreate] = useLogementAssignmentMutation();
    useEffect(() => {
        if(search.length !== 0){
            getEquipeEntretien();
        }else{
            setListResponsable([]);
        }
    }, [search]);

    useEffect(() => {
        console.log('listResponsable updated:', listResponsable);
    }, [listResponsable]);

    const handleDemandeEquipe = () => {
        setIsLoading(true)
        equipeCreate({ logement: logementId, responsable: selectedValue, status: "Offre envoyée" }).unwrap()
            .then(() => {
                handleCancel();
                setIsLoading(false);
                setListResponsable([]);
                setSelectedValue(null);
                message.success("Offre envoyée."); })
            .catch((error) => {
                setIsLoading(false);
                message.error(error.data.message);
            });
    }
    const getEquipeEntretien = () => {
        const data = ({ pageView: "RESPONSABLE", withStat: false, ...search})
        setIsLoading(true);
        equipeEntretienFetch(data)
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                setListResponsable(res.data.responsables);
            })
            .catch((error) => {
                setIsLoading(false);
                setListResponsable([]);
            });
    };


    //console.log(listResponsable);
    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 150 }}
            onCancel={() => handleCancel()}
            width={570}
            className="AssignerResponsable">

            <Spin spinning={isLoading}>
                <div className="supprimer assigner">
                    <div className="title sans-pro-semi-bold"><img src="/assets/img/launch-icon.png" alt="airbnb" className="title-img" />
                        <div className="title-label">Assigner un responsable de site</div>
                    </div>
                    <Row>
                        <Col>
                            <br/>
                            <span>
                                Si un responsable de site est déjà assigné à la prestation, il sera remplacé par le <br/>
                                nouveau et s’il y a une aide-ménagère, elle sera retirée.
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={20}>
                            <br/>
                            <Input
                                className='search-input'
                                placeholder="Recherchez et cliquez sur le responsable approprié"
                                onChange={(e) => setSearch(e.target.value !== "" ? {search: e.target.value} : [])}
                            />
                            <div className="result" style={{padding:" 10px 10px"}}>
                                {listResponsable.length > 0 ? (
                                    listResponsable.map((responsable) => (
                                        <div key={"responsable_" + (responsable?.id)} style={{ cursor: "pointer", padding:" 8px 15px", display:"inline-block", backgroundColor: selectedValue === responsable?.id ? "#ff7f0a" : "#FDF7F2", marginRight:"15px", marginBottom:"15px", borderRadius: "4px", fontSize:"15px", color: selectedValue === responsable?.id ? "white" : "black" }}>
                                            <div  onClick={() => setSelectedValue(responsable?.id)} style={{}}>
                                                {responsable?.firstname} {responsable?.lastname}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>Aucun responsables trouvés</p>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={14} className="button-left">
                                <a href="#" onClick={() => {
                                    handleCancel();
                                }} className="annuler"><span> Annuler la demande</span></a>
                            </Col>
                            <Col lg={10} className="button-right">
                                <a href="#" onClick={() => handleDemandeEquipe()}
                                   className="suivant sans-pro-regular">Valider</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default AssignerEquipeLogementModalView;
