import React, { useEffect, useState } from 'react';
import {Dropdown, Row, Col, Table, Input, Select, Badge, Button, Avatar, Modal, Divider} from 'antd';
import {
    API_ROOT,
} from "../../../../utils/global-var";
import {ArrowRightOutlined} from "@ant-design/icons";

const LogLogementPage = ({status, handleCancel, record}) => {

    const columns = [
        {
            title: 'Variable modifié',
            key: "numero",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.facture}</div>
            </div>,
        },
        {
            title: 'Ancienne valeur',
            key: "date",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.date}</div>
            </div>,
        },
        {
            title: 'Nouvelle valeur',
            key: "total",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.total}</div>
            </div>,
        },
        {
            title: 'Ancien forfait',
            key: "total",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.total}</div>
            </div>,
        },
        {
            title: 'Nouveau forfait',
            key: "total",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.total}</div>
            </div>,
        },
        {
            title: 'Utilisateur',
            key: "total",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.total}</div>
            </div>,
        },
        {
            title: 'Date',
            key: "total",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.total}</div>
            </div>,
        }
    ];

    return (
        <>
            <Modal
                open={status}
                footer={null}
                closable={true}
                style={{top:200}}
                width={1000}
                onCancel={handleCancel}
                className="instruction-client-modal scroll-none"
            >
                <div className='planning-page scroll-none'>
                    <div className='page-header'>
                        <div className='page-title'>
                            <h1>Histotique des modifications</h1>
                        </div>
                    </div>
                    <div className="instructionList">
                        <Table
                            className="log-table"
                            pagination={{pageSize: 10}}
                            dataSource={[]}
                            columns={columns}
                            rowKey={(record) => record.key}
                            locale={{
                                emptyText: (
                                    <Row>
                                        <Col lg={8}></Col>
                                        <Col lg={8}>
                                            <div className="empty-Tab-planning">
                                                {/*<img src="/assets/img/prestation.png" alt="airbnb"
                                                      className="plateforme-img"/>*/}
                                                <div className="title">Aucun log pour le moment!</div>
                                                <p>
                                            <span>

                                            </span>
                                                </p>
                                                {/*<a href="#">Plus sur notre politique d’avoirs liés à la
                                                    qualité <ArrowRightOutlined className="primary-color"/></a>*/}
                                                <br/>
                                                <br/>
                                            </div>
                                        </Col>
                                        <Col lg={8}></Col>
                                    </Row>
                                ),
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default LogLogementPage;
