import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const profilApi = createApi({
  reducerPath: "profilApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Profil"],
  endpoints: (builder) => ({
    getDefaultPaymentMethod: builder.query({
      query: () => `/api/v1/stripe/users/default-payment-method-details`,
      providesTags: ["Profil"],
    }),
  }),
});

export const {
  useGetDefaultPaymentMethodQuery,
} = profilApi;
