// Token
export const loadTokenState = () => {
  try {
    const serializedAccessToken = localStorage.getItem("access_token");
    const serializedRefreshToken = localStorage.getItem("refresh_token");
    if (!serializedAccessToken || !serializedRefreshToken) {
      return undefined;
    }

    // const boAccessToken = JSON.parse(serializedAccessToken);
    // const boRefreshToken = JSON.parse(serializedRefreshToken);
    return { boAccessToken: JSON.parse(serializedAccessToken), boRefreshToken: JSON.parse(serializedRefreshToken) };
  } catch (error) {
    console.error("Error while retrieving tokens from local storage:", error);
    return undefined;
  }
  // try {
  //   const serializedAccessToken = localStorage.getItem("access_token");
  //   const serializedRefreshToken = localStorage.getItem("refresh_token");
  //   if (serializedState === null) {
  //     return undefined;
  //   }
  //   return {JSON.parse(serializedAccessToken), JSON.parse(serializedRefreshToken)};
  // } catch (error) {
  //   return undefined;
  // }
};

export const saveTokenState = (accessToken, refreshToken) => {
  try {
    // const serializedState = JSON.stringify(accessToken, refreshToken);
    localStorage.setItem("access_token", JSON.stringify(accessToken));
    localStorage.setItem("refresh_token", JSON.stringify(refreshToken));
  } catch (error) { }
};

export const removeTokenState = () => {
  try {
    localStorage.removeItem("access_token");
  } catch (error) {
    console.log("error:", error);
  }
};
