import { API_ROOT } from "../../../utils/global-var";

const token = JSON.parse(localStorage.getItem("access_token"));

export const downloadPartnerInvoice = (userId, number) => {
    const apiUrl = API_ROOT+ `/api/v1/billing/invoices/${userId}/download-pdf`;
    fetch(apiUrl , {
        method: 'GET',
        headers: {
            'Content-Type': 'application/pdf',
            "Authorization": `Bearer ${token}`
        }
    })
    .then(response => {
        if (response.ok) {
            return response.blob();
        } else {
            throw new Error('Network response was not ok.');
        }
    })
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `facture_${number}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    })
    .catch(error => console.error('Error downloading the Invoice:', error));
}
