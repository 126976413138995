import React, {useState} from 'react';
import "../../styles/style.scss"
import {Col, Row} from "antd";
import AideMenageApercu from "../apercu/aide-menagere-apercu";
//presence_confirmee
const AideMenagePart = ({next, setAideMenagePart}) => {
    const [choix, setChoix] = useState('oui');
    return (
        <div className='AideMenagePart'>
            <Row>
                <Col lg={2}>
                    <img src="./assets/img/green-check.png" alt="icon"/>
                </Col>
                <Col lg={19}>
                    <div className="question">
                        Une aide-ménagère est-elle confirmée
                        pour cette prestation ?
                    </div>
                    <div className="decision">
                        <div className={"oui "+(choix === "oui" ? " active" : "")} onClick={()=> {
                            setChoix("oui");
                            setAideMenagePart({presence_confirmee:true});
                        }}>OUI</div>
                        <div className={"non "+(choix === "non" ? " active" : "")} onClick={()=> {
                            setChoix("non");
                            setAideMenagePart({presence_confirmee:false});
                        }}>NON</div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="link">
                        <a href="#" className={choix === "non" || choix === "" ? " disable" : ""} onClick={choix === "oui" ? next : null}>Suivant</a>
                    </div>
                </Col>
            </Row>
            <div className="apercu">
                 <AideMenageApercu choix={choix}/>
            </div>
        </div>
    );
}

export default AideMenagePart;
