import { createSlice } from "@reduxjs/toolkit";

export const planningSlice = createSlice({
  name: "planning",
  initialState: {
    isRefresh: false,
    isSuccess: false,
    isCreated: false,
  },
  reducers: {
    setCreated: (state, { payload }) => {
      state.isCreated = payload;
      return state;
    },

    setSuccess: (state, { payload }) => {
      state.isSuccess = payload;
      return state;
    },

    setRefresh: (state, { payload }) => {
      state.isRefresh = payload;
      return state;
    },
  },
});

export const { setCreated, setSuccess, setRefresh } = planningSlice.actions;
export default planningSlice.reducer;
