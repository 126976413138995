import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover, Space, Radio, Spin, message, TimePicker, Progress
} from "antd";
import {ArrowLeftOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";

import {useSelector} from "react-redux";
import {IoArrowForwardCircleOutline} from "react-icons/io5";
const {TextArea} = Input;
const EvaluationModalView = ({status, handleCancel}) => {

    const [selected, setSelected] = useState(null);

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 175}}
            closable={true}
            onCancel={() => {
                handleCancel();
            }}
            width={470}
            className="evaluation-service"
        >

            <div className="evaluation">
                <div className="mintitle custom-padding">
                    Évaluez cette prestation
                </div>
                <div className="description-controle">
                    Comment avez-vous apprécié nos services ?
                </div>
                <div className="sticker">
                    <Row>
                        <Col lg={5}><div className={"only-sticker "+(selected === "nul" ? "active" : "")} onClick={()=> setSelected("nul")}>🥲</div></Col>
                        <Col lg={5}><div className={"only-sticker "+(selected === "peu" ? "active" : "")} onClick={()=> setSelected("peu")}>🙁️</div></Col>
                        <Col lg={5}><div className={"only-sticker "+(selected === "moyen" ? "active" : "")} onClick={()=> setSelected("moyen")}>😕</div></Col>
                        <Col lg={5}><div className={"only-sticker "+(selected === "bien" ? "active" : "")} onClick={()=> setSelected("bien")}>🙂</div></Col>
                        <Col lg={4}><div className={"only-sticker "+(selected === "excelent" ? "active" : "")} onClick={()=> setSelected("excelent")}>😍</div></Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col lg={5}><div className="text">Nul</div></Col>
                        <Col lg={5}><div className=""></div></Col>
                        <Col lg={5}><div className=""></div></Col>
                        <Col lg={5}><div className=""></div></Col>
                        <Col lg={4}><div className="text">Excellent</div></Col>
                    </Row>
                </div>
                <br/>
                <Divider />
                <br/>
                <br/>
                <div className="description-controle">
                    Rajoutez quelques mots si vous souhaitez
                </div>
                <div className="textarea">
                    <TextArea
                              placeholder="Votre feedback ici"
                              rows={3}/>
                </div>
                <br/>
                <div className="add-file">
                    <div className="image">
                        <img src="./assets/img/upload-icon.png" alt="file"/>
                    </div>
                    <div className="text">Cliquez pour télécharger ou glisser-deposer un fichier</div>
                </div>

                <div className="footer-modal">
                    <Row>
                        <Col lg={13} className="button-left">
                            <a href="#" onClick={() => {
                                handleCancel();
                            }} className="annuler"><span> Annuler</span></a>
                        </Col>
                        <Col lg={11} className="button-right">
                            <a href="#" onClick={null}
                               className={"suivant sans-pro-regular "+(selected === null ? "disable" : "")}>Envoyer du feedback</a>
                        </Col>
                    </Row>
                </div>
            </div>


        </Modal>
    );
};
export default EvaluationModalView;
