import React, { useState, useEffect } from "react";
import {
  Input,
  Modal,
  Divider,
  Row,
  Col,
  Select,
  Popover,
  Space,
  Radio,
  Spin,
  message,
  TimePicker,
  Form,
} from "antd";
import {
  ArrowLeftOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { useSelector } from "react-redux";
import { useUpdatePricingsMutation } from "../services/tarif-api";

const UpdatePricingsModal = ({ status, handleCancel }) => {
  const [updatePricings] = useUpdatePricingsMutation();
  const [script, setScript] = useState(null);
  const [loading, setLoading] = useState(false);
  const onChange = (value) => {
    setScript(value);
  };

  const handleSubmit = ()=>{
    setLoading(true)
    updatePricings(script).unwrap()
        .then((res) => {
            setLoading(false);
            handleCancel();
        })
        .catch(() => {
                setLoading(false);
            }
        );
  }

  const runScript = () => {};

  return (
    <Modal
      title=""
      open={status}
      footer={null}
      closable={false}
      onCancel={() => {
        handleCancel();
      }}
      className="changer-creneau"
      width={1000}
    >
      <div className="creneau">
        <div className="head">
          <h3>Mise à jour des tarifs</h3>
        </div>
        <Row>
          <Form
            name="basic"
            onFinish={null}
            autoComplete="off"
            layout="vertical"
          >
            <Radio.Group onChange={(e) => onChange(e.target.value)}>
              <Row>
                <Col lg={24}>
                  <strong>
                    &nbsp; &nbsp; &nbsp; &nbsp; Évolution tarifs clients
                  </strong>
                  <br />
                  <Radio value={"1"}>
                    S’appliquera sur toute nouvelle prestation créée et sur les
                    prestations programmées à partir de J+30
                  </Radio><br/>
                </Col>
              </Row>{" "}
              <br /> <br />
              <Row>
                <Col lg={24}>
                  <strong>
                    &nbsp; &nbsp; &nbsp; &nbsp; Correction tarifs clients
                  </strong>
                  <br />
                  <Radio value={"2"}>
                    S’appliquera sur toute nouvelle prestation créée, sur les
                    prestations programmées ou en cours jusqu’à J+30 et sur les
                    prestations terminées sur le mois en cours
                  </Radio><br/>
                </Col>
              </Row>{" "}
              <br /> <br />
              <Row>
                <Col lg={24}>
                  <strong>
                    &nbsp; &nbsp; &nbsp; &nbsp; Évolution reverse partenaires
                  </strong>
                  <br />
                  <Radio value={"3"}>
                    S’appliquera sur toute nouvelle prestation créée et sur
                    toutes les prestations programmées
                  </Radio><br/>
                </Col>
              </Row>{" "}
              <br /> <br />
              <Row>
                <Col lg={24}>
                  <strong>
                    &nbsp; &nbsp; &nbsp; &nbsp; Correction reverse partenaires
                  </strong>
                  <br />
                  <Radio value={"4"}>
                    S’appliquera sur toute nouvelle prestation créée, sur toutes
                    les prestations programmées et sur les prestations terminées
                    sur le mois en cours
                  </Radio> <br/>
                </Col>
              </Row>{" "}
              <br /> <br />
            </Radio.Group>
          </Form>
        </Row>
        <div className="footer-modal">
          <Divider />
          <Row>
            <Col lg={14} className="button-left">
              <a
                href="#"
                onClick={() => {
                  handleCancel();
                }}
                className="annuler"
              >
                <span> Annuler</span>
              </a>
            </Col>
            <Col lg={10} className="button-right">
              <a
                href="#"
                onClick={() => handleSubmit()}
                className="suivant sans-pro-regular"
              >
                Lancer l'opération
              </a>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};
export default UpdatePricingsModal;
