import React, {useState} from 'react';
import {Col, Form, Input, Popover, Row} from "antd";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import {countryCodesMap} from "../../../../utils/global-var";
import {CloseOutlined, SearchOutlined, UserOutlined} from "@ant-design/icons";
import ChoisirForfaitView from "../../drawer/tabs/modal/forfait";

function RemunerationPartenairePage() {
    const [form] = Form.useForm();
    const [showModal2, setShowModal2] = useState(false);
    const contentInfo = (
        <div className="infoRemu">
            <p>
                hhh
            </p>
        </div>
    );
    return (
        <div className="content-in">
            <div className="head">
                <div className="image">
                    <img src="./assets/img/logon.png" alt="profil"/>
                </div>
                <div className="name">
                    Brice,
                </div>
            </div>
            <div className="titlePage sans-pro-bold">
                Quelle rémunération devrons-nous verser à ce <br/>
                partenaire pour l’entretien de vos logements ?
            </div>
            <div className="subTitle sans-pro-regular">
                Indiquez les frais de ménage à reverser à votre partenaire pour chaque logement que vous lui confiez, de façon à conserver les accords que vous avez négociés. Notez que nous prélèverons une commission sur les frais de ménage versés.
                <div style={{display:"inline-block", marginLeft:"5px"}}><a href="https://help.kliner.com/fr/articles/9343270-pourquoi-kliner-preleve-une-commission-sur-la-facturation-des-equipes-tierces" target="_blank"> En savoir plus sur nos conditions</a></div>
            </div>
            <div className="form-partenaire-remuneration">
                <Form
                    name="basic"
                    form={form}
                    onFinish={null}
                    autoComplete="off"
                    layout="vertical">
                    <Row>
                        <label className="sans-pro-semi-bold">Retrouvez un logement et indiquez les frais de ménage à appliquer
                            <Popover placement="top" content={contentInfo} overlayClassName="infoRemuneration">
                                <div className="info-remuneration">
                                    <img src="/assets/img/info-circled.png" alt="info"/>
                                </div>
                            </Popover>
                        </label>
                        <Col lg={12}>
                            <Input
                                className='search-input'
                                placeholder="Chercher et selectionnez le logement souhaité"
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                            />
                        </Col>
                        <Col lg={1} />
                    </Row>
                    <div className="block-tarif">
                        <Row>
                            <Col lg={10} >
                                <div className="logement">
                                    <div className="label">Logement 1</div>
                                    <div className="input">
                                        Albertville (Lyon)
                                    </div>
                                </div>
                            </Col>
                            <Col lg={7} >
                                <div className="label2">Frais de ménage (TTC)
                                    <Popover placement="top" content={contentInfo} overlayClassName="infoRemuneration">
                                        <div className="info-remuneration-ttc">
                                            <img src="/assets/img/info-circled.png" alt="info"/>
                                        </div>
                                    </Popover>
                                </div>
                                <Row>
                                    <Col lg={11}>
                                        <div className="input-clear">
                                            65.00€
                                            <div className="iconMore" onClick={() => setShowModal2(true)}>
                                                <img src="./assets/img/pen-colored.png" alt="more"/>
                                            </div>
                                        </div>
                                        <p>Vous nous payez</p>
                                    </Col>
                                    <Col lg={1}></Col>
                                    <Col lg={11}>
                                        <div className="input textcenter">
                                            61.75€
                                        </div>
                                        <p>Votre partenaire reçoit</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={4}>
                                <div className="close">
                                    <CloseOutlined style={{fontSize: "15px", marginRight: "10px", color:"grey"}}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <ChoisirForfaitView
                status={showModal2}
                top={755}
                left={400}
                handleCancel={() => setShowModal2(false)}
            />
        </div>
    );
}

export default RemunerationPartenairePage;