import React, {useState, useEffect} from 'react';
import "../../styles/style.scss"
import {Col, Input, Row, Select, Tabs, Form} from "antd";

const PrestatairesPartPage = ({checker, tarifs, onUpdate }) => {
    const [withLaundry, setWithLaundry] = useState('with_laundry');
    const [serviceAppoint, setServiceAppoint] = useState("Dépôt de linge");
    const [dataUpdate, setDataUpdate] = useState({});
    const items = [
        {
            key: 'with_laundry',
            label: <div className="sans-pro-light">Avec linge</div>,
            children: <div style={{paddingTop:"10px", fontSize:"11px", color:"#7F7F7F"}}>Revenus perçus pour les prestations réalisées.</div>,
        },
        {
            key: 'without_laundry',
            label: <div className="sans-pro-light">Sans linge</div>,
            children: <div style={{paddingTop:"10px", fontSize:"11px", color:"#7F7F7F"}}>Revenus perçus pour les prestations réalisées.</div>,
        },
    ];

    const onChange = (key) => {
        console.log(key);
        setWithLaundry(key);
    };

    const [form] = Form.useForm();

    const handleChange = (value) => {
        setServiceAppoint(value);
    };

    const updateVAT = (profile, type, value)=> {
        if(tarifs) {
            const vat = tarifs.vat.find(v=>v.userType === "partner" && v.userProfile === profile && v.serviceType === type);
            if(dataUpdate.vats) {
                const index = dataUpdate.vats.findIndex(elt=>elt.id === vat.id);
                if(index === -1) {
                    dataUpdate.vats.push({id: vat.id, value});
                } else {
                    dataUpdate.vats[index].value = value;
                }
                
            } else {
                dataUpdate.vats = [{id: vat.id, value}];
            }
            onUpdate(dataUpdate);
        }
    }

    useEffect(() => {
        if(tarifs) {
            form.setFieldsValue({
                "keys_deposit": getServiceAppointPrice("keys_deposit"),
                "laundry_deposit": getServiceAppointPrice("laundry_deposit"),
                "laundry_collect":getServiceAppointPrice("laundry_collect"),
                "other": getServiceAppointPrice("other"),
                "vat_sme_invoice_in": getCustomerVAT("sme", "invoice_in"),
                "vat_sme_invoice_out":getCustomerVAT("sme", "invoice_out"),
                "vat_ae_invoice_in": getCustomerVAT("ae", "invoice_in"),
                "vat_ae_invoice_out":getCustomerVAT("ae", "invoice_out"),
                "plan_confort": getPlanPrice("Confort"),
                "plan_autonome": getPlanPrice("Autonome"),
                "plan_accelerated_payment": getPlanPrice("accelerated_payment"),
                "classic_laundry": tarifs?.laundry?.classicLaundryPricings?.prix,
                "deep_laundry": tarifs?.laundry?.deepLaundryPricings?.prix,
                "laundry_product":checker? tarifs?.laundry?.laundryProduct?.reverse_ae : tarifs?.laundry?.laundryProduct?.reverse_pme,
                "laundry_kit": checker? tarifs?.laundry?.cleaningKit?.reverse_ae : tarifs?.laundry?.cleaningKit?.reverse_pme,
                "confort_sme_classic_with_laundry":getClassicLaundryPrice("Confort", "with_laundry", "pme"),
                "confort_sme_deep_with_laundry":getDeepLaundryPrice("Confort", "with_laundry", "pme"),
                "autonome_sme_classic_with_laundry": getClassicLaundryPrice("Autonome", "with_laundry", "pme"),
                "autonome_sme_deep_with_laundry": getDeepLaundryPrice("Autonome", "with_laundry", "pme"),
                "confort_sme_classic_without_laundry": getClassicLaundryPrice("Confort", "without_laundry", "pme"),
                "confort_sme_deep_without_laundry": getDeepLaundryPrice("Confort", "without_laundry", "pme"),
                "autonome_sme_classic_without_laundry": getClassicLaundryPrice("Autonome", "without_laundry", "pme"),
                "autonome_sme_deep_without_laundry": getDeepLaundryPrice("Autonome", "without_laundry", "pme"),
                "confort_ae_classic_with_laundry": getClassicLaundryPrice("Confort", "with_laundry", "auto-entrepreneur"),
                "confort_ae_deep_with_laundry": getDeepLaundryPrice("Confort", "with_laundry", "auto-entrepreneur"),
                "autonome_ae_classic_with_laundry": getClassicLaundryPrice("Autonome", "with_laundry", "auto-entrepreneur"),
                "autonome_ae_deep_with_laundry": getDeepLaundryPrice("Autonome", "with_laundry", "auto-entrepreneur"),
                "confort_ae_classic_without_laundry": getClassicLaundryPrice("Confort", "without_laundry", "auto-entrepreneur"),
                "confort_ae_deep_without_laundry": getDeepLaundryPrice("Confort", "without_laundry", "auto-entrepreneur"),
                "autonome_ae_classic_without_laundry": getClassicLaundryPrice("Autonome", "without_laundry", "auto-entrepreneur"),
                "autonome_ae_deep_without_laundry": getDeepLaundryPrice("Autonome", "without_laundry", "auto-entrepreneur"),
                "autonome_cost_per_laundry_kit": getCostPerLaundryClean()
            });
        }

    }, [tarifs, form, checker]);

    const getServiceAppointPrice = (key)=> {
        if(tarifs) {
            const service = tarifs.supportServices.find(svc=>svc.ref === key);
            return service ? (checker ? service.reverse_ae : service.reverse_pme) : "0.00";
        }
        return "0.00"
    }

    const getClassicLaundryPrice = (plan, laundryStatus, profile) => {
        if(tarifs) {
            const p = tarifs.subPlans.find(e=>e.name === plan);
            if(p) {
                const param = p.parameters.find(param=>param.userProfile === profile);
                if(param)
                    switch (laundryStatus) {
                        case "with_laundry": return param.cleaning_with_laundry_cost                    
                        case "without_laundry": return param.cleaning_without_laundry_cost
                        default: return "0.00"
                    }
            }
        }
        return "0.00"
    }

    const getDeepLaundryPrice = (plan, laundryStatus, profile) => {
        if(tarifs) {
            const p = tarifs.subPlans.find(e=>e.name === plan);
            if(p) {
                const param = p.parameters.find(param=>param.userProfile === profile);
                if(param)
                    switch (laundryStatus) {
                        case "with_laundry": return param.deep_cleaning_with_laundry_cost || "0.00"                   
                        case "without_laundry": return param.deep_cleaning_without_laundry_cost || "0.00"
                        default: return "0.00"
                    }
            }
        }
        return "0.00"
    }

    const getCustomerVAT = (profile, type)=> {
        if(tarifs) {
            const vat = tarifs.vat.find(vat=>vat.userType === "partner" && vat.userProfile === profile && vat.serviceType === type);
            return vat ? vat.value : "0.00";
        }
        return "0.00"
    }

    const getPlanPrice = (planName) => {
        if(tarifs) {
            const p = tarifs.subPlans.find(plan=>plan.name === planName);
            return p ? p.prices[0]?.amount : "0.00";
        }
        return "0.00"
    }

    const getCostPerLaundryClean = () => {
        if(tarifs) {
            const p = tarifs.subPlans.find(plan=>plan.name === "Autonome");
            return p ? p.parameters[0]?.cost_per_laundry_clean : "0.00";
        }
        return "0.00"
    }

    const updatePlanPrice = (plan, value) => {
        if(tarifs) {
            if(dataUpdate.plans) {
                const index = dataUpdate.plans.findIndex(elt=>elt.ref === plan);
                if(index === -1) {
                    dataUpdate.plans.push({ref: plan, value});
                } else {
                    dataUpdate.plans[index].value = value;
                }
                
            } else {
                dataUpdate.plans = [{ref: plan, value}];
            }
            onUpdate(dataUpdate);
        }
    }

    const getPlanParameter = (plan, profile) => {
        if(tarifs) {
            const p = tarifs.subPlans.find(e=>e.name === plan);
            if(p) {
                return p.parameters.find(param=>param.userProfile === profile);
            }
        }

        return null;
    }

    const updatePlanClassicLaundryParameters = (plan, laundryStatus, profile, value) => {
        if(tarifs) {
            const param = getPlanParameter(plan, profile);
            if(param) {
                    switch (laundryStatus) {
                        case "with_laundry": addPlanParameterToUpdateObject(param.id, "cleaning_with_laundry_cost", value); break;                    
                        case "without_laundry": addPlanParameterToUpdateObject(param.id, "cleaning_without_laundry_cost", value); break;
                        default: break
                    }
            }
        }
    }

    const addPlanParameterToUpdateObject = (id, field, value)=> {
        if(dataUpdate.planParameters) {
            const index = dataUpdate.planParameters.findIndex(elt=>elt.id === id && elt.field === field);
            if(index === -1) {
                dataUpdate.planParameters.push({id, field, value});
            } else {
                dataUpdate.planParameters[index].value = value;
            }
        } else {
            dataUpdate.planParameters = [{id, field, value}];
        }        
        onUpdate(dataUpdate);
    }

    const updatePlanDeepLaundryParameters = (plan, laundryStatus, profile, value) => {
        if(tarifs) {
            const param = getPlanParameter(plan, profile);
            if(param) {
                    switch (laundryStatus) {
                        case "with_laundry": addPlanParameterToUpdateObject(param.id, "deep_cleaning_with_laundry_cost", value); break;                    
                        case "without_laundry": addPlanParameterToUpdateObject(param.id, "deep_cleaning_without_laundry_cost", value); break;
                        default: break
                    }
            }
        }        
    }

    const updateServiceAppointPrice = (key, value)=> {
        if(tarifs) {
            if(dataUpdate.supportServices) {
                const index = dataUpdate.supportServices.findIndex(elt=>elt.ref === key);
                if(index === -1) {
                    dataUpdate.supportServices.push({ref: key, field: "reverse", value});
                } else {
                    dataUpdate.supportServices[index].value = value;
                }
                
            } else {
                dataUpdate.supportServices = [{ref: key, field: "reverse", value}];
            }
            onUpdate(dataUpdate);
        }
    }

    const updateCostPerLaundryKit = (value) =>{
        dataUpdate["costPerLaundryClean"] = value;
        onUpdate(dataUpdate);
    }


    const updateLaundryProductPrice = (value) => {
        const id = tarifs?.laundry?.laundryProduct?.id
        const field = checker ? "reverse_ae" : "reverse_pme";

        if(id && dataUpdate.laundryProduct) {
            const index = dataUpdate.laundryProduct.findIndex(elt=>elt.id === id && elt.field === field);
            if(index === -1) {
                dataUpdate.laundryProduct.push({id, field, value});
            } else {
                dataUpdate.laundryProduct[index].value = value;
            }
        } else {
            dataUpdate.laundryProduct = [{id, field, value}];
        }    
        onUpdate(dataUpdate);
    }

    const updateLaundryKitPrice = (value) => {
        const id = tarifs?.laundry?.cleaningKit?.id;
        const field = checker ? "reverse_ae" : "reverse_pme";

        if(id && dataUpdate.cleaningKit) {
            const index = dataUpdate.cleaningKit.findIndex(elt=>elt.id === id && elt.field === field);
            if(index === -1) {
                dataUpdate.cleaningKit.push({id, field, value});
            } else {
                dataUpdate.cleaningKit[index].value = value;
            }
        } else {
            dataUpdate.cleaningKit = [{id, field, value}];
        }    
        onUpdate(dataUpdate);
    }

    return (
        <div className="loueurs-part-page">
            <Form
                    name="basic"
                    layout="vertical"
                    className="form-edit-profil"
                    form={form}
            >
                <div className="separator"></div>
                <Row>
                    <Col lg={6}>
                        <div className="side">
                            <div className="titre">TVA</div>
                            <div className="desc">
                                Taux de TVA applicable selon le type de services
                            </div>
                        </div>
                    </Col>
                    <Col lg={18}>
                        <div className="sideContent">
                            <Row>
                             {!checker && <Col lg={8}>
                                    <div className="head">
                                        Tous services (% facture reçue)
                                    </div>
                                    <div className="input">
                                    <Form.Item name="vat_sme_invoice_in" >
                                        <Input id='vat_sme_invoice_in' onChange={e=>updateVAT("sme", "invoice_in", e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>}
                                {!checker && <Col lg={8}>
                                    <div className="head">
                                        Tous services (% facture emise)
                                    </div>
                                    <div className="input">
                                    <Form.Item name="vat_sme_invoice_out" >
                                        <Input id='vat_sme_invoice_out' onChange={e=>updateVAT("sme", "invoice_out", e.target.value)}/>
                                    </Form.Item>
                                        
                                    </div>
                                </Col>}                             
                                {checker && <Col lg={8}>
                                    <div className="head">
                                        Tous services (% facture reçue)
                                    </div>
                                    <div className="input">
                                    <Form.Item name="vat_ae_invoice_in" >
                                        <Input id='vat_ae_invoice_in' onChange={e=>updateVAT("ae", "invoice_in", e.target.value)}/>
                                    </Form.Item>
                                        
                                    </div>
                                </Col>}
                                {checker && <Col lg={8}>
                                    <div className="head">
                                        Tous services (% facture emise)
                                    </div>
                                    <div className="input">
                                    <Form.Item name="vat_ae_invoice_out" >
                                        <Input id='vat_ae_invoice_out' onChange={e=>updateVAT("ae", "invoice_out", e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>}
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className="separator"></div>
                <Row>
                    <Col lg={6}>
                        <div className="side">
                            <div className="titre">Abonnements de base</div>
                            <div className="desc">
                                Abonnements obligatoires pour l’utilisation de la plateforme Kliner
                            </div>
                        </div>
                    </Col>
                    <Col lg={18}>
                        <div className="sideContent">
                            <Row>
                                <Col lg={8}>
                                    <div className="head">
                                        Confort (€) par logement actif / mois
                                    </div>
                                    <div className="input">
                                    <Form.Item name="plan_confort" >
                                        <Input id='plan_confort' onChange={e=>updatePlanPrice("confort",e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className="head">
                                        Autonome (€) par logement actif / mois
                                    </div>
                                    <div className="input">
                                    <Form.Item name="plan_autonome" >
                                        <Input id='plan_autonome' onChange={e=>updatePlanPrice("autonome",e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>           
                </Row>
                <div className="separator"></div>
                <Row>
                    <Col lg={6}>
                        <div className="side">
                            <div className="titre">Abonnements optionnels</div>
                            <div className="desc">
                                Services à la carte
                            </div>
                        </div>
                    </Col>
                    <Col lg={18}>
                        <div className="sideContent">
                            <Row>
                                <Col lg={8}>
                                    <div className="head">
                                        Paiement accéléré
                                    </div>
                                    <div className="input">
                                    <Form.Item name="accelerated_payment" >
                                        <Input id='accelerated_payment' onChange={e=>updatePlanPrice("accelerated_payment",e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>            
                <div className="separator"></div>
                <Row>
                    <Col lg={6}>
                        <div className="side">
                            <div className="titre">Prestations</div>
                            <div className="desc">
                                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                            </div>
                        </div>
                    </Col>
                    <Col lg={18}>
                        <div className="sideContent">
                            {
                              !checker && withLaundry === "with_laundry" && (
                                <>
                                                            <Row>
                            <Col lg={8}>
                                <div className="head">
                                    Ménage classique (€) par heure
                                </div>
                                <div className="input">
                                <label>Confort</label>
                                <Form.Item name="confort_sme_classic_with_laundry" >
                                    <Input id='confort_sme_classic_with_laundry' onChange={e=>updatePlanClassicLaundryParameters("Confort", "with_laundry", "pme",e.target.value)}/>
                                </Form.Item>
                                    {/* <Input value={"0.00"}  /> <div className="indice">(€) par heure</div> */}
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="head">
                                    Ménage en profondeur (€) par heure
                                </div>
                                <div className="input">
                                <label>Confort</label>
                                <Form.Item name="confort_sme_deep_with_laundry" >
                                    <Input id='confort_sme_deep_with_laundry' onChange={e=>updatePlanDeepLaundryParameters("Confort", "with_laundry", "pme",e.target.value)}/>
                                </Form.Item>
                                    {/* <Input value={"0.00"}  /> <div className="indice">(€) par heure</div> */}
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={8}>
                                <div className="input">
                                <label>Autonome</label>
                                <Form.Item name="autonome_sme_classic_with_laundry" >
                                    <Input id='autonome_sme_classic_with_laundry'  onChange={e=>updatePlanClassicLaundryParameters("Autonome", "with_laundry", "pme",e.target.value)}/>
                                </Form.Item>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="input">
                                <label>Autonome</label>
                                <Form.Item name="autonome_sme_deep_with_laundry" >
                                    <Input id='autonome_sme_deep_with_laundry' onChange={e=>updatePlanDeepLaundryParameters("Autonome", "with_laundry", "pme",e.target.value)}/>
                                </Form.Item>
                                </div>
                            </Col>
                        </Row>
                                </>
                              )                                                                
                            }

                            {
                               !checker && withLaundry === "without_laundry" && (
                                    <>
                                                                <Row>
                                <Col lg={8}>
                                    <div className="head">
                                        Ménage classique (€) par heure
                                    </div>
                                    <div className="input">
                                    <label>Confort</label>
                                    <Form.Item name="confort_sme_classic_without_laundry" >
                                    <Input id='confort_sme_classic_without_laundry' onChange={e=>updatePlanClassicLaundryParameters("Confort", "without_laundry", "pme",e.target.value)}/>
                                    </Form.Item>
                                        {/* <Input value={"0.00"}  /> <div className="indice">(€) par heure</div> */}
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className="head">
                                        Ménage en profondeur (€) par heure
                                    </div>
                                    <div className="input">
                                    <label>Confort</label>
                                    <Form.Item name="confort_sme_deep_without_laundry" >
                                    <Input id='confort_sme_deep_without_laundry'  onChange={e=>updatePlanDeepLaundryParameters("Confort", "without_laundry", "pme",e.target.value)}/>
                                    </Form.Item>
                                        {/* <Input value={"0.00"}  /> <div className="indice">(€) par heure</div> */}
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col lg={8}>
                                    <div className="input">
                                    <label>Autonome</label>
                                    <Form.Item name="autonome_sme_classic_without_laundry" >
                                    <Input id='autonome_sme_classic_without_laundry' onChange={e=>updatePlanClassicLaundryParameters("Autonome", "without_laundry", "pme",e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className="input">
                                    <label>Autonome</label>
                                    <Form.Item name="autonome_sme_deep_without_laundry" >
                                    <Input id='autonome_sme_deep_without_laundry'  onChange={e=>updatePlanDeepLaundryParameters("Autonome", "without_laundry", "pme",e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                                    </>
                                )
                            }

                            {
                                checker && withLaundry === "with_laundry" &&(
                                    <>
                                                                <Row>
                                <Col lg={8}>
                                    <div className="head">
                                        Ménage classique (€) par heure
                                    </div>
                                    <div className="input">
                                    <label>Confort</label>
                                    <Form.Item name="confort_ae_classic_with_laundry" >
                                    <Input id='confort_ae_classic_with_laundry' onChange={e=>updatePlanClassicLaundryParameters("Confort", "with_laundry", "auto-entrepreneur",e.target.value)}/>
                                    </Form.Item>
                                        {/* <Input value={"0.00"}  /> <div className="indice">(€) par heure</div> */}
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className="head">
                                        Ménage en profondeur (€) par heure
                                    </div>
                                    <div className="input">
                                    <label>Confort</label>
                                    <Form.Item name="confort_ae_deep_with_laundry" >
                                    <Input id='confort_ae_deep_with_laundry' onChange={e=>updatePlanDeepLaundryParameters("Confort", "with_laundry", "auto-entrepreneur",e.target.value)}/>
                                    </Form.Item>
                                        {/* <Input value={"0.00"}  /> <div className="indice">(€) par heure</div> */}
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col lg={8}>
                                    <div className="input">
                                    <label>Autonome</label>
                                    <Form.Item name="autonome_ae_classic_with_laundry" >
                                    <Input id='autonome_ae_classic_with_laundry' onChange={e=>updatePlanClassicLaundryParameters("Autonome", "with_laundry", "auto-entrepreneur",e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className="input">
                                    <label>Autonome</label>
                                    <Form.Item name="autonome_ae_deep_with_laundry" >
                                    <Input id='autonome_ae_deep_with_laundry' onChange={e=>updatePlanDeepLaundryParameters("Autonome", "with_laundry", "auto-entrepreneur",e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                                    </>
                                )
                            }

                            {
                                checker && withLaundry === "without_laundry" &&(
                                    <>
                                                                <Row>
                                <Col lg={8}>
                                    <div className="head">
                                        Ménage classique (€) par heure
                                    </div>
                                    <div className="input">
                                    <label>Confort</label>
                                    <Form.Item name="confort_ae_classic_without_laundry" >
                                    <Input id='confort_ae_classic_without_laundry' onChange={e=>updatePlanClassicLaundryParameters("Confort", "without_laundry", "auto-entrepreneur",e.target.value)}/>
                                    </Form.Item>
                                        {/* <Input value={"0.00"}  /> <div className="indice">(€) par heure</div> */}
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className="head">
                                        Ménage en profondeur (€) par heure
                                    </div>
                                    <div className="input">
                                    <label>Confort</label>
                                    <Form.Item name="confort_ae_deep_without_laundry" >
                                    <Input id='confort_ae_deep_without_laundry' onChange={e=>updatePlanDeepLaundryParameters("Confort", "without_laundry", "auto-entrepreneur",e.target.value)}/>
                                    </Form.Item>
                                        {/* <Input value={"0.00"}  /> <div className="indice">(€) par heure</div> */}
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col lg={8}>
                                    <div className="input">
                                    <label>Autonome</label>
                                    <Form.Item name="autonome_ae_classic_without_laundry" >
                                    <Input id='autonome_ae_classic_without_laundry' onChange={e=>updatePlanClassicLaundryParameters("Autonome", "without_laundry", "auto-entrepreneur",e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className="input">
                                    <label>Autonome</label>
                                    <Form.Item name="autonome_ae_deep_without_laundry" >
                                    <Input id='autonome_ae_deep_without_laundry' onChange={e=>updatePlanDeepLaundryParameters("Autonome", "without_laundry", "auto-entrepreneur",e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                                    </>
                                )
                            }

                            <br/>
                            <Row>
                                <Col lg={8}>
                                    <div className="subhead-colored">
                                        OPTIONS
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={11}>
                                    <div className="head-content">
                                        <div className="point"></div>
                                        <div className="text">Produits ménagers</div>
                                    </div>
                                    <div className="input-inline">
                                    <Form.Item name="laundry_product" >
                                    <Input id='laundry_product' onChange={e=>updateLaundryProductPrice(e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>
                                <Col lg={1} />
                                <Col lg={11}>
                                    <div className="head-content">
                                        <div className="point"></div>
                                        <div className="text">Matériel de nettoyage</div>
                                    </div>
                                    <div className="input-inline">
                                    <Form.Item name="laundry_kit" >
                                    <Input id='laundry_kit' onChange={e=>updateLaundryKitPrice(e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col lg={14}>
                                    <div className="head-content">
                                        <div className="point"></div>
                                        <div className="text">Entretien linge (Autonome)</div>
                                    </div>
                                    <div className="input-inline">
                                    <Form.Item name="autonome_cost_per_laundry_kit" >
                                    <Input id='autonome_cost_per_laundry_kit'  onChange={e=>updateCostPerLaundryKit(e.target.value)}/>
                                    </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <div className="separator"></div>

                            <Row>
                            <Col lg={8}>
                                <div className="head">
                                    Service d'appoint
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">Pose d’une boîte à clés</div>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className="input-inline">
                                        <Form.Item name="keys_deposit" >
                                            <Input id='keys_deposit'  onChange={e=>updateServiceAppointPrice("keys_deposit", e.target.value)}/>
                                        </Form.Item>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                    {checker ? <div className="indice-double">(€) par service <br/> + coût matériel</div> : <div className="indice">(€) par service</div>}
                                    </Col>
                                    
                                </Row>
                            </Col>
                            <Col lg={1} />
                            <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">Dépôt de linge</div>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className="input-inline">
                                            <Form.Item name="laundry_deposit" >
                                                <Input id='laundry_deposit' onChange={e=>updateServiceAppointPrice("laundry_deposit", e.target.value)}/>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="indice-double">(€) par service <br/> + coût location</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={1} />
                        </Row>
                        <br/>
                        <Row>
                            <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">Collecte de linge</div>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className="input-inline">
                                        <Form.Item name="laundry_collect" >
                                                <Input id='laundry_collect'  onChange={e=>updateServiceAppointPrice("laundry_collect", e.target.value)}/>
                                            </Form.Item>
                                        
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                    <div className="indice">(€) par service</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={1} />
                            <Col lg={11}>
                                <Row>
                                    <Col lg={11}>
                                        <div className="head-content">
                                            <div className="pointBlue"></div>
                                            <div className="text">Autre besoin (forfait 1h)</div>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className="input-inline">
                                            <Form.Item name="other" >
                                                <Input id='other' onChange={e=>updateServiceAppointPrice("other", e.target.value)}/>
                                            </Form.Item>
                                            
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                    <div className="indice">(€) par service</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={1} />
                        </Row>


                            <br/>
                            {/* <Row>
                                <Col lg={11}>
                                    <Row>
                                        <Col lg={11}>
                                            <div className="head-content">
                                                <div className="pointBlue"></div>
                                                <div className="text">Contrôle Qualité</div>
                                            </div>
                                        </Col>
                                        <Col lg={13}>
                                            <div className="input-inline">
                                                <Input value={"0.00"}  /> <div className="indice-double">(€) par service <br/>au-delà du forfait</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> */}
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default PrestatairesPartPage;
