import React, {useState} from 'react';

import {Badge, Col, Divider, List, Row, Spin, Table} from "antd";
import {IoCloudDownloadOutline} from "react-icons/io5";
import {convertDateStringToTimeStringDateOnly} from "../../../../../utils/global-var";

const FacturesTab = (factures) => {
    console.log(factures.factures);
    const columns = [
        {
            title: 'Numéro de facture',
            key: "numero",
            width: "25%",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.facture}</div>
            </div>,
        },
        {
            title: 'Date',
            key: "date",
            width: "23%",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.date}</div>
            </div>,
        },
        {
            title: 'Total',
            key: "total",
            width: "23%",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.total}</div>
            </div>,
        },
        {
            title: 'Statut',
            key: "statut",
            width: "23%",
            render: (record) => <div className="badgeTable">
                {(record.statut === "during") && (<Badge color="#faad14" count={"En cours"} />)}
                {(record.statut === "pending") && (<Badge color="black" count={"A faire"} />)}
                {(record.statut === "done") && (<Badge color="green" count={"Fait"} />)}
                {(record.statut === "cancel") && (<Badge color="red" count={"Annulé"} />)}
            </div>,
        },
        {
            title: '',
            key: "download",
            width: "6%",
            render: (record) => <div className="download">
                <img src="./assets/img/logon.png" alt="download"/>
            </div>,
        }
    ];
    return (
        <div className="FacturesTab-client-page">
            <div className="headprofil">
                <Table
                    className="facture-table"
                    columns={columns}
                />
               <div style={{height:"400px", overflowY:"scroll"}}>
                   {factures?.factures?.data?.map((item, index) => (
                       <Row className="factures-listes" key={"factures_"+index}>
                           <Col lg={6}>
                               <div className="tableTitle sans-pro-semi-bold">{item.number}</div>
                           </Col>
                           <Col lg={6}>
                               <div className="tableTitle">{ convertDateStringToTimeStringDateOnly(new Date(item.created * 1000))}</div>
                           </Col>
                           <Col lg={6}>
                               <div className="tableTitle">{((item.amount_due || 0)/100).toFixed(2)} €</div>
                           </Col>
                           <Col lg={5}>
                               <div className="tableTitle">
                                   {/* {(item.status === "paid") && (<div className="bo-badge-green">Payé</div>)}
                                   {(item.status === "open") && (<div className="bo-badge-red">Impayé</div>)}
                                   {(item.status === "review") && (<div className="bo-badge-black">Relance</div>)}
                                   {(item.status === "pending") && (<div className="bo-badge-black">Relance</div>)} */}

                                    {(item["status"] === "paid") && (<Badge color="green" count={"Payée"} />)}
                                    {(item["status"] === "open") && (<Badge color="red" count={"Impayée"} />)}
                                    {(item["status"] === "draft") && (<Badge color="black" count={"Brouillon"} />)}
                                    {(item["status"] === "void") && (<Badge color="red" count={"Annulée"} />)}                                   
                               </div>
                           </Col>
                           <Col lg={1}>
                               <div className="download">
                                   {/*<img src="./assets/img/logon.png" alt="download"/>*/}
                                   <a className="download" href={item.invoice_pdf} download={true} >
                                      {/* <img src="./assets/img/download-icon.png" alt="download" /> */}
                                      <IoCloudDownloadOutline />
                                   </a>
                               </div>
                           </Col>
                       </Row>
                   ))}
               </div>
            </div>
        </div>
    );
}

export default FacturesTab;
