import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const planningApi = createApi({
  reducerPath: "planningApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Planning"],
  endpoints: (builder) => ({
    prestationCreate: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/prestation/create-for-house/",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Planning"],
    }),

    prestationFetch: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/prestation/user-planning`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Planning"],
    }),

    prestationUserFetch: builder.query({
      query: (id) => `/api/v1/prestation/user/${id}`,
      providesTags: ["Planning"],
    }),

    prestationFetchOne: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/prestation/${payload.id}`,
        method: "GET",
      }),
      invalidatesTags: ["Planning"],
    }),

    prestationUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/prestation/${payload.id}`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ["Planning"],
    }),

    prestationDelete: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/prestation/${payload}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Planning"],
    }),

  }),
});

export const {
  usePrestationCreateMutation,
  usePrestationFetchMutation,
  usePrestationUserFetchQuery,
  usePrestationFetchOneMutation,
  usePrestationUpdateMutation,
  usePrestationDeleteMutation
} = planningApi;
