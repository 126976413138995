import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../../utils/global-var";

export const tarifApi = createApi({
  reducerPath: "tarifApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["tarif"],
  endpoints: (builder) => ({
    updateTarif: builder.mutation({
      query: (data) => ({
        url: "/api/v1/tarifs/update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tarif"],
    }),
    getTarifs: builder.query({
      query: () => `/api/v1/tarifs`,
      providesTags: ["tarif"],
    })
  }),
});

export const {
  useGetTarifsQuery,
  useUpdateTarifMutation
} = tarifApi;
