import React, { useState } from "react";
import {Modal, Divider, Row, Col, Spin, message} from "antd";
import {useLogementDeleteAssignationMutation} from "../services/logement-api";
import { useDispatch } from "react-redux";
import { setRefresh } from "../services/logement-slice";

const SupprimerAssignationModalView = ({ logementId, status, handleCancel }) => {
    const [logementDelete] = useLogementDeleteAssignationMutation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = () => {
        setIsLoading(true)
        const data = ({ id: logementId })
        logementDelete(data).unwrap()
            .then(() => {
                dispatch(setRefresh(true));
                handleCancel();
                message.success("Assignation retirée.")
                setIsLoading(false)
            })
            .catch((error) => {
                handleCancel();
                message.error(error.data.message);
                setIsLoading(false)
            });
    };


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={600}
            className="suppressionAssignation">

            <Spin spinning={isLoading}>
                <div className="supprimer">
                    <div className="title sans-pro-semi-bold"><img src="/assets/img/info-pop-up_colored.png" alt="airbnb" className="title-img" /><div className="title-label">Retirer l’assignation sur ce logement
                    </div></div>
                    <Row>
                        <Col>
                            <span>
                                Cette action n’est possible que s’il n’y a plus de prestations programmées sur <br/>
                                le logement. Si vous poursuivez il n’y aura plus d’équipe de ménage assignée <br/>
                                et le client ne pourra plus programmer de prestation.
                            </span>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={12} className="button-left">
                                <a href="#" onClick={() => handleCancel()} className="annuler"><span> Annuler la demande</span></a>
                            </Col>
                            <Col lg={11} className="button-right">
                                <a href="#" onClick={() =>  handleDelete()}
                                   className="suivant sans-pro-regular">Confirmer</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default SupprimerAssignationModalView;
