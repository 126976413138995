import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover, Space, Radio, Spin, message, TimePicker
} from "antd";
import {ArrowLeftOutlined, MinusOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";

import {useSelector} from "react-redux";

const ChoisirForfaitView = ({status, top, left, handleCancel}) => {

    const timeFormat = 'HH:mm';

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: top != null ? top : 525, left: left !== null ? left : 350}}
            closable={false}
            onCancel={() => {
                handleCancel();
            }}
            width={280}
            className="choisir-logement"
        >

            <div className="choisir">
                <div className="head">
                    <div className="arrow" onClick={handleCancel}><ArrowLeftOutlined /></div>
                    <div className="title"><div  className="title-in">Forfait pour Pasteur</div></div>
                </div>
                <br/>
                <label htmlFor="price" className="sans-pro-regular" style={{fontSize:"12px", color:"#98A1AC"}}>Vous nous payez</label>
                <Row>
                    <Col lg={8}>
                <Input
                    id="price"
                    className='search-input backWhite'
                    placeholder="100"
                />
                    </Col>
                    <Col>
                       <div className="signe">
                           €
                       </div>
                    </Col>
                </Row>
                <br/>
                <label htmlFor="price2" className="sans-pro-regular" style={{fontSize:"12px", color:"#98A1AC"}}>Ce partenaire recevra</label>
                <Row>
                    <Col lg={8}>
                        <Input
                            id="price2"
                            className='search-input'
                            placeholder="95.00"
                        />
                    </Col>
                    <Col>
                        <div className="signe">
                            €
                        </div>
                    </Col>
                </Row>
                <div className="footer-modal">

                    <Row>
                        <Col lg={24} className="button-right">
                            <a href="#" onClick={handleCancel}
                               className="suivant sans-pro-regular">Valider le forfait</a>
                        </Col>
                    </Row>
                </div>
            </div>


        </Modal>
    );
};
export default ChoisirForfaitView;
