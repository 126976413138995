import React, {useState} from 'react';

import {Badge, Col, Divider, Row, Table} from "antd";

function AttestationTab() {
    const factures = [
        {
            key: 3,
            facture: `Belleville`,
            date: `01.05.2024 - 01.05.2024`,
            total: "134.00 €",
            statut: "paid",
        },
        {
            key: 3,
            facture: `Pasteur`,
            date: `01.05.2024 - 01.05.2024`,
            total: "134.00 €",
            statut: "not-paid",
        },
    ];
    const columns = [
        {
            title: 'Periode',
            key: "numero",
            width: "25%",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.date}</div>
            </div>,
        },
        {
            title: 'Logement',
            key: "date",
            width: "23%",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.facture}</div>
            </div>,
        },
        {
            title: 'Total dépensé',
            key: "total",
            width: "23%",
            render: (record) => <div style={{cursor:"pointer"}}>
                <div className="titleTable">{record.total}</div>
            </div>,
        },
        {
            title: '',
            key: "download",
            width: "6%",
            render: (record) => <div className="download">
                <img src="./assets/img/logon.png" alt="download"/>
            </div>,
        }
    ];
    return (
        <div className="FacturesTab-client-page">
            <div className="headprofil">
                {/*<Table
                    className="facture-table"
                    columns={columns}
                />*/}
                {/*factures.map((item, index) => (
                    <Row className="factures-listes" key={"factures_"+index}>
                        <Col lg={8}>
                            <div className="tableTitle sans-pro-semi-bold">{item["date"]}</div>
                        </Col>
                        <Col lg={8}>
                            <div className="tableTitle" style={{color:"grey"}}>{item["facture"]}</div>
                        </Col>
                        <Col lg={7}>
                            <div className="tableTitle">{item["total"]}</div>
                        </Col>
                        <Col lg={1}>
                            <div className="download">
                                <img src="./assets/img/logon.png" alt="download"/>
                            </div>
                        </Col>
                    </Row>
                ))*/}
            </div>
        </div>
    );
}

export default AttestationTab;
