import React, { useState } from "react";
import {Modal, Divider, Row, Col, Spin, message} from "antd";
import {useDispatch} from "react-redux";
import {usePrestationUpdateMutation} from "../services/planning-api";
import {setRefresh} from "../services/planning-slice";

const AnnulerPrestationModalView = ({ status, prestationId, handleCancel }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch()
    const [prestationUpdate] = usePrestationUpdateMutation();
  //console.log(prestationId);
    const handleDemandeEquipe = () => {
        setIsLoading(true);
        prestationUpdate({ id: prestationId, data: { status: "Annulé" } })
            .unwrap()
            .then(() => {
                dispatch(setRefresh(true));
                setIsLoading(false);
                message.success("Prestation annulée.");
                handleCancel();
            })
            .catch(() => setIsLoading(false));
    }

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={600}
            className="suppressionLogementNotPossible">

            <Spin spinning={isLoading}>
                <div className="supprimer">
                    <div className="title sans-pro-semi-bold" style={{marginBottom:"8px"}}><img src="/assets/img/info-pop-up_colored.png" alt="airbnb" className="title-img" />
                        <div className="title-label" style={{marginLeft:"10px"}}>Annuler la prestation</div>
                    </div>
                    <Row>
                        <Col>
                            <span className="sans-pro-regular">
                                Vous êtes sur le point d’annuler cette prestation. Si vous poursuivez, la prestation va <br/>
                                passer en statut ‘Annulé’, sera retiré du planning de l’aide-ménagère, et le client sera <br/>
                                informé via la mise à jour des logs dans sa web app.

                            </span>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={14} className="button-left">
                                <a href="#" onClick={() => {
                                    handleCancel();
                                }} className="annuler"><span> Annuler la demande</span></a>
                            </Col>
                            <Col lg={10} className="button-right">
                                <a href="#" onClick={() => handleDemandeEquipe()}
                                   className="suivant sans-pro-regular" style={{width:"90px", textAlign:"center"}}>Confirmer</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default AnnulerPrestationModalView;
