import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const instructionApi = createApi({
  reducerPath: "instructionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Instruction"],
  endpoints: (builder) => ({
    instructionCreate: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/logement/instruction/",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Instruction"],
    }),

    instructionFetch: builder.query({
      query: (id) => `/api/v1/instruction/user/${id}`,
      providesTags: ["Instruction"],
    }),

    instructionFetchOne: builder.query({
      query: (id) => `/api/v1/instruction/${id}`,
      providesTags: ["Instruction"],
    }),

    instructionUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/instruction/${payload.id}`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ["Instruction"],
    }),

    productsFetch: builder.query({
      query: () => `/api/v1/produit/type/kit`,
      providesTags: ["Instruction"],
    }),
  }),
});

export const {
  useInstructionCreateMutation,
  useInstructionFetchQuery,
  useInstructionFetchOneQuery,
  useInstructionUpdateMutation,
  useProductsFetchQuery
} = instructionApi;
