import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import { Spin, Alert } from "antd";


function PmeMobilePage() {
    const routeParams = useParams();
    const stripe = useStripe();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const handleAccountId = async () => {
        const state = JSON.parse((routeParams.user));
        const accountData = {
            business_type: `${state.role === "pme" ? "company" : "individual"}`,
            business_profile: {
                url: "www.kliner.com",
            },
            tos_shown_and_accepted: true,
        };

        const accountResult = await stripe.createToken("account", accountData);
        if (accountResult.error) {
            setIsLoading(false)
            setError(accountResult.error.message);
        } else {
            return accountResult.token.id;
        }
    };

    const handlePersonId = async () => {
        const state = JSON.parse((routeParams.user));
        const personResult = await stripe.createToken("person", {
            person: {
                first_name: state.firstname,
                last_name: state.lastname,
                email: state.email,
                phone: state.phone,
                dob: {
                    day: state.day,
                    month: state.month,
                    year: state.year,
                },
                address: {
                    line1: state.residence_principale_street,
                    city: state.residence_principale_city,
                    state: state.residence_principale_city,
                    postal_code: state.residence_principale_zip,
                },
                relationship: {
                    representative: true,
                    director: true,
                    executive: true,
                    owner: true,
                    title: "Directeur général",
                },
            },
            tos_shown_and_accepted: true,
        });
        if (personResult.error) {
            setIsLoading(false)
            setError(personResult.error.message);
        } else {
            return personResult.token.id;
        }
    };

    const handleGoBack = async () => {
        setIsLoading(true)
        const accountToken = await handleAccountId();
        const personToken = await handlePersonId();
        if (accountToken && personToken) {
            window.accountToken.postMessage(accountToken);
            window.personToken.postMessage(personToken);
        }
    }

    const handleCancel = async () => {
        window.cancel.postMessage("cancel");
    }

    return (
        <div className="auth-page" style={{ textAlign: 'center' }}>
            <Spin spinning={isLoading}>
                <div className="auth-stripe-token">
                    {
                        error ?
                            <div onClick={() => handleGoBack()}>
                                <Alert message={error} type="error" closable />
                            </div> :
                            <div className="auth-stripe-content">
                                <div className="btn-cancel" onClick={() => handleCancel()}>ANNULER</div>
                                <div className="btn-confirm" onClick={() => handleGoBack()}>CONFIRMER</div>
                            </div>
                    }
                </div>
            </Spin>
        </div>
    );
}
export default PmeMobilePage;