import React, {useState} from 'react';
import "../styles/style.scss"
import {Avatar, Badge, Col, Drawer, Dropdown, Input, Menu, Popover, Progress, Row, Select, Table} from "antd";
import {IoAdd, IoEllipsisHorizontal, IoStar, IoSyncSharp} from "react-icons/io5";
import {EditOutlined, PlusCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {API_ROOT, convertDateStringToTimeStringComplete} from "../../../utils/global-var";
import moment from "moment/moment";
import EquipeDrawerPage from "../drawer";
import {NavLink} from "react-router-dom";
import {useEquipeEntretienFetchMutation} from "../services/equipe-entretien-api";

const ResponsableSitePage = ({record, loading, setRequest, request, stats}) => {
    const [open, setOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const showDrawer = (record) => {
        setCurrentRecord(record);
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    //console.log(record);
    const onChange = (key) => {
        console.log(key);
    };
    const getMenuItems = (record) => [
        {
            label: <div className="add-logement2-item sans-pro-regular">
                <div className="imageSide">
                    <img src="../assets/img/attribuer-lgt-icon.png" alt="add" />
                </div>
                Attribuer un logement
            </div>,
            key: '1itemsOption',
        },
        {
            label: <div className="add-logement2-item sans-pro-regular">
                <div className="imageSide">
                    <img src="../assets/img/pourboire-icon.png" alt="add" />
                </div>
                Verser un pourboire
            </div>,
            key: '2itemsOption',
        },
        {
            type: 'divider',
        },
        {
            label:
                <div className="add-logement2-item sans-pro-regular" style={{ color: "red" }} onClick={() => null}>
                    <div className="imageSide">
                        <img src="../assets/img/bloquer-profil-icon.png" alt="add" />
                    </div>
                    Bloquer ce profil
                </div>,
            key: '6itemsOption',
        },
    ];

    const columns = [
        {
            title: "Prénom, Nom, Entreprise",
            width: "25%",
            render: (record) => (
                <div style={{ cursor: 'pointer', color: "#263754", fontSize: "15px" }} onClick={()=>showDrawer(record)}>
                    <div className="avatar">
                        <Avatar src={`${API_ROOT}/${record.avatar}`} alt="avatar"/>
                    </div>
                    <div className="info">
                        <div className="name sans-pro-semi-bold">{record.lastname} {record.firstname} {record.special && <IoStar className='icon' style={{color:"#FFBF00"}} />}</div>
                        <div className="desc">{record.entreprise}</div>
                    </div>
                    <div className="sigle ">
                        {record.role === "auto-entrepreneur" ? "AE" : "PME"}
                    </div>
                </div>
            ),
        },

        {
            title: 'Statut',
            width: "20%",
            render: (record) => (
                <div>
                    {(record.is_bloqued === 0 && record.total_prest !== "0") && (<div className="badge-green" >Actif</div>)}
                    {(record.statut === "pending") && (<div className="badge-blue" >Invitation envoyée</div>)}
                    {(record.is_bloqued === 0 && record.total_prest === "0") && (<div className="wait">
                        <div className="badge-grey" >En attente de prestations</div>
                        <Popover placement="top" content={contentInfo} overlayClassName="infoContentLogement">
                            <div className="info-equipe">
                                <img src="/assets/img/info-circled.png" alt="info" />
                            </div>
                        </Popover>
                    </div>)}
                    {(record.is_bloqued === 1) && (<div className="wait">
                        <div className="badge-red" >Bloqué</div>
                        <Popover placement="top" content={contentInfoBlocked} overlayClassName="infoContentLogement">
                            <div className="info-equipe">
                                <img src="/assets/img/info-circled.png" alt="info" />
                            </div>
                        </Popover>
                    </div>)}
                </div>
            ),
        },
        {
            title: 'Perf.',
            width: "5%",
            render: (record) => (
                <div>
                    <Progress type="circle" percent={record.performance_percentage} size={20} />
                </div>
            ),
        },
        {
            title: 'Numéro de téléphone',
            width: "13%",
            render: (record) => (
                <div>
                    {record.phone}
                </div>
            ),
        },
        {
            title: 'Logements gérés',
            width: "10%",
            render: (record) => (
                <div>
                    <div className="nbLogement">
                        {record.logements_total_number}
                    </div>
                </div>
            ),
        },
        {
            title: 'Aides ménagères',
            width: "10%",
            render: (record) => (
                <div>{record.nb_aide_menagere}</div>
            ),
        },
        {
            title: 'Créé depuis le…',
            width: "19%",
            render: (record) => (
                <div>{convertDateStringToTimeStringComplete(record.createdAt)}</div>
            ),
        },
        {
            title: "",
            key: "option",
            width: "5%",
            render: (record) => (
                <div  className="tab-option">
                    <Dropdown
                        overlay={<Menu items={getMenuItems(record)} />}
                        placement="bottomRight" trigger={['click']}>
                        <IoEllipsisHorizontal style={{ fontSize: "19px", marginRight: "10px" }} />
                    </Dropdown>
                </div>
            ),
        },
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const contentInfoBlocked = (
        <div className="infoLogement">
            <p>
                Ce responsable de site ne pourra <br/>
                plus être assigné sur vos <br/>
                logements. Il sera sorti de votre <br/>
                équipe lorsque tous vos <br/>
                logements où il est encore <br/>
                assigné seront transférés à <br/>
                d'autres responsables
            </p>
        </div>
    );
    const contentInfo = (
        <div className="infoLogement">
            <p>
                Des frais de gestion de 50€ <br/>
                s’appliquent si 30 jours après la <br/>
                mise en place d’une nouvelle <br/>
                équipe, celle-ci n’a effectuée <br/>
                aucune prestation.
            </p>
        </div>
    );
    const contentInfoSuccess = (
        <div className="infoLogement">
            <p>
                Représente le pourcentage de <br/>
                prestations effectuées sur vos <br/>
                logements dont la note globale <br/>
                est supérieure ou égale à 4 sur <br/>
                5.
            </p>
        </div>
    );
    const contentInfoSaturation = (
        <div className="infoLogement">
            <p>
                Représente le taux de remplissage <br/>
                du planning de l’équipe <br/>
                sélectionnée. Plus ce taux est <br/>
                élevé, moins l’équipe est flexible <br/>
                et la qualité des prestations est <br/>
                souvent affectée.
            </p>
        </div>
    );
    const items = [
        {
            label: <div onClick={() => null}  className="sans-pro-regular color-5B6E8C">
                <IoSyncSharp />  <span className="text">Demander une équipe pour un nouveau logement</span>
            </div>,
            key: '2',
        },
        {
            label: <div  className="sans-pro-regular color-5B6E8C" onClick={() => null}>
                <EditOutlined />  <span className="text">Changer l’équipe sur un logement actif</span>
            </div>,
            key: '3',
        },
        {
            label: <div  className="sans-pro-regular color-5B6E8C">
                <NavLink to={"/inviter-un-tierce"} className="color-5B6E8C"><PlusCircleOutlined /> <span className="text">Inviter une équipe tierce sur Kliner</span></NavLink>
            </div>,
            key: '4',
        },
    ];
    console.log(stats);

    return (
        <div className=''>
            <br/>
            <div className="header">
                <div className='flex justify-content-space-between'>
                    <div className='blc-left'>
                        <div className='blc-filter-alter'>
                            <Input
                                className='search-input'
                                placeholder="Chercher par nom de partenaire…"
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={(e) => setRequest(e.target.value === "" ? "" : {search: e.target.value})}
                            />
                        </div>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Statut:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={(value)=> setRequest(value === 'Tout' ? "" : {userType: value})}
                                suffixIcon={<div className='icon-logement-select'><img
                                    src="../assets/img/arrow-down-gray.png" alt="add"/></div>}
                                options={[
                                    {
                                        value: 'Tout', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter"/>
                                            <div className="text sans-pro-regular color-213856"
                                                 style={{marginLeft: "10px"}}>
                                                Tout
                                            </div>
                                        </div>
                                    },
                                    {
                                        value: 'ACTIF', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter"/>
                                            <div className="text sans-pro-regular color-213856"
                                                 style={{marginLeft: "10px"}}>
                                                Actif
                                            </div>
                                        </div>
                                    },
                                    {
                                        value: 'INACTIF', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter"/>
                                            <div className="text sans-pro-regular color-213856"
                                                 style={{marginLeft: "10px"}}>
                                                Inactif
                                            </div>
                                        </div>
                                    },
                                ]}
                                popupClassName="equipe-statut-classe-site"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="stats">
                <Row>
                    <Col lg={12}>
                        <div className="content statistiqueLeft">
                            <div className="head">
                                <div className="image">
                                    <img src="./assets/img/white-stars-icon.png" alt="logo"/>
                                </div>
                                <div className="text">
                                    <div className="title sans-pro-semi-bold"  >Performance</div>
                                    <div className="description color-5B6E8C sans-pro-regular">Surveiller l’efficacité de vos équipes de ménage</div>
                                </div>
                            </div>
                            <div className="statistique">
                                <Row>
                                    <Col lg={12}>
                                        <div className="blocStat blockBlue">
                                            <div className="indication">
                                                <Popover placement="top" content={contentInfoSuccess} overlayClassName="infoContentLogement">
                                                    <div className="info-equipe">
                                                        <img src="/assets/img/info-circled.png" alt="info" />
                                                    </div>
                                                </Popover>
                                            </div>
                                            <div className="number">{parseFloat(stats?.prestations_successful_percentage ?? 0).toFixed(2)}%</div>
                                            <div className="label sans-pro-light">Prestations réussies</div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="blocStat blockOrange">
                                            <div className="indication">
                                                <Popover placement="top" content={contentInfoSaturation} overlayClassName="infoContentLogement">
                                                    <div className="info-equipe">
                                                        <img src="/assets/img/info-circled.png" alt="info" />
                                                    </div>
                                                </Popover>
                                            </div>
                                            <div className="number">{parseFloat(stats?.saturation_percentage ?? 0).toFixed(2)}%</div>
                                            <div className="label sans-pro-light">Taux de saturation</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="content statistiqueRight">
                            <div className="head">
                                <div className="image">
                                    <img src="./assets/img/activity.png" alt="logo"/>
                                </div>
                                <div className="text">
                                    <div className="title sans-pro-semi-bold"  >Activité générale</div>
                                    <div className="description color-5B6E8C sans-pro-regular">Garder un œil sur la répartition du travail au sein de vos équipes</div>
                                </div>
                            </div>
                            <div className="statistique">
                                <Row>
                                    <Col lg={12}>
                                        <div className="blocStat blockGreyLeft">
                                            <div className="indication">

                                            </div>
                                            <div className="number">{parseFloat(stats?.totalite_prestation_percentage ?? 0).toFixed(2)}%</div>
                                            <div className="label sans-pro-light">De la totalité de vos prestations</div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="blocStat blockGreyRight">
                                            <div className="indication">

                                            </div>
                                            <div className="number">{parseFloat(stats?.prestations_total_finished ?? 0).toFixed(2)}</div>
                                            <div className="label sans-pro-light">Prestations effectuées</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="content">
                <Table
                    className="equipe-table"
                    pagination={{ pageSize: 10 }}
                    rowSelection={rowSelection}
                    dataSource={record.data?.responsables}
                    columns={columns}
                    rowKey={(record) => record.key}
                    loading={loading}
                />
            </div>
            {currentRecord !== null && <Drawer title="" onClose={onClose} open={open} width={550} className="equipe-custom-drawer-class">
                <EquipeDrawerPage equipe={currentRecord} setRequest={setRequest} request={request} />
            </Drawer>}
        </div>
    );
}

export default ResponsableSitePage;
