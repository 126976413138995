import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const equipeEntretienApi = createApi({
    reducerPath: "equipeEntretienApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["EquipeEntretien", "StatEquipe"],
    endpoints: (builder) => ({

        equipeEntretienFetch: builder.mutation({
            query: (payload) => ({
                url: "/api/v1/performance/user/entretien-equipe",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["EquipeEntretien"],
        }),

        equipeEntretienFetchOne: builder.query({
            query: (id) => `/api/v1/equipeEntretien/${id}`,
            providesTags: ["EquipeEntretien"],
        }),

        equipeEntretienStatusFetchOne: builder.query({
            query: (id) => `/api/v1/prestation/stats-by-user/${id}`,
            providesTags: ["StatEquipe"],
        }),
    }),
});

export const {
    useEquipeEntretienFetchMutation,
    useEquipeEntretienFetchOneQuery,
    useEquipeEntretienStatusFetchOneQuery,
} = equipeEntretienApi;
