import React, {useState} from 'react';
import "./styles/style.scss"
import {Col, Divider, Dropdown, Menu, Popover, Row, Select, Table} from "antd";
import {IoAdd, IoEllipsisHorizontal, IoEllipsisVertical, IoStar} from "react-icons/io5";
import {ArrowRightOutlined} from "@ant-design/icons";

function ParrainagePage() {
    return (
        <div className='logistique-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Parrainage</h1>
                </div>
            </div>

            <div className="header">
                <div className='flex justify-content-space-between'>
                    <div className='blc-left'>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Responsable:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={null}
                                suffixIcon={<div className='icon-logement-select'><img
                                    src="../assets/img/arrow-down-gray.png" alt="add"/></div>}
                                options={[
                                    {
                                        value: 'Tout', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter"/>
                                            <div className="text sans-pro-regular color-213856"
                                                 style={{marginLeft: "10px"}}>
                                                Camelia Megrard
                                            </div>
                                        </div>
                                    },
                                    {
                                        value: 'Tout', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter"/>
                                            <div className="text sans-pro-regular color-213856"
                                                 style={{marginLeft: "10px"}}>
                                                Marine Babey
                                            </div>
                                        </div>
                                    },
                                    {
                                        value: 'Tout', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter"/>
                                            <div className="text sans-pro-regular color-213856"
                                                 style={{marginLeft: "10px"}}>
                                                Julia Mbengue
                                            </div>
                                        </div>
                                    },
                                ]}
                                popupClassName="responsable-classe-select"
                            />
                        </div>

                    </div>
                </div>
            </div>

            <div className="stats">
                <Row>
                    <Col lg={12}>
                        <div className="content statistiqueLeft">
                            <div className="head">
                                <div className="image">
                                    <img src="./assets/img/white-stars-icon.png" alt="logo"/>
                                </div>
                                <div className="text">
                                    <div className="title sans-pro-semi-bold">Efficacité commerciale</div>
                                    <div className="description color-5B6E8C sans-pro-regular"> Mesurer l'attractivité du programme
                                    </div>
                                </div>
                            </div>
                            <div className="statistique">
                                <Row>
                                    <Col lg={12}>
                                        <div className="blocStat blockBlue">
                                            <div className="indication">
                                                <Popover placement="top" content={"test"}
                                                         overlayClassName="infoContentLogement">
                                                    <div className="info-equipe">
                                                        <img src="/assets/img/info-circled.png" alt="info"/>
                                                    </div>
                                                </Popover>
                                            </div>
                                            <div className="number">145</div>
                                            <div className="label sans-pro-light">Nouveaux utilisateurs</div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="blocStat blockOrange">
                                            <div className="indication">
                                            </div>
                                            <div className="number">32</div>
                                            <div className="label sans-pro-light">Parrainés actifs</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="content statistiqueRight">
                            <div className="head">
                                <div className="image">
                                    <img src="./assets/img/activity.png" alt="logo"/>
                                </div>
                                <div className="text">
                                    <div className="title sans-pro-semi-bold">Performance financiere</div>
                                    <div className="description color-5B6E8C sans-pro-regular">Veiller à l’équilibre financier du programme

                                    </div>
                                </div>
                            </div>
                            <div className="statistique">
                                <Row>
                                    <Col lg={12}>
                                        <div className="blocStat blockGreyLeft">
                                            <div className="indication">

                                            </div>
                                            <div className="number">140 893 €</div>
                                            <div className="label sans-pro-light">Revenus générés
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="blocStat blockGreyRight">
                                            <div className="indication">

                                            </div>
                                            <div className="number">8%</div>
                                            <div className="label sans-pro-light">Rentabilité du programme</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ParrainagePage;
