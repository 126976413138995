import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const prestationApi = createApi({
    reducerPath: "prestationApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Prestation"],
    endpoints: (builder) => ({
        prestationFetch: builder.mutation({
            query: (payload) => ({
                url: "/api/v1/prestation/user-planning",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Prestation"],
        }),
    }),
});

export const {
    usePrestationFetchMutation,
} = prestationApi;
