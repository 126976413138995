import React, {useState, useEffect} from "react";
import {
    Button,
    Col, DatePicker, Form, message,
    Modal, Row,
} from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import TextArea from "antd/es/input/TextArea";
import {usePrestationUpdateMutation} from "../services/planning-api";
import {setRefresh} from "../services/planning-slice";
import {useDispatch} from "react-redux";

const InstructionSpecialeModalView = ({status, handleCancel, prestaInstruction, prestationId}) => {
    const [form] = Form.useForm();
    const [instruction, setInstruction] = useState(prestaInstruction);

    useEffect(() => {
        setInstruction(prestaInstruction);
    }, [prestationId]);

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 175}}
            closable={true}
            onCancel={() => {
                handleCancel();
            }}
            width={400}
            className="controle-qualite"
        >

            <div className="controle">
                <div className="mintitle custom-padding">
                    Instruction pour la prestation
                </div>
                <div className="description-controle sans-pro-regular color-A7A7A7">
                    Laisser des instructions de ménage pour cette prestation.
                </div>
                <br/>
                <Form name="basic" onFinish={null} form={form} layout="vertical">
                    <Col lg={24}>
                        <Form.Item rules={[{ required: true, message: " " }]}>
                           <TextArea rows={20} value={instruction ?? ""} readOnly={true}/>
                        </Form.Item>
                    </Col>
                   <Row>
                       <Col lg={12}> </Col>
                       <Col lg={12}>
                       </Col>
                   </Row>
                </Form>
                <br/>
            </div>

        </Modal>
    );
};
export default InstructionSpecialeModalView;
