import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const aminitieApi = createApi({
    reducerPath: "aminitieApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Aminitie"],
    endpoints: (builder) => ({
        aminitieFetch: builder.query({
            query: (payload) => ({
                url: `api/v1/cart/prestation/${payload.id}/amenitie`,
                method: "GET",
            }),
            invalidatesTags: ["Aminitie"],
        }),
       /* controleListFetch: builder.query({
            query: () => ({
                url: `/api/v1/prestation-dysfunction/list-dysfunction`,
                method: "GET",
            }),
        }),*/
    }),
});

export const {
    useAminitieFetchQuery,
} = aminitieApi;

export const lingeApi = createApi({
    reducerPath: "lingeApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Linge"],
    endpoints: (builder) => ({
        lingeFetch: builder.query({
            query: (payload) => ({
                url: `api/v1/cart/prestation/${payload.id}/linge`,
                method: "GET",
            }),
            invalidatesTags: ["Linge"],
        }),
        /*controleListFetch: builder.query({
            query: () => ({
                url: `/api/v1/prestation-dysfunction/list-dysfunction`,
                method: "GET",
            }),
        }),*/
    }),
});

export const {
    useLingeFetchQuery,
} = lingeApi;
