import React, {useState} from 'react';
import "./styles/style.scss"
import {Button, Col, Row, Space} from "antd";
import {IoHome} from "react-icons/io5";
import {IoIosRadioButtonOff, IoIosSchool} from "react-icons/io";
import {NavLink} from "react-router-dom";
import ProfilPartPage from "./profil";
import TarifsPartPage from "./tarifs";
import EquipesPartPage from "./equipes";
import {useSelector} from "react-redux";
import UpdatePricingsModal from './tarifs/modal/update-pricings-modal';

function ProfilPage() {
    const [itemSelected, setItemSelected] = useState("Profil");
    const [showUpdatePricingsModal, setShowUpdatePricingsModal] = useState(false);
    const { user } = useSelector((state) => state.auth);
    console.log(user);
    const handleSelect = async (value) => setItemSelected(value);

    return (
        <>
            <div className='page-header'>
                <div className='page-title'>
                        <h1>Paramètres</h1>
                </div>
                <Button type="primary" onClick={()=>setShowUpdatePricingsModal(true)}> Mettre à jour les prix</Button>
            </div>


            <div className='page-content'>
                <Row>
                    <Col lg={4}>
                        <div className="profilSidebar">
                            <div className="inContent">
                                <ul>
                                    <li className={itemSelected === "Profil" && "active"} onClick={() => handleSelect("Profil")}>Profil</li>
                                    {user?.role === "administrateur" && <li className={itemSelected === "Tarifs" && "active"}
                                         onClick={() => handleSelect("Tarifs")}>Tarifs</li>}
                                    <li className={itemSelected === "Equipes" && "active"} onClick={() => handleSelect("Equipes")}>Equipes</li>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col lg={20}>
                        <div className="profilContent">
                            <div className="inCOntent">
                                {
                                    itemSelected === "Profil" ?
                                        <ProfilPartPage/> :
                                        itemSelected === "Tarifs" ?
                                            <TarifsPartPage/> :  <EquipesPartPage/>

                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* <div className="buttonValidProfil">
                    <Button className={"annuler disable-Button-primary"}>Annuler</Button>
                    <Button type={"primary"} className={"disable-Button-primary"}>Enregistrer</Button>
                </div>
                <br/> */}

                    <UpdatePricingsModal
                                            status={showUpdatePricingsModal}
                                            handleCancelSuccess={() => {
                                                setShowUpdatePricingsModal(false);
                                            }}
                                            handleCancel={() => {
                                                setShowUpdatePricingsModal(false);
                                            }}                        
                    />      
            </div>
        </>
    );
}

export default ProfilPage;
